import {
    UpApiResult,
    UpApiResultStatus,
    UpConfirmButton,
    UpDividedCard,
    UpHeader,
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, FormControlLabel, FormGroup, InputAdornment, Switch, TextField, useMediaQuery, useTheme } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import { Priority, PrioritysNames } from "../../../../shared/enums/priority";
import { TenantGroup } from "../../../integration/tenant-group/models/tenant-group";
import TenantGroupService from "../../../integration/tenant-group/services/tenant-group-service";
import { CreateServiceLevelAgreement } from "../../models/create-service-level-agreement";
import { ServiceLevelAgreement } from "../../models/service-level-agreement";
import ServiceLevelAgreementService from "../../services/service-level-agreement-service";

const ServiceLevelAgreementFormPage = () => {
    const theme = useTheme();
    const { id } = useParams();

    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [tenantsGroups, setTenantsGroups] = useState<UpApiResult<TenantGroup[]>>(UpApiResult.start());
    const [saveApiResult, setSaveApiResult] = useState<UpApiResult<ServiceLevelAgreement[]>>(
        UpApiResult.new(),
    );

    const [createServiceLevelAgreementList, setcreateServiceLevelAgreementList] = useState<CreateServiceLevelAgreement[]>([]);

    const menuItem = useMenuItem("/contracts");
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const {
        register,
        reset,
        watch,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<CreateServiceLevelAgreement[]>({
        defaultValues: createServiceLevelAgreementList
    });

    const validators = (slaArray: CreateServiceLevelAgreement[]) => {
        var priorityInvalid = slaArray.find(x => x.priority === null || x.priority === undefined);
        var tenantGroupInvalid = slaArray.find(x => x.tenantGroupId === null || x.tenantGroupId === undefined);
        var timeToFirstResponseInvalid = slaArray.find(x => x.timeToFirstResponse === null || x.timeToFirstResponse === undefined || x.timeToFirstResponse === 0 || isNaN(x.timeToFirstResponse));
        var timeToResolutionInvalid = slaArray.find(x => x.timeToResolution === null || x.timeToResolution === undefined || x.timeToResolution === 0 || isNaN(x.timeToResolution));

        if (!!priorityInvalid) {
            enqueueSnackbar("Ops, é necessário preencher todos os campos de 'Prioridades'", { variant: "error" });
            return false;
        }

        if (!!tenantGroupInvalid) {
            enqueueSnackbar("Ops, é necessário preencher o campo de 'Grupo econômico'", { variant: "error" });
            return false;
        }

        if (!!timeToFirstResponseInvalid) {
            enqueueSnackbar("Ops, é necessário preencher o campo de 'Tempo primeira resposta'", { variant: "error" });
            return false;
        }

        if (!!timeToResolutionInvalid) {
            enqueueSnackbar("Ops, é necessário preencher o campo de 'Tempo de resolução'", { variant: "error" });
            return false;
        }

        for (const item of slaArray) {
            if (item.timeToFirstResponse >= item.timeToResolution) {
                enqueueSnackbar("Ops, o 'Tempo de resolução' deve ser maior que o 'Tempo de primeira resposta'", { variant: "error" });
                return false;
            }
        }

        return true;
    }

    const save: SubmitHandler<CreateServiceLevelAgreement[]> = async (serviceLevelAgreements) => {
        // Necessário fazer na mão para ser uma lista
        const slaArray = [
            {
                id: serviceLevelAgreements[0].id,
                tenantGroupId: serviceLevelAgreements[0].tenantGroupId,
                active: serviceLevelAgreements[0].active === undefined ? true : serviceLevelAgreements[0].active,
                priority: serviceLevelAgreements[0].priority,
                timeToFirstResponse: serviceLevelAgreements[0].timeToFirstResponse,
                timeToResolution: serviceLevelAgreements[0].timeToResolution,
            },
            {
                id: serviceLevelAgreements[1].id,
                tenantGroupId: serviceLevelAgreements[0].tenantGroupId,
                active: serviceLevelAgreements[0].active === undefined ? true : serviceLevelAgreements[0].active,
                priority: serviceLevelAgreements[1].priority,
                timeToFirstResponse: serviceLevelAgreements[1].timeToFirstResponse,
                timeToResolution: serviceLevelAgreements[1].timeToResolution,
            },
            {
                id: serviceLevelAgreements[2].id,
                tenantGroupId: serviceLevelAgreements[0].tenantGroupId,
                active: serviceLevelAgreements[0].active === undefined ? true : serviceLevelAgreements[0].active,
                priority: serviceLevelAgreements[2].priority,
                timeToFirstResponse: serviceLevelAgreements[2].timeToFirstResponse,
                timeToResolution: serviceLevelAgreements[2].timeToResolution,
            },
            {
                id: serviceLevelAgreements[3].id,
                tenantGroupId: serviceLevelAgreements[0].tenantGroupId,
                active: serviceLevelAgreements[0].active === undefined ? true : serviceLevelAgreements[0].active,
                priority: serviceLevelAgreements[3].priority,
                timeToFirstResponse: serviceLevelAgreements[3].timeToFirstResponse,
                timeToResolution: serviceLevelAgreements[3].timeToResolution,
            },
            {
                id: serviceLevelAgreements[4].id,
                tenantGroupId: serviceLevelAgreements[0].tenantGroupId,
                active: serviceLevelAgreements[0].active === undefined ? true : serviceLevelAgreements[0].active,
                priority: serviceLevelAgreements[4].priority,
                timeToFirstResponse: serviceLevelAgreements[4].timeToFirstResponse,
                timeToResolution: serviceLevelAgreements[4].timeToResolution,
            },
        ];

        if (validators(slaArray)) {
            try {
                setSaveApiResult(UpApiResult.start());

                if (id) {
                    // Atualizando um SLA existente
                    setSaveApiResult(
                        await ServiceLevelAgreementService.instance.updateAll(parseInt(id), slaArray),
                    );
                } else {
                    // Criando novos SLAs
                    setSaveApiResult(
                        await ServiceLevelAgreementService.instance.createAll(slaArray),
                    );
                }
            } catch (error) {
                enqueueSnackbar("Erro ao salvar os SLA", { variant: "error" });
            }
        }
    };

    useEffect(() => {
        const fetchTenantGroups = async () => {
            try {
                const fetchedTenantsGroups = await TenantGroupService.instance.listActives();
                setTenantsGroups(fetchedTenantsGroups);

                if (id) {
                    const listaSlasEdit = await ServiceLevelAgreementService.instance.listByTenantGroup(id);

                    // preenchendo o valor da lista com o que foi salvo
                    setcreateServiceLevelAgreementList(listaSlasEdit.data);

                    // redefinir os valores do form após carregar os TenantGroups
                    reset(listaSlasEdit.data);
                }
                else {
                    // preenchendo valores pré difinidos do form
                    setcreateServiceLevelAgreementList(
                        [
                            {
                                tenantGroupId: 0,
                                priority: Priority.Highest,
                                timeToFirstResponse: 0,
                                timeToResolution: 0,
                                active: true,
                            },
                            {
                                tenantGroupId: 0,
                                priority: Priority.High,
                                timeToFirstResponse: 0,
                                timeToResolution: 0,
                                active: true,
                            },
                            {
                                tenantGroupId: 0,
                                priority: Priority.Medium,
                                timeToFirstResponse: 0,
                                timeToResolution: 0,
                                active: true,
                            },
                            {
                                tenantGroupId: 0,
                                priority: Priority.Low,
                                timeToFirstResponse: 0,
                                timeToResolution: 0,
                                active: true,
                            },
                            {
                                tenantGroupId: 0,
                                priority: Priority.Lowest,
                                timeToFirstResponse: 0,
                                timeToResolution: 0,
                                active: true,
                            },
                        ]
                    );
                }

            } catch (error) {
                enqueueSnackbar("Erro ao carregar os grupos econômicos", { variant: "error" });
            }
        };

        if (saveApiResult.status === UpApiResultStatus.success) {
            enqueueSnackbar("O contrato de SLA foi salvo com sucesso.", {
                variant: "success",
            });
            navigate("/contracts");
        } else if (saveApiResult.status === UpApiResultStatus.error) {
            enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
        }

        fetchTenantGroups();
    }, [saveApiResult, id, enqueueSnackbar, navigate]);

    return (
        <Box>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(save)}
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
            >
                <UpHeader appTitle={appTitle} menuItem={menuItem} showBack>
                    <UpConfirmButton
                        apiResult={saveApiResult}
                        sx={{ ml: 2 }}
                        color="primary"

                    />
                </UpHeader>

                <UpDividedCard
                    title="Dados Cadastrais"
                    description="Informe os dados básicos de um contrato SLA"
                >
                    <Box sx={{ mt: 2 }}>
                        <Controller
                            {...register(`${0}.tenantGroupId`)}
                            control={control}
                            disabled={false}
                            // rules={{ required: "O campo 'grupo econômico' é obrigatório." }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    options={tenantsGroups.data || []}
                                    getOptionLabel={(group: TenantGroup) => group.name}
                                    color="warning"
                                    size="small"
                                    fullWidth
                                    disabled={false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            color="warning"
                                            size="small"
                                            label="Grupo Econômico"
                                            placeholder={
                                                tenantsGroups.status === UpApiResultStatus.loading
                                                    ? "Carregando..."
                                                    : ""
                                            }
                                            helperText={errors?.[0]?.tenantGroupId?.message ?? "*Obrigatório"}
                                            error={!!errors?.[0]?.tenantGroupId}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: tenantsGroups.status ===
                                                    UpApiResultStatus.loading && (
                                                        <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                                                            <FontAwesomeIcon icon={["fal", "loader"]} spin />
                                                        </InputAdornment>
                                                    ),
                                            }}
                                            fullWidth
                                            required={true}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    )}
                                    value={
                                        tenantsGroups.data?.find(
                                            (group: TenantGroup) => group.id === value,
                                        ) ?? null
                                    }
                                    onChange={(_, value) => {
                                        return onChange(value?.id ?? null);
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Grid container>
                        <Grid size={2}>
                            <FormGroup>
                                <Controller
                                    {...register(`${0}.active`)}
                                    control={control}
                                    defaultValue={true} // Valor padrão como true
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    sx={{ mt: 1 }}
                                                    checked={value}
                                                    onChange={(e) => onChange(e.target.checked)}
                                                    color="secondary"
                                                />
                                            }
                                            label="Ativo"
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </UpDividedCard>

                <UpDividedCard title="SLAs" description="Informe os SLAs contratados pelo grupo econômico para cada uma das prioridades de tickets">
                    {createServiceLevelAgreementList.map((entity, index) => (
                        <Grid container direction={isMdDown ? "column" : "row"} spacing={2} sx={{ my: 2 }} key={index}>
                            <Grid size={{ xs: 12, md: 6, lg: 4 }} >
                                <Controller
                                    name={`${index}.priority`}
                                    control={control}
                                    defaultValue={entity.priority}
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            options={PrioritysNames}
                                            getOptionLabel={(priority) => priority.label}
                                            color="warning"
                                            size="small"
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    color="warning"
                                                    size="small"
                                                    label="Prioridade"
                                                    helperText={errors?.[index]?.priority?.message ?? ""}
                                                    error={!!errors?.[index]?.priority}
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                />
                                            )}
                                            disabled={true}
                                            value={PrioritysNames.find((x) => x.value === value) ?? null}
                                            onChange={(_, value) => {
                                                onChange(value?.value ?? null)
                                            }
                                            }
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid size={{ xs: 12, md: 3, lg: 4 }}>
                                <TextField
                                    {...register(`${index}.timeToFirstResponse`, {
                                        valueAsNumber: true,
                                        min: 0,
                                    })}
                                    color="warning"
                                    size="small"
                                    label="Tempo da 1ª resposta (h)"
                                    defaultValue={entity.timeToFirstResponse}
                                    helperText={errors?.[index]?.timeToFirstResponse?.message ?? "*Obrigatório"}
                                    error={!!errors?.[index]?.timeToFirstResponse}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    type="number"
                                    required={true}
                                />
                            </Grid>

                            <Grid size={{ xs: 12, md: 3, lg: 4 }}>
                                <TextField
                                    {...register(`${index}.timeToResolution`, {
                                        valueAsNumber: true,
                                        min: 0,

                                    })}
                                    color="warning"
                                    size="small"
                                    type="number"
                                    label="Tempo de resolução (h)"
                                    defaultValue={entity.timeToResolution}
                                    helperText={errors?.[index]?.timeToResolution?.message ?? "*Obrigatório"}
                                    error={!!errors?.[index]?.timeToResolution}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </UpDividedCard>
            </form>
        </Box >
    );
};

export default ServiceLevelAgreementFormPage;
