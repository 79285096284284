import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardContent, Stack, Tooltip, Typography, Grid, useTheme } from "@mui/material";
import React from "react";
import { Ticket } from "../models/ticket";

const TicketSlaStatus = ({
    hasFirstResponse,
    targetTimeToFirstResponse,
    elapsedTimeToFirstResponse,
    targetTimeToResolution,
    elapsedTimeToResolution,
    completionDate
}: Ticket) => {

    const timeToFirstResponsePercentage: number = elapsedTimeToFirstResponse / targetTimeToFirstResponse * 100;
    const timeToResolutionPercentage: number = elapsedTimeToResolution / targetTimeToResolution * 100;

    return (
        <Grid container columnGap={3} rowGap={{ xs: 2 }} sx={{ ml: 2, my: 1 }} justifyContent={"center"}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SlaStatusItem title="Tempo para primeira resposta"
                    tooltipTitle={hasFirstResponse ? "Primeira resposta enviada" : "Aguardando primeira resposta"}
                    completed={hasFirstResponse}
                    currentPercentage={timeToFirstResponsePercentage}
                    elapsedTime={elapsedTimeToFirstResponse}
                    targetTime={targetTimeToFirstResponse} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SlaStatusItem
                    title="Tempo para resolução"
                    tooltipTitle={!!completionDate ? "Ticket finalizado" : "Ticket aberto"}
                    completed={!!completionDate}
                    currentPercentage={timeToResolutionPercentage}
                    elapsedTime={elapsedTimeToResolution}
                    targetTime={targetTimeToResolution} />
            </Grid>
        </Grid>
    );
}

const SlaStatusItem = ({ title, tooltipTitle, completed, currentPercentage, elapsedTime, targetTime }) => {
    const theme = useTheme();

    const getFullTooltipTitle = (): string => {
        if (elapsedTime > targetTime)
            return `${tooltipTitle} (com atraso)`;

        return tooltipTitle;
    }
    return (
        <Card sx={{ width: "100%" }}>
            <CardContent>
                <Stack direction={"row"} alignItems={{ xs: "start", sm: "center" }}>
                    <Tooltip title={getFullTooltipTitle()}>
                        <Avatar sx={{
                            mr: 1,
                            background: () => {
                                if (completed)
                                    return elapsedTime <= targetTime ? theme.palette.success.main : "#ffc107";
                                else if (currentPercentage < 100)
                                    return theme.palette.primary.main;
                                else
                                    return theme.palette.error.main;
                            }
                        }}>
                            {completed && (
                                <FontAwesomeIcon icon={["fal", "check"]} color="#fff" />
                            )}

                            {!completed && currentPercentage < 100 && (
                                <Typography component={"small"} variant={"subtitle2"}>
                                    {`${Math.round(currentPercentage)}%`}
                                </Typography>
                            )}

                            {!completed && currentPercentage >= 100 && (
                                <FontAwesomeIcon icon={["fal", "alarm-exclamation"]} color="#fff" />
                            )}
                        </Avatar>
                    </Tooltip>

                    <Stack direction={"column"} justifyContent={"center"}>
                        <Typography component={"span"} variant="caption">
                            {title}
                        </Typography>
                        <Typography component={"span"} >
                            {elapsedTime.toFixed(2)}h / {targetTime.toFixed(0)}h
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default TicketSlaStatus;