import {
  UpApiResult,
  UpApiResultStatus,
  UpHeader,
  UpMenuItem,
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { PrioritysNames } from "../../../../../shared/enums/priority";
import TicketClassificationsField from "../../../../classification/components/ticket-classifications";
import { ExternalUser } from "../../../../external-user/models/external-user";
import ExternalUserService from "../../../../external-user/services/external-user-service";
import AttributionField from "../../../../integration/attribution/components/attribution-field";
import BusinessUnitField from "../../../../integration/business-unit/components/business-unit-field";
import DepartmentField from "../../../../integration/department/components/department-field";
import OccupationAreaField from "../../../../integration/occupation-area/components/occupation-area-field";
import TenantGroupField from "../../../../integration/tenant-group/components/tenant-group-field";
import TenantField from "../../../../integration/tenant/components/tenant-field";
import ReportUser from "../../../../integration/user/components/report-user";
import externalUseMe from "../../../../integration/user/hooks/external-use-me";
import useMe from "../../../../integration/user/hooks/use-me";
import { User } from "../../../../integration/user/models/user";
import UserService from "../../../../integration/user/services/user-service";
import { StatusType } from "../../../../status/enums/status-type";
import { Status } from "../../../../status/models/status";
import StatusService from "../../../../status/services/status-service";
import StatusField from "../../../../ticket/components/status-field";
import TicketSlaStatus from "../../../../ticket/components/ticket-sla-status";
import { TicketTypesNames } from "../../../../ticket/enums/ticket-type";
import { CreateTicket } from "../../../../ticket/models/create-ticket";
import { Ticket } from "../../../../ticket/models/ticket";
import { UpdateTicket } from "../../../../ticket/models/update-ticket";
import TicketService from "../../../../ticket/services/ticket.service";
import AttachmentList from "../../../attachment/components/list";
import { CreateAttachment } from "../../../attachment/models/create-attachment";
import InteractionForm from "../../../interaction/components/form/index";
import InteractionList from "../../../interaction/components/list/index";
import { TickectInteractionDto } from "../../../interaction/dtos/ticket-interaction-dto";
import { Interaction } from "../../../interaction/models/interaction";
import { useAppContext } from "../../../../../shared/contexts/app-context";
import { environment } from "../../../../../shared/utils/environment";
import { Attachment } from "../../../attachment/models/attachment";
import { useRole } from "../../../../user-role/hooks/use-role";

const TicketInteractionPage = () => {
  const user = useMe();
  const role = useRole();

  const externalUser = externalUseMe();
  const isExternalUser = !!externalUser;

  const theme = useTheme();
  const navigate = useNavigate();
  const loggedUser = externalUseMe();

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState<Status>();

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const { interactions, setInteractions } = useAppContext();

  const [menuItem, setMenuItem] = useState<UpMenuItem>(
    new UpMenuItem(
      ["fal", "tickets-perforated"],
      "",
      "",
      "/my-tickets",
      undefined,
      undefined,
    ),
  );
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [saveApiResult, setSaveApiResult] = useState<UpApiResult<Ticket>>(
    UpApiResult.new(),
  );
  const [findApiResult, setFindApiResult] = useState<UpApiResult<Ticket>>(
    UpApiResult.start(),
  );

  const [disable, setDisable] = useState<boolean>(false);
  const [disableAfterFirstResponse, setDisableAfterFirstResponse] =
    useState<boolean>(false);

  const { attachmentsInteractions, setAttachmentsInteractions } =
    useAppContext();

  //#region Participantes WKS
  // participantes que foram salvos quando o ticket foi aberto.
  const [stakeholdersOld, setStakeholdersOld] = useState<User[]>([]);

  // ids dos participantes que foram salvos quando o ticket foi aberto.
  const [stakeholdersIds, setStakeholdersIds] = useState<string[]>([]);

  // participantes que foram adicionado quando o ticket foi criado, e os que é adicionado durante a iteração com o ticket.
  const [stakeholders, setStakeholders] = useState<User[]>([
    ...stakeholdersOld,
  ]);

  // todos usuários do done auth
  const [usersAll, setUsersAll] = useState<User[]>([]);
  //#endregion

  //#region Participantes Externos
  // participantes que foram salvos quando o ticket foi aberto.
  const [externalStakeholdersOld, setExternalStakeholdersOld] = useState<
    ExternalUser[]
  >([]);

  // ids dos participantes que foram salvos quando o ticket foi aberto.
  const [externalStakeholdersIds, setExternalStakeholdersIds] = useState<
    string[]
  >([]);

  // participantes que foram adicionado quando o ticket foi criado, e os que é adicionado durante a iteração com o ticket.
  const [externalStakeholders, setExternalStakeholders] = useState<
    ExternalUser[]
  >(externalStakeholdersOld !== undefined ? [...externalStakeholdersOld] : []);

  // todos usuários externos
  const [externalUsersAll, setExternalUsersAll] = useState<ExternalUser[]>([]);
  //#endregion

  const handleInteractionFormSubmit = async (interactionForm: Interaction) => {
    const wrappedSave: SubmitHandler<CreateTicket> = async (createTicket) => {
      await save(createTicket, interactionForm);
    };

    // Modifica o save para aceitar também o interactionForm
    await handleSubmit(wrappedSave)();
  };

  const methods = useForm<CreateTicket>({
    defaultValues: async () => {
      if (id) {
        try {
          const result = await TicketService.instance.find(parseInt(id!));

          if (result.status === UpApiResultStatus.success && result.data) {
            const resultUsers = await UserService.instance.listActives();
            const resultExternalUsers = loggedUser
              ? await ExternalUserService.instance.listByTenantGroupId(
                  loggedUser.tenantGroupId,
                )
              : await ExternalUserService.instance.listActives();

            // para sempre quando entrar em um ticket porder alterar o status dos interaction para load
            if (interactions.status === UpApiResultStatus.success) {
              setInteractions(UpApiResult.start());
            }

            setMenuItem(
              new UpMenuItem(
                ["fal", "tickets-perforated"],
                `[#${result.data?.id}] ${result.data?.subject}`,
                `[#${result.data?.id}] ${result.data?.subject}`,
                "/my-tickets",
                undefined,
                undefined,
              ),
            );

            if (result.data?.status) {
              if (result.data?.status.type === StatusType.Close)
                setDisable(true);
            }

            if (role === "Cliente") {
              setDisableAfterFirstResponse(!!result.data?.hasFirstResponse);
            }

            setFindApiResult(result);
            // setTags(result.data.tags);
            setAttachments(result.data.attachments);

            // wks
            setUsersAll(resultUsers?.data || []);
            setStakeholdersOld(result.data?.stakeholders || []);
            setStakeholdersIds(result.data.stakeholdersIds || []);
            setStakeholders(result.data?.stakeholders || []);

            // externos
            setExternalUsersAll(resultExternalUsers?.data || []);
            setExternalStakeholders(result.data?.externalStakeholders || []);
            setExternalStakeholdersOld(result.data?.externalStakeholders || []);
            setExternalStakeholdersIds(
              result.data?.externalStakeholdersIds || [],
            );

            return result.data;
          } else navigate(-1);
        } catch (error) {
          navigate(-1);
        }
      } else {
        setStatus(
          (await StatusService.instance.findByStatus(StatusType.New)).data,
        );

        setFindApiResult(UpApiResult.success({}));
      }
    },
  });

  const {
    register,
    reset,
    control,
    watch,
    setValue,
    formState,
    setFocus,
    handleSubmit,
  } = methods;
  const { errors } = formState;

  // esse metodo atualiza o ticket e cria iterações com o ticket
  const save = async (createTicket: UpdateTicket, interactionForm) => {
    try {
      // Atribui valores seprados ao ticket
      createTicket.attachments = attachments;

      // Wks
      for (const stakeholdersId of stakeholdersIds) {
        if (!createTicket.stakeholdersIds.includes(stakeholdersId))
          createTicket.stakeholdersIds.push(stakeholdersId);
      }

      // External
      for (const externalStakeholdersId of externalStakeholdersIds) {
        if (
          !createTicket.externalStakeholdersIds.includes(externalStakeholdersId)
        )
          createTicket.externalStakeholdersIds.push(externalStakeholdersId);
      }

      // Quando cria ele atribui o status do tipo New para o chamado.
      if (!id) createTicket.statusId = status?.id ?? 0;

      // Atribui os classifications tickets que foram selecionados
      createTicket.classifications = createTicket.optionsSelect
        ?.filter(
          (x) => x.selectedOption !== null && x.selectedOption !== undefined,
        )
        .flatMap((x) => x.options.filter((a) => a.id == x.selectedOption));

      const listTickets = UpApiResult<Interaction[]>;

      let dto = new TickectInteractionDto();

      if (interactionForm.text !== "") {
        let interaction: Interaction = {} as Interaction;
        dto.interaction = interaction;
        dto.interaction.ticketId = findApiResult.data.id;
        dto.interaction.userId = user ? user!.id : externalUser!.id.toString();
        dto.interaction.text = interactionForm.text;
        dto.interaction.attachments = attachmentsInteractions;
        dto.interaction.visibility = interactionForm.visibility;
      }

      dto.ticket = createTicket;

      setSaveApiResult(UpApiResult.start());

      let ticket =
        await TicketService.instance.updateTicketCreateInteraction(dto);

      setSaveApiResult(ticket);

      if (attachmentsInteractions.length > 0) setAttachmentsInteractions([]);

      if (dto.interaction !== undefined) setInteractions(listTickets.start());

      if (ticket.data?.status) {
        if (ticket.data.status.type === StatusType.Close) navigate(-1);
      }
    } catch (error: any) {
      setSaveApiResult(UpApiResult.error(errors));
    }
  };

  useEffect(() => {
    if (saveApiResult.status === UpApiResultStatus.success) {
      enqueueSnackbar(`Ticket atualizado com sucesso.`, {
        variant: "success",
      });

      // navigate("/assigned-tickets");
    } else if (saveApiResult.status === UpApiResultStatus.error) {
      // setFocus("timeToFirstResponse");
      enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
    }
  }, [saveApiResult, id, enqueueSnackbar, reset, setFocus, navigate]);

  // Função para remover um anexo
  const handleRemoveAttachment = (attachmentToRemove: CreateAttachment) => {
    setAttachmentsInteractions(
      attachmentsInteractions.filter(
        (attachment) => attachment !== attachmentToRemove,
      ),
    );
  };

  const back = async () => {
    setAttachmentsInteractions([]);
    navigate(-1);
  };

  return (
    <Box>
      <UpHeader
        menuItem={menuItem}
        // showBack
        caption={
          <>
            {findApiResult.status === UpApiResultStatus.success && (
              <>
                <Typography
                  component={"div"}
                  variant="caption"
                  color={theme.palette.warning.main}
                  sx={{ ml: 5 }}
                >
                  {findApiResult.data.creationDate.toUpLocalDateTime(true)}
                </Typography>

                <TicketSlaStatus {...findApiResult.data} />
              </>
            )}
          </>
        }
      >
        <Button sx={{ color: "primary" }} onClick={() => back()}>
          <FontAwesomeIcon icon={["fal", "arrow-left"]} />
          <Typography sx={{ ml: 1 }}>Voltar</Typography>
        </Button>
      </UpHeader>

      {findApiResult.status === UpApiResultStatus.success && (
        <>
          <Grid container spacing={2}>
            {/* Iteração com o ticket */}
            <Grid item xs={12} md={8}>
              <Box sx={{ height: "58vh", overflowY: "scroll" }}>
                <Box
                  sx={{
                    marginLeft: "initial",
                  }}
                  key={findApiResult.data.id}
                >
                  <Card
                    sx={{
                      bgcolor: (theme) =>
                        (user?.id ?? externalUser?.id) ===
                        findApiResult?.data?.reportUserId
                          ? theme.palette.secondary.dark
                          : "initial",
                      marginLeft:
                        (user?.id ?? externalUser?.id) ===
                        findApiResult?.data?.reportUserId
                          ? "auto"
                          : "5%",
                      marginRight: "5%",
                      borderRadius: "20px",
                      width: "80%",
                    }}
                  >
                    <CardContent sx={{ padding: 0 }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            src={
                              findApiResult.data.reportUser?.image
                                ? `${environment.VITE_API_GATEWAY_URL}/onstock/images/${findApiResult.data.reportUser.image}`
                                : undefined
                            }
                            sx={{
                              bgcolor: (theme) => theme.palette.secondary.dark,
                            }}
                            aria-label="user-first-char"
                          >
                            {"Username".charAt(0)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={findApiResult.data.reportUser?.name}
                          secondary={
                            <Typography
                              component="span"
                              fontSize={12}
                              sx={{ opacity: 0.75 }}
                            >
                              {findApiResult.data.creationDate.toUpLocalDateTime(
                                true,
                              )}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Typography
                        variant="body1"
                        sx={{
                          margin: 2,
                          marginTop: 0,
                          width: "fit-content",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {findApiResult.data.subject}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>

                <InteractionList ticketId={parseInt(findApiResult.data.id!)} />
              </Box>

              <Grid
                container
                direction={isMdDown ? "column" : "row"}
                spacing={2}
              >
                <Grid item xs={12}>
                  <>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {attachmentsInteractions.map((attachment, index) => (
                        <Tooltip
                          title={"O arquivo está pré carregado"}
                          key={index}
                        >
                          <Chip
                            key={index}
                            icon={<FontAwesomeIcon icon={["fal", "upload"]} />}
                            label={attachment.name}
                            onDelete={() => handleRemoveAttachment(attachment)}
                            deleteIcon={
                              <IconButton size="small">
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            }
                            sx={{ maxWidth: "150px", height: "30px" }}
                          />
                        </Tooltip>
                      ))}
                    </Box>

                    <InteractionForm
                      disable={disable}
                      onSubmit={handleInteractionFormSubmit}
                      ticketId={parseInt(findApiResult.data.id!)}
                      attachmentsInteractions={attachmentsInteractions}
                      createInteractionApiResult={setSaveApiResult}
                    />
                  </>
                </Grid>
              </Grid>
            </Grid>

            {/* menu lateral com as informações do ticket */}
            <Grid item xs={12} md={4}>
              <Card sx={{ height: "67dvh", overflowY: "scroll" }}>
                <CardContent>
                  <FormProvider {...methods}>
                    <form
                      noValidate
                      onSubmit={handleSubmit(save)}
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <StatusField
                          control={control}
                          disabled={
                            isExternalUser ||
                            disable ||
                            disableAfterFirstResponse
                          }
                        ></StatusField>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <ReportUser
                          control={control}
                          userId={findApiResult.data?.reportUserId}
                          disable={disable || disableAfterFirstResponse}
                        ></ReportUser>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <TenantGroupField
                          control={control}
                          disabled={
                            disable ||
                            isExternalUser ||
                            disableAfterFirstResponse
                          }
                        ></TenantGroupField>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <TenantField
                          control={control}
                          disabled={disable || disableAfterFirstResponse}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <BusinessUnitField
                          control={control}
                          setValue={setValue}
                          disabled={disable || disableAfterFirstResponse}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <OccupationAreaField
                          control={control}
                          setValue={setValue}
                          disabled={disable || disableAfterFirstResponse}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <DepartmentField
                          listAll={false}
                          control={control}
                          disabled={disable || disableAfterFirstResponse}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Controller
                          name="type"
                          control={control}
                          defaultValue={0}
                          rules={{ required: "O campo tipo é obrigatório." }}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Autocomplete
                                options={TicketTypesNames}
                                getOptionLabel={(type) => type.label}
                                color="warning"
                                size="small"
                                fullWidth
                                disabled={disable || disableAfterFirstResponse}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="warning"
                                    size="small"
                                    label="Tipo"
                                    helperText={
                                      errors.type?.message ?? "*Obrigatório"
                                    }
                                    error={!!errors.type}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                )}
                                value={
                                  TicketTypesNames.find(
                                    (x) => x.value === value,
                                  ) ?? null
                                }
                                onChange={(_, value) => onChange(value?.value)}
                              />
                            </>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Controller
                          name="priority"
                          control={control}
                          defaultValue={0}
                          rules={{
                            required: "O campo prioridade é obrigatório.",
                          }}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Autocomplete
                                options={PrioritysNames}
                                getOptionLabel={(priority) => priority.label}
                                color="warning"
                                size="small"
                                fullWidth
                                disabled={disable || disableAfterFirstResponse}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="warning"
                                    size="small"
                                    label="Prioridade"
                                    helperText={
                                      errors.priority?.message ?? "*Obrigatório"
                                    }
                                    error={!!errors.priority}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                )}
                                value={
                                  PrioritysNames.find(
                                    (x) => x.value === value,
                                  ) ?? null
                                }
                                onChange={(_, value) => onChange(value?.value)}
                              />
                            </>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <TicketClassificationsField
                          required={true}
                          control={control}
                          watch={watch}
                          disabled={disable || disableAfterFirstResponse}
                          ticketId={parseInt(id!)}
                          fullWidth
                        />
                      </Grid>

                      {!isExternalUser && (
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <Autocomplete
                            sx={{ mt: 1 }}
                            multiple
                            id="fixed-tags-demo"
                            value={stakeholders}
                            size="small"
                            disabled={disable || disableAfterFirstResponse}
                            onChange={(event, newValue) => {
                              // Filtrar os novos usuários que não estão em stakeholdersOld
                              const newUsersSelect = newValue.filter(
                                (newUser) =>
                                  !stakeholdersOld.some(
                                    (oldUser) =>
                                      oldUser.email === newUser.email,
                                  ),
                              );

                              // Mapear os emails dos novos usuários
                              const emailsStakeholdersNew = newUsersSelect.map(
                                (user) => user.email,
                              );

                              // Atualizar os estados
                              setStakeholdersIds(emailsStakeholdersNew);
                              setStakeholders([
                                ...stakeholdersOld,
                                ...newUsersSelect,
                              ]);
                            }}
                            options={usersAll}
                            getOptionLabel={(user) => user.name}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((user, index) => {
                                const { key, ...tagProps } = getTagProps({
                                  index,
                                });
                                const isDisabled =
                                  stakeholdersOld.indexOf(user) !== -1;

                                return (
                                  <Typography key={key}>
                                    <Tooltip
                                      title={user.email}
                                      disableInteractive
                                    >
                                      <Typography
                                        sx={{ pointerEvents: "auto" }}
                                      >
                                        <Chip
                                          label={user.nameInitials}
                                          {...tagProps}
                                          variant="filled"
                                          sx={{ mr: 1 }}
                                          className={
                                            isDisabled
                                              ? "hidden-delete-icon"
                                              : ""
                                          }
                                          avatar={
                                            <Avatar
                                              alt={user.name}
                                              src={`${environment.VITE_API_GATEWAY_URL}/onstock/images/${user.image}`}
                                            />
                                          }
                                        />
                                      </Typography>
                                    </Tooltip>
                                  </Typography>
                                );
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Participantes Internos"
                                placeholder="Adicione mais participantes internos"
                              />
                            )}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Autocomplete
                          sx={{ mt: 5, mb: 3 }}
                          multiple
                          id="fixed-tags-demo"
                          value={externalStakeholders}
                          size="small"
                          onChange={(event, newValue) => {
                            // Filtrar os novos usuários que não estão em externalStakeholdersOld
                            const newExternalUsersSelect = newValue.filter(
                              (newUser) =>
                                !externalStakeholdersOld.some(
                                  (oldUser) => oldUser.email === newUser.email,
                                ),
                            );

                            // Mapear os emails dos novos usuários
                            const emailsStakeholdersNew =
                              newExternalUsersSelect.map((user) => user.email);

                            // Atualizar os estados
                            setExternalStakeholdersIds(emailsStakeholdersNew);
                            setExternalStakeholders([
                              ...externalStakeholdersOld,
                              ...newExternalUsersSelect,
                            ]);
                          }}
                          options={externalUsersAll}
                          getOptionLabel={(user) => user.name}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((user, index) => {
                              const { key, ...tagProps } = getTagProps({
                                index,
                              });
                              const isDisabled =
                                externalStakeholdersOld.indexOf(user) !== -1;

                              return (
                                <Typography key={key}>
                                  <Tooltip
                                    title={user.email}
                                    disableInteractive
                                  >
                                    <Typography sx={{ pointerEvents: "auto" }}>
                                      <Chip
                                        label={user.nameInitials}
                                        {...tagProps}
                                        variant="filled"
                                        sx={{ mr: 1 }}
                                        className={
                                          isDisabled ? "hidden-delete-icon" : ""
                                        }
                                        avatar={
                                          <Avatar
                                            alt={user.name}
                                            src={`${environment.VITE_API_GATEWAY_URL}/onstock/images/${user.image}`}
                                          />
                                        }
                                      />
                                    </Typography>
                                  </Tooltip>
                                </Typography>
                              );
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                !isExternalUser
                                  ? "Participantes Externos"
                                  : "Participantes"
                              }
                              placeholder={
                                !isExternalUser
                                  ? "Adicione mais participantes externos"
                                  : "Adicione mais participantes"
                              }
                            />
                          )}
                          disabled={disable || disableAfterFirstResponse}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <AttributionField
                          control={control}
                          disabled={isExternalUser || disable}
                        ></AttributionField>
                      </Grid>

                      <Grid container sx={{ mt: 0 }} spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            name="tags"
                            control={control}
                            disabled={disable || disableAfterFirstResponse}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                options={Array.of<string>()}
                                color="warning"
                                size="small"
                                multiple
                                freeSolo
                                fullWidth
                                disabled={disable || disableAfterFirstResponse}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    color="warning"
                                    size="small"
                                    label="Tags"
                                    placeholder="Digite a tag e pressione 'enter' para adicioná-la"
                                    helperText={
                                      errors.tags?.message ?? "Opcional"
                                    }
                                    error={!!errors.tags}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                )}
                                value={value ?? []}
                                onChange={(_, value) => onChange(value)}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={12}>
                          <AttachmentList
                            isAttachmentInteracionCreate={false}
                            isVisibleAllAttachments={true}
                            saveApiResult={saveApiResult}
                            hideTrash={true}
                            ticketId={parseInt(id!)}
                            attachments={attachments}
                            attachmentsInteractions={[]}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </FormProvider>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default TicketInteractionPage;
