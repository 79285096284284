import { AdapterFormats } from "@mui/x-date-pickers";

export const DateFormatProvider: AdapterFormats = {
  year: 'YYYY',
  month: 'MMMM',
  monthShort: 'MMM',
  dayOfMonth: 'DD',
  weekday: 'dddd',
  weekdayShort: 'ddd',
  hours24h: 'HH',
  hours12h: 'hh',
  meridiem: '',
  minutes: 'mm',
  seconds: 'ss',
  fullDate: 'DD/MM/YYYY',
  fullDateWithWeekday: 'ddd DD/MM/YYYY',
  keyboardDate: 'DD/MM/YYYY',
  shortDate: 'DD/MM/YYYY',
  normalDate: 'DD/MM/YYYY',
  normalDateWithWeekday: 'ddd DD/MM/YYYY',
  monthAndYear: 'MMM/YYYY',
  monthAndDate: 'DD/MMM',
  fullTime: 'HH:mm:ss',
  fullTime12h: 'hh:mm:ss',
  fullTime24h: 'HH:mm:ss',
  fullDateTime: 'DD/MM/YYYY HH:mm:ss',
  fullDateTime12h: 'DD/MM/YYYY hh:mm:ss',
  fullDateTime24h: 'DD/MM/YYYY HH:mm:ss',
  keyboardDateTime: 'DD/MM/YYYY HH:mm:ss',
  keyboardDateTime12h: 'DD/MM/YYYY hh:mm:ss',
  keyboardDateTime24h: 'DD/MM/YYYY HH:mm:ss'
}