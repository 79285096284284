import {
  UpApiResult,
  UpConfirmButton,
  UpMenuItem,
} from "@done/react-essentials";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { Suspense, useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Outlet, To, useLocation, useNavigate } from "react-router";
import ExternalUserService from "../../features/external-user/services/external-user-service";
import Me from "../../features/integration/user/components/me";
import { ChangePassword } from "../../features/login-external/models/change-password";
import { LoginResult } from "../../features/login-external/models/login-result";
import { User } from "../../features/login-external/models/user";
import useMenuItems from "../../menus-items/hooks/use-menu-items";
import Logo from "../../shared/logo";
import { environment } from "../../shared/utils/environment";
import useThemeMode from "../../themes/hooks/use-theme-mode";
import useThemeModeSelector from "../../themes/hooks/use-theme-mode-selector";
import {
  useExternalSidebarStatus,
  useSetExternalSidebarStatus,
} from "./hooks/use-external-sidebar-status";
import { useAppContext } from "../../shared/contexts/app-context";

library.add(fal, fas);

const AppHeader = ({ drawerWidth }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const themeMode = useThemeMode();
  const themeModeSelector = useThemeModeSelector();
  const sidebarStatus = useExternalSidebarStatus();
  const setSidebarStatus = useSetExternalSidebarStatus();

  const { isLoggedIn, setIsLoggedIn } = useAppContext();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    setIsLoggedIn(false);

    const sessionStr = window.localStorage.getItem("vup_help_desk");
    const session: LoginResult | null = !sessionStr
      ? null
      : JSON.parse(window.localStorage.getItem("vup_help_desk")!);

    localStorage.removeItem("vup_help_desk");

    let accountId = session?.account?.id;

    window.location.href = `${environment.VITE_APP_HELPDESK_URL}/${accountId}/external-login`;
  };

  const handleClose = (confirm: boolean) => {
    setAnchorEl(null);

    if (confirm === true) logout();
  };

  //#region alterar senha
  const [dialogOpened, setDialogOpened] = useState<{
    dialogOpened: boolean;
  } | null>(null);

  const [saveApiResult, setSaveApiResult] = useState<UpApiResult<User>>(
    UpApiResult.new(),
  );

  const [changePassword, setChangePassword] = useState<ChangePassword>({
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });

  const [currentPasswordVisible, setCurrentPasswordVisible] =
    useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [newPasswordConfirmationVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);

  const {
    reset,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ChangePassword>({
    defaultValues: changePassword,
  });

  const password = useWatch<ChangePassword>({
    name: "newPassword",
    control: control,
  });

  const save: SubmitHandler<ChangePassword> = async (user) => {
    setSaveApiResult(UpApiResult.start());

    try {
      let result = await ExternalUserService.instance.changePasswordAsync(user);
      setSaveApiResult(UpApiResult.success(result));
      enqueueSnackbar("Senha alterada com sucesso", { variant: "success" });
      closeDialog();
    } catch (error: any) {
      enqueueSnackbar(error, { variant: "error" });
      setSaveApiResult(UpApiResult.error(error));
    }
  };

  const handleEditPassword = (confirm: boolean) => {
    setAnchorEl(null);

    if (confirm === true) openDialog();
  };

  const openDialog = () => {
    setDialogOpened({
      dialogOpened: true,
    });
  };

  const closeDialog = async () => {
    setSaveApiResult(UpApiResult.success({}));

    setChangePassword({
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    });

    reset();
    setDialogOpened(null);
  };
  //#endregion

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <AppBar enableColorOnDark position="fixed">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {!sidebarStatus && (
            <IconButton onClick={() => setSidebarStatus(true)}>
              <Box sx={{ height: "24px", width: "24px" }}>
                <FontAwesomeIcon icon={["fal", "bars"]} />
              </Box>
            </IconButton>
          )}

          <Box
            sx={{
              height: 64,
              width: drawerWidth - 48,
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            <Logo />
          </Box>

          <Box>
            <Button color="primary" onClick={handleMenu}>
              <Me variant="nameAvatar" />
              <FontAwesomeIcon icon={["fal", "chevron-down"]} />
            </Button>

            <Menu
              keepMounted
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() =>
                  themeModeSelector(themeMode === "light" ? "dark" : "light")
                }
              >
                <FontAwesomeIcon
                  icon={["fal", themeMode === "light" ? "moon" : "sun"]}
                />
                <Typography sx={{ ml: 1 }}>
                  Ativar modo {themeMode === "light" ? "escuro" : "claro"}
                </Typography>
              </MenuItem>

              <Divider />

              <MenuItem onClick={() => handleEditPassword(true)}>
                <FontAwesomeIcon icon={["fal", "key"]} />{" "}
                <Typography sx={{ ml: 1 }}>Alterar Senha</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleClose(true)}>
                <FontAwesomeIcon icon={["fal", "sign-out"]} />{" "}
                <Typography sx={{ ml: 1 }}>Fazer Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Dialog
        open={!!dialogOpened}
        onClose={() => closeDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form noValidate autoComplete="off" onSubmit={handleSubmit(save)}>
          <DialogTitle id="alert-dialog-title">Alterar Senha</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6} lg={4}>
                    <ListItemText
                      sx={{ mb: 2 }}
                      primary={
                        <Typography gutterBottom variant="h6" component="div">
                          Alterar senha
                        </Typography>
                      }
                      secondary="Informe a senha atual e uma nova senha para alterar sua senha."
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={12}>
                        <TextField
                          color="warning"
                          size="small"
                          required
                          fullWidth
                          label="Senha atual"
                          type={currentPasswordVisible ? "text" : "password"}
                          autoComplete="current"
                          {...register("currentPassword", {
                            required: "Campo obrigatório.",
                          })}
                          helperText={errors.currentPassword?.message ?? ""}
                          error={!!errors.currentPassword}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                tabIndex={-1}
                                onClick={() =>
                                  setCurrentPasswordVisible(
                                    !currentPasswordVisible,
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={[
                                    "fal",
                                    !currentPasswordVisible
                                      ? "eye"
                                      : "eye-slash",
                                  ]}
                                />
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={12}>
                        <TextField
                          color="warning"
                          size="small"
                          required
                          fullWidth
                          label="Nova senha"
                          type={passwordVisible ? "text" : "password"}
                          autoComplete="password"
                          {...register("newPassword", {
                            required: "Campo obrigatório.",
                            pattern: {
                              value:
                                /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
                              message:
                                "A senha deve conter ao menos um número, uma letra maiúscula, uma minúscula e um caractere especial.",
                            },
                          })}
                          helperText={errors.newPassword?.message ?? ""}
                          error={!!errors.newPassword}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                tabIndex={-1}
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                              >
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={[
                                    "fal",
                                    !passwordVisible ? "eye" : "eye-slash",
                                  ]}
                                />
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={12}>
                        <TextField
                          color="warning"
                          size="small"
                          required
                          fullWidth
                          label="Confirmar nova senha"
                          type={
                            newPasswordConfirmationVisible ? "text" : "password"
                          }
                          {...register("newPasswordConfirmation", {
                            required: "Campo obrigatório.",
                            validate: (value) =>
                              value === password || "As senhas não coincidem.",
                            pattern: {
                              value:
                                /(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/g,
                              message:
                                "A senha deve conter ao menos um número, uma letra maiúscula, uma minúscula e um caractere especial.",
                            },
                          })}
                          helperText={
                            errors.newPasswordConfirmation?.message ?? ""
                          }
                          error={!!errors.newPasswordConfirmation}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                tabIndex={-1}
                                onClick={() =>
                                  setConfirmPasswordVisible(
                                    !newPasswordConfirmationVisible,
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={[
                                    "fal",
                                    !newPasswordConfirmationVisible
                                      ? "eye"
                                      : "eye-slash",
                                  ]}
                                />
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeDialog()} autoFocus sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <UpConfirmButton
              apiResult={saveApiResult}
              iconPrefix="fal"
              sx={{ ml: 2 }}
              text="Confirmar"
              icon={["fal", "check"]}
            />
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

const Sidebar = ({ drawerWidth }) => {
  const [menus, setMenus] = useState<UpMenuItem[]>([]);
  const theme = useTheme();
  const isGreaterThanMd = useMediaQuery(theme.breakpoints.up("md"));
  const sidebarStatus = useExternalSidebarStatus();
  const setSidebarStatus = useSetExternalSidebarStatus();
  const getMenu = useMenuItems();
  const { role } = useAppContext();

  useEffect(() => {
    if (role) {
      setMenus(getMenu(role));
    }
  }, [role]);

  useEffect(() => {
    setTimeout(() => {
      toggleDrawer(isGreaterThanMd, true);
    });
  }, [isGreaterThanMd]);

  const toggleDrawer = (value: boolean, fromMediaQuery: boolean = false) => {
    if (fromMediaQuery) {
      setSidebarStatus(value);
    } else {
      if (isGreaterThanMd) setSidebarStatus(true);
      else setSidebarStatus(value);
    }
  };

  return (
    <>
      <Box sx={{ width: isGreaterThanMd ? drawerWidth + 48 : 0 }}>
        {/* Div que serve como divisor entre a sidebar e o main content */}
      </Box>

      <Drawer
        variant={isGreaterThanMd ? "permanent" : "temporary"}
        open={sidebarStatus}
        onClose={() => toggleDrawer(false)}
        sx={{ zIndex: isGreaterThanMd ? 0 : 9999 }}
      >
        <Paper color="primary" sx={{ width: drawerWidth, height: 1 }}>
          <Box
            sx={{
              width: 1,
              height: 57,
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            <Logo />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <List sx={{ width: 1 }}>
              {menus.map((item, i) => (
                <UpListItem
                  key={i}
                  item={item}
                  onClick={() => toggleDrawer(false)}
                />
              ))}
            </List>
          </Box>
        </Paper>
      </Drawer>
    </>
  );
};

const MainContent = () => {
  return (
    <Box
      component="main"
      sx={{ width: 1, maxWidth: 1, overflowX: "hidden", mt: 10, px: 3 }}
    >
      <Suspense fallback={<p>Carregando...</p>}>
        <Outlet />
      </Suspense>
    </Box>
  );
};

const UpListItem = ({
  item,
  onClick,
}: {
  item: UpMenuItem;
  onClick: Function;
}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const goTo = (route: To) => {
    onClick();
    navigate(route);
  };

  const isActive =
    location.pathname === "/"
      ? item.route === "/"
      : location.pathname
          .substring(1)
          .startsWith(item.route?.toString().substring(1) || "◬");
  const color = isActive
    ? theme.palette.warning.main
    : theme.palette.primary.main;

  return (
    <ListItemButton onClick={() => goTo(item.route!)}>
      <Box sx={{ width: "24px", textAlign: "center" }}>
        <FontAwesomeIcon icon={item.icon} color={color} />
      </Box>
      <Typography color={color} sx={{ ml: 1 }}>
        {item.text}
      </Typography>
    </ListItemButton>
  );
};

const ExternalUserLayout = () => {
  const drawerWidth = 0;
  // Utilizado para ocultar menu lateral no mobile
  // const drawerWidth = 240;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <AppHeader drawerWidth={drawerWidth} />

      <Stack direction="row" sx={{ display: "flex" }}>
        {/* <Sidebar drawerWidth={drawerWidth} /> */}
        <MainContent />
      </Stack>
    </Box>
  );
};

export default ExternalUserLayout;
