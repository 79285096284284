import { UpBackButton } from "@done/react-essentials";
import { Box, Card, Typography } from "@mui/material";
import React from "react";

const NotRolePage = () => {
    return (
        <Box
            sx={{
                height: "100dvh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Card sx={{
                p: 5,
                textAlign: "center",
                maxWidth: "600px",
                width: "100%"
            }}>
                <Typography component="h2" variant="h5">
                    Nenhuma alçada encontrada, favor entrar em contato com o administrador do sistema
                </Typography>
                <UpBackButton sx={{ mt: 3 }} />
            </Card>
        </Box>
    );
};

export default NotRolePage;