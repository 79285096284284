import { UpApiResult, VupConsoleAccount } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../http/http-client";
import { CreateExternalUser } from "../models/create-external-user";
import { ExternalUser } from "../models/external-user";
import { HttpStatusCode } from "axios";
import ExternalHttpClient from "../../../http/external-http-client";
import { ChangePassword } from "../../login-external/models/change-password";
import { CreateUser } from "../../login-external/models/create-user";
import { LoginRequest } from "../../login-external/models/login-request";
import { ResetPassword } from "../../login-external/models/reset-password";
import { UpdateUser } from "../../login-external/models/update-user";
import { User } from "../../login-external/models/user";
import { LoginResult } from "../../login-external/models/login-result";
import { HardResetPassword } from "../models/hard-reset-password";

const controller = "help-desk/externalUsers";

export default class ExternalUserService {
  private USER_STORAGE: string = "vup_help_desk";
  static myInstance: ExternalUserService | null = null;

  static get instance(): ExternalUserService {
    return (ExternalUserService.myInstance ??= new ExternalUserService());
  }

  async list(): Promise<UpApiResult<ExternalUser[]>> {
    const response = await HttpClient.instance.get(`/${controller}`);
    return validateHttpResponse(response);
  }

  async listByTenantGroupId(tenantGroupId: number): Promise<UpApiResult<ExternalUser[]>> {
    const response = await HttpClient.instance.get(`/${controller}/listByTenantGroupId/${tenantGroupId}`);
    return validateHttpResponse(response);
  }

  async listActives(): Promise<UpApiResult<ExternalUser[]>> {
    const response = await HttpClient.instance.get(`/${controller}/actives`);
    return validateHttpResponse(response);
  }

  async find(id: string): Promise<UpApiResult<ExternalUser>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async create(task: CreateExternalUser): Promise<UpApiResult<ExternalUser>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}`, task);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async update(
    id: string,
    task: CreateExternalUser,
  ): Promise<UpApiResult<ExternalUser>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/update/${id}`,
        task,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: string): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async activeOrDeactive(id: string): Promise<UpApiResult<ExternalUser>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/activeOrDeactive/${id}`,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  //#region Login
  //#region Requests
  async me(): Promise<UpApiResult<User>> {
    const response = await ExternalHttpClient.instance.get(`/${controller}/me`);
    return validateHttpResponse(response);
  }

  async findAsync(id: string): Promise<User> {
    const response = await ExternalHttpClient.instance.get(`/${controller}/${id}`);

    if (response.status === HttpStatusCode.Ok) return response.data;
    else throw new Error(response.data);
  }
  //#endregion

  //#region ToDO: Melhorar retorno de métodos com erro
  async registerAsync(model: CreateUser): Promise<any> {
    const response = await ExternalHttpClient.instance.post(`/${controller}`, model);

    if (response.status === HttpStatusCode.Ok) {
      return {
        success: true,
        user: response.data,
      };
    } else {
      return {
        success: false,
        error: response.data.title,
      };
    }
  }

  async updateAsync(id: string, model: UpdateUser): Promise<any> {
    const response = await ExternalHttpClient.instance.put(
      `/${controller}/update/${id}`,
      model,
    );

    if (response.status === HttpStatusCode.Ok) {
      return {
        success: true,
        user: response.data,
      };
    } else {
      return {
        success: false,
        error: response.data.title,
      };
    }
  }

  async hardResetPasswordAsync(model: HardResetPassword): Promise<boolean> {
    const response = await HttpClient.instance.patch(
      `/${controller}/hardResetPassword`,
      model,
    );
    if (response.status === HttpStatusCode.Ok) return response.data;
    else throw new Error(response.data);
  }

  async changePasswordAsync(model: ChangePassword): Promise<boolean> {
    const response = await ExternalHttpClient.instance.patch(
      `/${controller}/changePassword`,
      model,
    );

    if (response.status === HttpStatusCode.Ok) return response.data;
    else throw new Error(response.data);
  }

  async requestPassworRecoverydAsync(email: string): Promise<boolean> {
    const response = await ExternalHttpClient.instance.patch(
      `/${controller}/requestPasswordRecovery`,
      null,
      {
        params: { email },
      },
    );

    if (response.status === HttpStatusCode.Ok) return response.data;
    else throw new Error(response.data);
  }

  async recoverPasswordAsync(model: ResetPassword): Promise<boolean> {
    const response = await ExternalHttpClient.instance.patch(
      `/${controller}/recoverPassword`,
      model,
    );

    if (response.status === HttpStatusCode.Ok) return response.data;
    else throw new Error(response.data);
  }

  async loginAsync(model: LoginRequest, account: VupConsoleAccount): Promise<UpApiResult<LoginResult | null>> {
    try {
      const response = await ExternalHttpClient.instance.post(`/${controller}/login`, model, {
        headers: {
          "X-Account-Domain": account?.domain ?? "",
          "X-Account-Id": account?.id ?? "",
        }
      });

      if (response.status === HttpStatusCode.Ok)
        this.setAuthOptions(response.data!, account);

      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async logoutAsync(): Promise<void> {
    const response = await ExternalHttpClient.instance.patch(`/${controller}/logout`);

    this.removeAuthOptions();

    if (response.status !== HttpStatusCode.Ok) throw new Error(response.data!);
  }
  //#endregion

  //#region Functions
  private setAuthOptions(result: LoginResult, account?: VupConsoleAccount): LoginResult {
    result.account = account;
    window.localStorage.setItem(this.USER_STORAGE, JSON.stringify(result));

    return result;
  }

  private removeAuthOptions(): void {
    window.localStorage.removeItem(this.USER_STORAGE);
  }

  getToken(): string {
    const auth: LoginResult | null = this.getAuthOptions();

    return auth?.token ?? "";
  }

  getAuthOptions(): LoginResult | null {
    const storageContent: string | null = localStorage.getItem(
      this.USER_STORAGE,
    );

    if (!storageContent) return null;

    return JSON.parse(storageContent);
  }
  //#endregion
  //#endregion
}
