import { LoginResult, UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Avatar, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Controller, useFormState } from "react-hook-form";
import { PermissionAccountUser } from "../../account-user/models/permission-account-user";
import { AccountUserService } from "../../account-user/services/account-user-service";
import { environment } from "../../../../shared/utils/environment";
import Cookies from "js-cookie";

interface AttributionFieldProps {
    control: any;
    disabled?: boolean;
}

const AttributionField = ({
    control,
    disabled
}: AttributionFieldProps) => {
    const [attributions, setAttributions] = useState<UpApiResult<PermissionAccountUser[]>>(UpApiResult.start());

    const { errors } = useFormState<PermissionAccountUser>({ control: control });

    const sessionWks = Cookies.get("session");
    const sessionObjWks: LoginResult | null = sessionWks ? JSON.parse(sessionWks) : null;

    const session = localStorage.getItem("vup_help_desk");
    const sessionObj: LoginResult | null = session ? JSON.parse(session) : null;

    const fetchData = async () => {
        setAttributions(await AccountUserService.instance.listAdminAndAgentsByAccountAsync(sessionObjWks?.account?.id ?? sessionObj!.account!.id!));
    };

    useEffect(() => {
        if (attributions.status === UpApiResultStatus.loading) fetchData();
    }, [attributions]);

    const [attributionUser, setAttributionUser] = useState<PermissionAccountUser | null>(null);

    return (
        <Controller
            name="assignedUserId"
            control={control}
            defaultValue={null}
            disabled={!sessionObjWks || !!disabled}
            render={({ field: { onChange, value } }) => (

                <Autocomplete
                    options={attributions.data || []}
                    getOptionLabel={(user: PermissionAccountUser) => user.userName}
                    color="warning"
                    size="small"
                    fullWidth
                    disabled={!sessionObjWks || !!disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="warning"
                            size="small"
                            label="Atribuido a"
                            placeholder={
                                attributions.status === UpApiResultStatus.loading
                                    ? "Carregando..."
                                    : ""
                            }
                            error={!!errors.id}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment:
                                    <>
                                        {attributions.status === UpApiResultStatus.loading && (
                                            <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                                                <FontAwesomeIcon icon={["fal", "loader"]} spin />
                                            </InputAdornment>
                                        )}

                                        {!!attributionUser && (
                                            <Tooltip title={attributionUser.userEmail}>
                                                <Avatar alt={attributionUser.userName} src={`${environment.VITE_API_GATEWAY_URL}/onstock/images/${attributionUser.image}`} sx={{ width: 24, height: 24 }} />
                                            </Tooltip>
                                        )}
                                    </>,
                            }}
                            fullWidth
                        // required={required}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.userName} { }
                        </li>
                    )}
                    value={
                        attributions.data?.find(
                            (accountUser: PermissionAccountUser) => accountUser.userId === value,
                        ) ?? null
                    }
                    onChange={(_, selectedOption) => {
                        onChange(selectedOption?.userId ?? null);
                        setAttributionUser(selectedOption);
                    }}
                />
            )}
        />
    );
};

export default memo(AttributionField);
