import { IconPrefix, IconName } from "@fortawesome/fontawesome-svg-core";
import { To } from "react-router-dom";

export class UpMenuItem {
  icon: [IconPrefix, IconName];
  text: string;
  route: To | null;
  externalUrl: string | null;
  tooltip: string;
  description?: string;

  constructor(
    icon: [IconPrefix, IconName],
    text: string,
    tooltip: string,
    route: To | null = null,
    externalUrl: string | null = null,
    description?: string,
  ) {
    this.icon = icon;
    this.text = text;
    this.route = route;
    this.tooltip = tooltip;
    this.description = description;
    this.externalUrl = externalUrl;

    if (!route && !externalUrl)
      throw new Error("Either 'router' or 'externalUrl' must be provided.");
  }
}
