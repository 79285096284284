import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { AccountUser } from "../models/account-user";
import { PermissionAccountUser } from "../models/permission-account-user";
import { environment } from "../../../../shared/utils/environment";

const controller = "auth/accountsUsers";

export class AccountUserService {
  private static _instance: AccountUserService | null = null;

  static get instance(): AccountUserService {
    return (AccountUserService._instance ??= new AccountUserService());
  }

  async listAgentsByAccountAsync(accountId: number): Promise<UpApiResult<PermissionAccountUser[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/agents/account/${accountId}`,
    );

    return validateHttpResponse(response);
  }

  async listAdminAndAgentsByAccountAsync(accountId: number): Promise<UpApiResult<PermissionAccountUser[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/admin-and-agents/account/${accountId}`,
    );

    return validateHttpResponse(response);
  }

  async listByUser(userId: string): Promise<UpApiResult<AccountUser[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/user/${userId}`,
    );

    return validateHttpResponse(response);
  }

  async findAlcadaHelpDesk(userId: string, accountId: number): Promise<UpApiResult<string>> {
    const response = await HttpClient.instance.get(`/${controller}/find-alcada-help-desk/${userId}/${accountId}`);
    return validateHttpResponse(response);
  }

  async canAccess(userId: string, accountId: number): Promise<UpApiResult<boolean>> {
    const softwareId_HelpDesk = 9;

    const params = new URLSearchParams({
      softwareId: softwareId_HelpDesk.toString(),
      accountId: accountId.toString(),
      userId: userId,
    });

    const response = await HttpClient.instance.get(
      `${environment.VITE_API_GATEWAY_URL}/workspace/core/softwareAccesses/canAccess?${params.toString()}`
    );

    return validateHttpResponse(response);
  }
}
