import { useRecoilValue, useSetRecoilState } from "recoil";
import { externalSidebarStatusState } from "../states/external-sidebar-status-state";

const useExternalSidebarStatus = () => {
  return true; // Utilizado para ocultar menu lateral no mobile
  return useRecoilValue(externalSidebarStatusState);
};

const useSetExternalSidebarStatus = () => {
  const setSidebarStatus = useSetRecoilState(externalSidebarStatusState);

  return (opened: boolean) => {
    setSidebarStatus(opened);
  };
};

export { useExternalSidebarStatus, useSetExternalSidebarStatus };
