export enum Priority {
    Highest = 2,
    High = 1,
    Medium = 0,
    Low = -1,
    Lowest = -2,
}

export const PrioritysNames = [
    { value: Priority.Highest, label: "Altíssima" },
    { value: Priority.High, label: "Alta" },
    { value: Priority.Medium, label: "Média" },
    { value: Priority.Low, label: "Baixo" },
    { value: Priority.Lowest, label: "Muito baixo" },
];