import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography
} from "@mui/material";
import Chip from '@mui/material/Chip';
import React, { useEffect, useRef, useState } from "react";
import withMe from "../../../../integration/user/hocs/withMe";
import { User } from "../../../../integration/user/models/user";
import { Interaction } from "../../models/interaction";
import InteractionService from "../../services/interaction-service";
import UploadService from "../../../../upload/services/upload.service";
import { CreateAttachment } from "../../../attachment/models/create-attachment";
import { Visibility } from "../../enums/visibility";
import { environment } from "../../../../../shared/utils/environment";
import "../../../../../shared/utils/up-date-time-converter";
import { useAppContext } from "../../../../../shared/contexts/app-context";

type InteractionListProps = {
  ticketId: number;
  me: User | null;
};

const InteractionList = ({ ticketId, me }: InteractionListProps) => {
  const {interactions, setInteractions} = useAppContext();

  const download = (attachment: CreateAttachment) => {
    window.open(`${UploadService.downloadBaseUrl}/${attachment.url}`, "_blank");
  };

  useEffect(() => {
    if (interactions.status === UpApiResultStatus.loading) {
      fetchData();
    }

    // rolar até o ultimo item da lista
    if (interactions.data?.length > 0)
      listEndRef.current?.scrollIntoView({ behavior: "smooth" });

  }, [interactions]);

  const listEndRef = useRef<HTMLDivElement | null>(null);

  const fetchData = async () => {
    setInteractions(await InteractionService.instance.listByTicketId(ticketId));
  };

  return (
    <>
      {interactions.status === UpApiResultStatus.loading && (
        <Typography>Carregando...</Typography>
      )}

      {interactions.data?.map((interaction: Interaction) => (
        // Esse box serve para alinhar à esquerda ou a direita
        <Box
          sx={{
            marginLeft: interaction.userId === me?.id ? "0%" : "initial",
            marginRight: interaction.userId === me?.id ? "inital" : "0%",
          }}
          key={interaction.id}
        >
          <Tooltip title={interaction.visibility === Visibility.Public ? "" : "Resposta visível somente para agentes"}>
            <Card
              sx={{
                bgcolor: (theme) => {
                  if (interaction.visibility === Visibility.Private) {
                    return "rgba(255, 220, 115, .2)";
                  }

                  return interaction.userId === me?.id
                    ? theme.palette.secondary.dark
                    : "initial";
                },
                border: interaction.visibility === Visibility.Public ? "none" : "dashed 3px rgba(255, 220, 115, .3)",
                margin: "5%",
                borderRadius: "20px",
                width: "80%",
                marginLeft: interaction.userId === me?.id
                  ? "auto"
                  : "5%"
              }}
            >
              <CardContent sx={{
                padding: 0,
              }}>
                <ListItem>
                  <ListItemAvatar sx={{ position: "relative" }}>
                    <Avatar
                      src={interaction.user?.image
                        ? `${environment.VITE_API_GATEWAY_URL}/onstock/images/${interaction.user.image}`
                        : undefined}
                      alt={interaction.user?.name}
                      sx={{
                        bgcolor: (theme) =>
                          interaction.userId === me?.id
                            ? theme.palette.primary.dark
                            : theme.palette.secondary.dark,
                      }}
                      aria-label="user-first-char"
                    >
                    </Avatar>
                    {interaction.visibility === Visibility.Private &&
                      <Box sx={{ position: "absolute", bottom: "-.5rem", right: "1rem", }}>
                        <FontAwesomeIcon icon={["fas", "lock"]} size="xs" />
                      </Box>
                    }
                  </ListItemAvatar>
                  <ListItemText
                    primary={interaction.user?.name}
                    secondary={
                      <Typography component="span" fontSize={12} sx={{ opacity: .75 }}>
                        {interaction.creationDate.toUpLocalDateTime(true)}
                      </Typography>
                    }
                  />
                </ListItem>

                <Typography variant="body1" sx={{ margin: 2, marginTop: 0, width: "fit-content", whiteSpace: "pre-line" }}>
                  {interaction.text}
                </Typography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin: 2, }}>
                  {interaction.attachments.map((attachment, index) => (
                    <Chip
                      key={index}
                      icon={<FontAwesomeIcon icon={["fal", "download"]} />}
                      label={attachment.name}
                      onClick={(e) => download(attachment)}

                      sx={{ maxWidth: '230px', height: '30px' }}
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Tooltip>
        </Box>
      ))}

      {/* Referência para rolar até o final */}
      <div ref={listEndRef} />
    </>
  );
};

export default withMe(InteractionList);
