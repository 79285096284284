import { Card, CardContent, Grid, ListItemText } from "@mui/material";
import { ReactNode } from "react";

interface UpDividedCardProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  children: string | ReactNode;
}

const UpDividedCard = ({
  title,
  description,
  children,
}: UpDividedCardProps) => {
  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={5} lg={4}>
            <ListItemText
              primaryTypographyProps={{
                gutterBottom: true,
                variant: "h6",
                component: "div",
              }}
              primary={
                <>
                  {typeof title === "string" && (title || "-")}
                  {typeof title !== "string" && title}
                </>
              }
              secondary={
                <>
                  {!!description &&
                    typeof description === "string" &&
                    (description || "-")}
                  {!!description &&
                    typeof description !== "string" &&
                    description}
                </>
              }
            />
          </Grid>

          <Grid item xs={0} md={1} lg={2}></Grid>

          <Grid item xs={12} md={6} lg={6}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UpDividedCard;
