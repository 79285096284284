import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { OccupationArea } from "../models/occupation-area";
import OccupationAreaService from "../services/occupation-area-service";
import { CreateTicket } from "../../../ticket/models/create-ticket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OccupationAreaFieldProps {
    setValue,
    control: any;
    required?: boolean;
    disabled?: boolean;
}

const OccupationAreaField = ({
    setValue,
    control,
    required = true,
    disabled = false,
}: OccupationAreaFieldProps) => {
    const watchBusinessUnitId = useWatch({ control, name: "businessUnitId" });
    const { errors } = useFormState<CreateTicket>({ control: control });

    const [occupationsAreas, setOccupationsAreas] = useState<UpApiResult<OccupationArea[]>>(UpApiResult.start());

    const fetchData = async () => {
        if (!watchBusinessUnitId) {
            setOccupationsAreas(UpApiResult.new());
            return;
        }

        const preventRelist = (occupationsAreas.data as OccupationArea[])?.some(
            (x) => x.businessUnitId === watchBusinessUnitId,
        );

        if (preventRelist) return;

        setOccupationsAreas(UpApiResult.start());

        setOccupationsAreas(await OccupationAreaService.instance.listByBusinessUnit(watchBusinessUnitId));
    };

    useEffect(() => {
        fetchData();
    }, [watchBusinessUnitId]);

    const fieldValidationRules = {};

    if (required) {
        fieldValidationRules["required"] =
            "O campo área de atuação é obrigatório.";
    }

    return (
        <Controller
            name="occupationAreaId"
            control={control}
            defaultValue={null}
            rules={fieldValidationRules}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    options={occupationsAreas.data || []}
                    getOptionLabel={(occupationArea: OccupationArea) => occupationArea.name}
                    color="warning"
                    size="small"
                    fullWidth
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="warning"
                            size="small"
                            label="Áreas de Atuação"
                            placeholder={
                                occupationsAreas.status === UpApiResultStatus.loading
                                    ? "Carregando..."
                                    : ""
                            }
                            helperText={
                                errors.occupationAreaId?.message ??
                                (required ? "*Obrigatório" : "")
                            }
                            error={!!errors.occupationAreaId}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: occupationsAreas.status ===
                                    UpApiResultStatus.loading && (
                                        <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                                            <FontAwesomeIcon icon={["fal", "loader"]} spin />
                                        </InputAdornment>
                                    ),
                            }}
                            fullWidth
                            required={required}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    value={
                        occupationsAreas.data?.find(
                            (occupationArea: OccupationArea) => occupationArea.id === value,
                        ) ?? null
                    }
                    onChange={(_, value) => {
                        setValue("departmentId", null);

                        return onChange(value?.id ?? null);
                    }}
                />
            )}
        />
    );
};

export default memo(OccupationAreaField);
