import React from "react";
import useThemeMode from "../../themes/hooks/use-theme-mode";

const LogoLight = () => (
  <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 746 439">
    <defs>
      <style>
        {".cls-1 {fill: #a15609;} .cls-1, .cls-2, .cls-3 {stroke-width: 0px;} .cls-2 {fill: #003251;} .cls-3 {fill: #00477d;}"}
      </style>
    </defs>
    <g>
      <g>
        <path className="cls-3" d="M154.78,65.43c3.25-.54,6.45-.9,9.61-1.08,3.16-.18,6.27-.27,9.34-.27s6.23.09,9.48.27c3.25.18,6.5.54,9.75,1.08v193.3c-8.49,2.53-17.1,4.47-25.85,5.82-8.76,1.35-19.36,2.03-31.81,2.03-9.57,0-18.73-1.31-27.48-3.93-8.76-2.62-16.47-6.77-23.15-12.45-6.68-5.69-11.96-13.04-15.84-22.07-3.88-9.02-5.82-20.03-5.82-33.03,0-10.29,1.67-19.99,5.01-29.1,3.34-9.11,8.12-17.01,14.35-23.69,6.23-6.67,13.76-11.95,22.61-15.84,8.84-3.88,18.68-5.82,29.51-5.82,3.25,0,6.63.13,10.15.41s6.9.86,10.15,1.76v-57.4ZM154.78,152.34c-3.61-.9-6.54-1.49-8.8-1.76-2.26-.27-5.1-.41-8.53-.41-5.96,0-11.15,1.22-15.57,3.66-4.43,2.44-8.08,5.69-10.96,9.75-2.89,4.06-5.01,8.85-6.36,14.35-1.35,5.51-2.03,11.24-2.03,17.19,0,7.4.9,13.67,2.71,18.82,1.8,5.14,4.29,9.25,7.44,12.32,3.16,3.07,6.9,5.28,11.24,6.63s9.02,2.03,14.08,2.03c3.07,0,5.86-.14,8.39-.41,2.53-.27,5.32-.85,8.39-1.76v-80.41Z" />
        <path className="cls-3" d="M274.88,266.59c-11.37,0-21.25-1.85-29.65-5.55-8.39-3.7-15.34-8.84-20.85-15.43-5.51-6.59-9.66-14.3-12.45-23.15-2.8-8.84-4.2-18.41-4.2-28.7s1.4-19.94,4.2-28.97c2.8-9.02,6.95-16.83,12.45-23.42,5.5-6.59,12.45-11.78,20.85-15.57,8.39-3.79,18.27-5.69,29.65-5.69s21.29,1.89,29.78,5.69c8.48,3.79,15.52,8.98,21.12,15.57,5.59,6.59,9.75,14.4,12.45,23.42,2.71,9.03,4.06,18.68,4.06,28.97s-1.35,19.86-4.06,28.7c-2.71,8.85-6.86,16.56-12.45,23.15-5.6,6.59-12.64,11.73-21.12,15.43-8.49,3.7-18.41,5.55-29.78,5.55ZM274.88,237.35c10.11,0,17.37-3.74,21.79-11.24,4.42-7.49,6.63-18.27,6.63-32.35s-2.21-24.86-6.63-32.35c-4.42-7.49-11.69-11.24-21.79-11.24s-17.1,3.75-21.52,11.24c-4.43,7.49-6.63,18.27-6.63,32.35s2.21,24.86,6.63,32.35c4.42,7.49,11.59,11.24,21.52,11.24Z" />
        <path className="cls-3" d="M357.09,123.37c2.53-.54,5.05-.95,7.58-1.22,2.53-.27,5.32-.41,8.39-.41s5.77.14,8.12.41c2.34.27,4.78.68,7.31,1.22.54.91,1.04,2.21,1.49,3.93.45,1.72.85,3.52,1.22,5.41.36,1.89.68,3.75.95,5.55.27,1.81.49,3.34.68,4.6,1.8-2.71,3.97-5.41,6.5-8.12,2.52-2.71,5.46-5.14,8.8-7.31,3.34-2.17,7.08-3.93,11.24-5.28,4.15-1.35,8.75-2.03,13.81-2.03,15.88,0,27.84,4.33,35.87,12.99,8.03,8.66,12.05,22.29,12.05,40.88v89.34c-6.5,1.08-12.91,1.62-19.22,1.62s-12.72-.54-19.22-1.62v-79.05c0-9.75-1.49-17.23-4.47-22.47-2.98-5.23-8.17-7.85-15.57-7.85-3.07,0-6.23.45-9.47,1.35-3.25.91-6.19,2.66-8.8,5.28s-4.74,6.27-6.36,10.96c-1.62,4.7-2.44,10.83-2.44,18.41v73.37c-6.5,1.08-12.91,1.62-19.22,1.62s-12.72-.54-19.22-1.62V123.37Z" />
        <path className="cls-3" d="M533.77,205.13c.72,10.83,4.51,18.68,11.37,23.55,6.86,4.87,15.88,7.31,27.07,7.31,6.13,0,12.41-.58,18.82-1.76,6.4-1.17,12.5-2.84,18.27-5.01,1.98,3.61,3.7,7.94,5.14,13,1.44,5.06,2.25,10.47,2.44,16.24-14.08,5.42-29.96,8.12-47.65,8.12-13,0-24.14-1.81-33.44-5.41-9.3-3.61-16.88-8.62-22.74-15.03-5.87-6.4-10.2-14.03-12.99-22.88-2.8-8.84-4.2-18.5-4.2-28.97,0-10.29,1.44-19.94,4.33-28.97,2.89-9.02,7.17-16.87,12.86-23.55,5.69-6.68,12.72-11.95,21.12-15.84,8.39-3.88,18.18-5.82,29.37-5.82,9.75,0,18.41,1.72,25.99,5.14,7.58,3.43,14.03,8.12,19.36,14.08,5.32,5.96,9.34,13.04,12.05,21.25,2.71,8.21,4.06,17.01,4.06,26.4,0,3.43-.14,6.81-.41,10.15-.27,3.34-.59,6-.95,7.99h-89.88ZM588.73,178.33c-.36-8.66-2.75-15.65-7.17-20.98-4.43-5.32-10.61-7.99-18.55-7.99-9.03,0-15.88,2.53-20.58,7.58-4.7,5.06-7.49,12.18-8.39,21.39h54.69Z" />
      </g>
      <path className="cls-1" d="M641.43,261.44c-1.09-6.7-1.63-13.31-1.63-19.83s.54-13.22,1.63-20.11c6.7-1.09,13.31-1.63,19.83-1.63s13.22.54,20.1,1.63c1.09,6.89,1.63,13.5,1.63,19.83,0,6.7-.54,13.41-1.63,20.11-6.89,1.09-13.49,1.63-19.83,1.63-6.7,0-13.41-.54-20.1-1.63ZM640.34,66.1c7.06-1.09,14.03-1.63,20.92-1.63s14.13.54,21.19,1.63l-2.72,132.86c-6.34,1.09-12.41,1.63-18.2,1.63-6.16,0-12.32-.54-18.48-1.63l-2.72-132.86Z" />
    </g>
    <g>
      <path className="cls-2" d="M179.09,374.51h-6.9v-20.1h-23.62v20.1h-6.9v-45.14h6.9v19.15h23.62v-19.15h6.9v45.14Z" />
      <path className="cls-2" d="M210.5,348.66h21.99v5.35h-21.99v14.69h23.15v5.82h-30.05v-45.14h29.92v5.82h-23.01v13.47Z" />
      <path className="cls-2" d="M263.22,368.29h22.13v6.23h-29.03v-45.14h6.9v38.92Z" />
      <path className="cls-2" d="M306,329.37h18.54c7.92,0,9.54.27,12.18,2.1,2.91,2.1,4.2,5.96,4.2,12.86s-1.83,11.23-5.89,12.93c-2.17.95-2.44,1.02-10.63,1.15h-11.51v16.11h-6.9v-45.14ZM323.8,352.45c8.8,0,10.15-1.15,10.15-8.26,0-5.35-.61-7.38-2.57-8.26-1.22-.61-2.37-.74-6.36-.74h-12.12v17.26h10.9Z" />
      <path className="cls-2" d="M396.15,329.37h21.52c6.36,0,10.15,1.15,12.72,3.93,3.11,3.32,4.13,7.24,4.13,15.7,0,11.78-.41,15.5-2.1,18.68-1.69,3.38-4.2,5.28-8.05,6.16-1.89.41-4.19.68-5.89.68h-22.34v-45.14ZM416.45,368.56c4.53,0,7.44-.88,8.87-2.77,1.76-2.3,2.23-5.21,2.23-13.47,0-9.27-.41-11.84-2.1-14.14-1.42-1.96-3.79-2.77-8.26-2.77h-14.14v33.16h13.4Z" />
      <path className="cls-2" d="M464.1,348.66h22v5.35h-22v14.69h23.15v5.82h-30.05v-45.14h29.92v5.82h-23.01v13.47Z" />
      <path className="cls-2" d="M535.57,342.09c0-3.05-.2-4.13-.88-5.21-1.08-1.69-3.04-2.23-8.46-2.23-9.34,0-11.1,1.08-11.1,7.04,0,3.59.88,5.35,3.11,6.09,1.29.47,1.9.54,8.93.88,7.99.41,10.49.88,12.66,2.44,2.5,1.9,3.45,4.67,3.45,10.09,0,6.56-1.15,9.75-4.13,11.64-2.64,1.62-5.89,2.1-14.55,2.1-7.11,0-9.61-.41-12.38-2.03-1.69-1.01-3.18-3.18-3.66-5.48-.41-2.03-.47-3.32-.54-7.04h6.77c0,4.06.2,5.08,1.02,6.5,1.15,2.03,2.37,2.3,9.95,2.3,9.54,0,10.69-.88,10.69-8.05,0-3.38-.74-4.94-2.64-5.69q-1.22-.47-9.07-.95c-8.19-.41-9.14-.61-11.44-1.69-3.59-1.76-5.08-4.94-5.08-10.9,0-5.21,1.08-8.53,3.45-10.29,2.71-2.03,6.09-2.64,14.14-2.64,8.93,0,12.45.95,14.62,3.99,1.29,1.89,1.9,4.33,1.9,7.78v1.35h-6.77Z" />
      <path className="cls-2" d="M572.53,348.39h4.26l16.65-19.02h8.73l-19.42,21.93,21.59,23.21h-9.07l-18.48-20.1h-4.26v20.1h-6.9v-45.14h6.9v19.02Z" />
    </g>
  </svg>
);

const LogoDark = () => (
  <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 747 439">
    <defs>
      <style>
        {".cls-1 {fill: #a15609;} .cls-1, .cls-2, .cls-3 {stroke-width: 0px;} .cls-2 {fill: #e2e2e2;} .cls-3 {fill: #f9f9f9;}"}
      </style>
    </defs>
    <g>
      <g>
        <path className="cls-3" d="M155.28,65.43c3.25-.54,6.45-.9,9.61-1.08,3.16-.18,6.27-.27,9.34-.27s6.23.09,9.48.27c3.25.18,6.5.54,9.75,1.08v193.3c-8.49,2.53-17.1,4.47-25.85,5.82-8.76,1.35-19.36,2.03-31.81,2.03-9.57,0-18.73-1.31-27.48-3.93-8.76-2.62-16.47-6.77-23.15-12.45-6.68-5.69-11.96-13.04-15.84-22.07-3.88-9.02-5.82-20.03-5.82-33.03,0-10.29,1.67-19.99,5.01-29.1,3.34-9.11,8.12-17.01,14.35-23.69,6.23-6.67,13.76-11.95,22.61-15.84,8.84-3.88,18.68-5.82,29.51-5.82,3.25,0,6.63.13,10.15.41s6.9.86,10.15,1.76v-57.4ZM155.28,152.34c-3.61-.9-6.54-1.49-8.8-1.76-2.26-.27-5.1-.41-8.53-.41-5.96,0-11.15,1.22-15.57,3.66-4.43,2.44-8.08,5.69-10.96,9.75-2.89,4.06-5.01,8.85-6.36,14.35-1.35,5.51-2.03,11.24-2.03,17.19,0,7.4.9,13.67,2.71,18.82,1.8,5.14,4.29,9.25,7.44,12.32,3.16,3.07,6.9,5.28,11.24,6.63s9.02,2.03,14.08,2.03c3.07,0,5.86-.14,8.39-.41,2.53-.27,5.32-.85,8.39-1.76v-80.41Z" />
        <path className="cls-3" d="M275.38,266.59c-11.37,0-21.25-1.85-29.65-5.55-8.39-3.7-15.34-8.84-20.85-15.43-5.51-6.59-9.66-14.3-12.45-23.15-2.8-8.84-4.2-18.41-4.2-28.7s1.4-19.94,4.2-28.97c2.8-9.02,6.95-16.83,12.45-23.42,5.5-6.59,12.45-11.78,20.85-15.57,8.39-3.79,18.27-5.69,29.65-5.69s21.29,1.89,29.78,5.69c8.48,3.79,15.52,8.98,21.12,15.57,5.59,6.59,9.75,14.4,12.45,23.42,2.71,9.03,4.06,18.68,4.06,28.97s-1.35,19.86-4.06,28.7c-2.71,8.85-6.86,16.56-12.45,23.15-5.6,6.59-12.64,11.73-21.12,15.43-8.49,3.7-18.41,5.55-29.78,5.55ZM275.38,237.35c10.11,0,17.37-3.74,21.79-11.24,4.42-7.49,6.63-18.27,6.63-32.35s-2.21-24.86-6.63-32.35c-4.42-7.49-11.69-11.24-21.79-11.24s-17.1,3.75-21.52,11.24c-4.43,7.49-6.63,18.27-6.63,32.35s2.21,24.86,6.63,32.35c4.42,7.49,11.59,11.24,21.52,11.24Z" />
        <path className="cls-3" d="M357.59,123.37c2.53-.54,5.05-.95,7.58-1.22,2.53-.27,5.32-.41,8.39-.41s5.77.14,8.12.41c2.34.27,4.78.68,7.31,1.22.54.91,1.04,2.21,1.49,3.93.45,1.72.85,3.52,1.22,5.41.36,1.89.68,3.75.95,5.55.27,1.81.49,3.34.68,4.6,1.8-2.71,3.97-5.41,6.5-8.12,2.52-2.71,5.46-5.14,8.8-7.31,3.34-2.17,7.08-3.93,11.24-5.28,4.15-1.35,8.75-2.03,13.81-2.03,15.88,0,27.84,4.33,35.87,12.99,8.03,8.66,12.05,22.29,12.05,40.88v89.34c-6.5,1.08-12.91,1.62-19.22,1.62s-12.72-.54-19.22-1.62v-79.05c0-9.75-1.49-17.23-4.47-22.47-2.98-5.23-8.17-7.85-15.57-7.85-3.07,0-6.23.45-9.47,1.35-3.25.91-6.19,2.66-8.8,5.28s-4.74,6.27-6.36,10.96c-1.62,4.7-2.44,10.83-2.44,18.41v73.37c-6.5,1.08-12.91,1.62-19.22,1.62s-12.72-.54-19.22-1.62V123.37Z" />
        <path className="cls-3" d="M534.27,205.13c.72,10.83,4.51,18.68,11.37,23.55,6.86,4.87,15.88,7.31,27.07,7.31,6.13,0,12.41-.58,18.82-1.76,6.4-1.17,12.5-2.84,18.27-5.01,1.98,3.61,3.7,7.94,5.14,13,1.44,5.06,2.25,10.47,2.44,16.24-14.08,5.42-29.96,8.12-47.65,8.12-13,0-24.14-1.81-33.44-5.41-9.3-3.61-16.88-8.62-22.74-15.03-5.87-6.4-10.2-14.03-12.99-22.88-2.8-8.84-4.2-18.5-4.2-28.97,0-10.29,1.44-19.94,4.33-28.97,2.89-9.02,7.17-16.87,12.86-23.55,5.69-6.68,12.72-11.95,21.12-15.84,8.39-3.88,18.18-5.82,29.37-5.82,9.75,0,18.41,1.72,25.99,5.14,7.58,3.43,14.03,8.12,19.36,14.08,5.32,5.96,9.34,13.04,12.05,21.25,2.71,8.21,4.06,17.01,4.06,26.4,0,3.43-.14,6.81-.41,10.15-.27,3.34-.59,6-.95,7.99h-89.88ZM589.23,178.33c-.36-8.66-2.75-15.65-7.17-20.98-4.43-5.32-10.61-7.99-18.55-7.99-9.03,0-15.88,2.53-20.58,7.58-4.7,5.06-7.49,12.18-8.39,21.39h54.69Z" />
      </g>
      <path className="cls-1" d="M641.93,261.44c-1.09-6.7-1.63-13.31-1.63-19.83s.54-13.22,1.63-20.11c6.7-1.09,13.31-1.63,19.83-1.63s13.22.54,20.1,1.63c1.09,6.89,1.63,13.5,1.63,19.83,0,6.7-.54,13.41-1.63,20.11-6.89,1.09-13.49,1.63-19.83,1.63-6.7,0-13.41-.54-20.1-1.63ZM640.84,66.1c7.06-1.09,14.03-1.63,20.92-1.63s14.13.54,21.19,1.63l-2.72,132.86c-6.34,1.09-12.41,1.63-18.2,1.63-6.16,0-12.32-.54-18.48-1.63l-2.72-132.86Z" />
    </g>
    <g>
      <path className="cls-2" d="M179.59,374.51h-6.9v-20.1h-23.62v20.1h-6.9v-45.14h6.9v19.15h23.62v-19.15h6.9v45.14Z" />
      <path className="cls-2" d="M211,348.66h21.99v5.35h-21.99v14.69h23.15v5.82h-30.05v-45.14h29.92v5.82h-23.01v13.47Z" />
      <path className="cls-2" d="M263.72,368.29h22.13v6.23h-29.03v-45.14h6.9v38.92Z" />
      <path className="cls-2" d="M306.5,329.37h18.54c7.92,0,9.54.27,12.18,2.1,2.91,2.1,4.2,5.96,4.2,12.86s-1.83,11.23-5.89,12.93c-2.17.95-2.44,1.02-10.63,1.15h-11.51v16.11h-6.9v-45.14ZM324.3,352.45c8.8,0,10.15-1.15,10.15-8.26,0-5.35-.61-7.38-2.57-8.26-1.22-.61-2.37-.74-6.36-.74h-12.12v17.26h10.9Z" />
      <path className="cls-2" d="M396.65,329.37h21.52c6.36,0,10.15,1.15,12.72,3.93,3.11,3.32,4.13,7.24,4.13,15.7,0,11.78-.41,15.5-2.1,18.68-1.69,3.38-4.2,5.28-8.05,6.16-1.89.41-4.19.68-5.89.68h-22.34v-45.14ZM416.95,368.56c4.53,0,7.44-.88,8.87-2.77,1.76-2.3,2.23-5.21,2.23-13.47,0-9.27-.41-11.84-2.1-14.14-1.42-1.96-3.79-2.77-8.26-2.77h-14.14v33.16h13.4Z" />
      <path className="cls-2" d="M464.6,348.66h22v5.35h-22v14.69h23.15v5.82h-30.05v-45.14h29.92v5.82h-23.01v13.47Z" />
      <path className="cls-2" d="M536.07,342.09c0-3.05-.2-4.13-.88-5.21-1.08-1.69-3.04-2.23-8.46-2.23-9.34,0-11.1,1.08-11.1,7.04,0,3.59.88,5.35,3.11,6.09,1.29.47,1.9.54,8.93.88,7.99.41,10.49.88,12.66,2.44,2.5,1.9,3.45,4.67,3.45,10.09,0,6.56-1.15,9.75-4.13,11.64-2.64,1.62-5.89,2.1-14.55,2.1-7.11,0-9.61-.41-12.38-2.03-1.69-1.01-3.18-3.18-3.66-5.48-.41-2.03-.47-3.32-.54-7.04h6.77c0,4.06.2,5.08,1.02,6.5,1.15,2.03,2.37,2.3,9.95,2.3,9.54,0,10.69-.88,10.69-8.05,0-3.38-.74-4.94-2.64-5.69q-1.22-.47-9.07-.95c-8.19-.41-9.14-.61-11.44-1.69-3.59-1.76-5.08-4.94-5.08-10.9,0-5.21,1.08-8.53,3.45-10.29,2.71-2.03,6.09-2.64,14.14-2.64,8.93,0,12.45.95,14.62,3.99,1.29,1.89,1.9,4.33,1.9,7.78v1.35h-6.77Z" />
      <path className="cls-2" d="M573.03,348.39h4.26l16.65-19.02h8.73l-19.42,21.93,21.59,23.21h-9.07l-18.48-20.1h-4.26v20.1h-6.9v-45.14h6.9v19.02Z" />
    </g>
  </svg>
);

const Logo = () => {
  const themeMode = useThemeMode();

  if (themeMode === "dark") return <LogoDark />;

  return <LogoLight />;
};

export default Logo;
