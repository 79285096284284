import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../http/http-client";
import { Classification } from "../models/classification";
import { CreateClassification } from "../models/create-classification";

const controller = "help-desk/classifications";

export default class ClassificationService {
  static myInstance: ClassificationService | null = null;

  static get instance(): ClassificationService {
    return (ClassificationService.myInstance ??= new ClassificationService());
  }

  async list(): Promise<UpApiResult<Classification[]>> {
    const response = await HttpClient.instance.get(`/${controller}`);
    return validateHttpResponse(response);
  }

  async listActives(): Promise<UpApiResult<Classification[]>> {
    const response = await HttpClient.instance.get(`/${controller}/actives`);
    return validateHttpResponse(response);
  }

  async listActivesByDepartment(departmentId: number): Promise<UpApiResult<Classification[]>> {
    const response = await HttpClient.instance.get(`/${controller}/actives/department/${departmentId}`);
    return validateHttpResponse(response);
  }

  async find(id: number): Promise<UpApiResult<Classification>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async create(classification: CreateClassification): Promise<UpApiResult<Classification>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}`, classification);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async update(
    id: number,
    classification: CreateClassification,
  ): Promise<UpApiResult<Classification>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/${id}`,
        classification,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: number): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async activeOrDeactive(id: number): Promise<UpApiResult<Classification>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/activeOrDeactive/${id}`,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }
}
