import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../http/http-client";
import { CreateStatus } from "../models/create-status";
import { Status } from "../models/status";
import { StatusType } from "../enums/status-type";

const controller = "help-desk/statuses";

export default class StatusService {
  static myInstance: StatusService | null = null;

  static get instance(): StatusService {
    return (StatusService.myInstance ??= new StatusService());
  }

  async list(): Promise<UpApiResult<Status[]>> {
    const response = await HttpClient.instance.get(`/${controller}`);
    return validateHttpResponse(response);
  }

  async listActives(): Promise<UpApiResult<Status[]>> {
    const response = await HttpClient.instance.get(`/${controller}/actives`);
    return validateHttpResponse(response);
  }

  async find(id: number): Promise<UpApiResult<Status>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async create(task: CreateStatus): Promise<UpApiResult<Status>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}`, task);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async update(
    id: number,
    task: CreateStatus,
  ): Promise<UpApiResult<Status>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/${id}`,
        task,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: number): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async activeOrDeactive(id: number): Promise<UpApiResult<Status>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/activeOrDeactive/${id}`,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }


  async findByStatus(type: StatusType): Promise<UpApiResult<Status>> {
    try {
      const response = await HttpClient.instance.get(
        `/${controller}/status/${type}`,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

}
