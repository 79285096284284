import { DateFormatProvider } from "@done/react-essentials";
import { CssBaseline } from "@mui/material";
import { ptBR } from "@mui/material/locale";
import {
  createTheme,
  ThemeProvider
} from "@mui/material/styles";
import { ptBR as gridPtBR } from "@mui/x-data-grid/locales";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ptBR as datePtBr } from "@mui/x-date-pickers/locales";
import "dayjs/locale/pt-br";
import React, { ReactNode } from "react";
import "./app-theme.css";
import useThemeMode from "./hooks/use-theme-mode";

// Color definitions
export const themeColors = {
  dark: "#1f1914",
  light: "#f0e9e6",
  accent: "#A15609",
  accent2: "#764519",
  grey: '#e1e1e1',
  errorColor: '#f44336'
};

interface AppThemeProps {
  children: ReactNode;
}

const AppTheme = ({ children }: AppThemeProps) => {
  const themeMode = useThemeMode();
  const isLightMode = themeMode === "light";

  const typography = {
    fontFamily: "Noto Sans",
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  };

  const components = {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: ".4rem",
          color: isLightMode ? themeColors.dark : themeColors.light,
          backgroundColor: isLightMode ? themeColors.light : themeColors.dark,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: isLightMode ? themeColors.dark : themeColors.light,
          backgroundColor: isLightMode ? themeColors.light : themeColors.dark,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: isLightMode ? themeColors.dark : themeColors.light,
          backgroundColor: isLightMode ? themeColors.light : themeColors.dark,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          color: isLightMode ? themeColors.dark : themeColors.light,
          backgroundColor: isLightMode ? themeColors.light : themeColors.dark,
          borderRadius: "1.2rem",
          backgroundImage: "none",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: "1.2rem",
          borderColor: isLightMode ? themeColors.dark : "",
          "& .MuiDataGrid-cell": {
            borderColor: isLightMode ? themeColors.dark : "",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderColor: isLightMode ? themeColors.dark : "",
          },
          "& .MuiDataGrid-footerContainer": {
            borderColor: isLightMode ? themeColors.dark : "",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "1.2rem",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "1.2rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "1.2rem",
        },
      },
    },
  };

  // Themes
  const lightTheme = createTheme(
    {
      palette: {
        mode: "light",
        text: {
          primary: themeColors.accent2,
          secondary: themeColors.accent,
        },
        primary: {
          main: themeColors.accent2,
          contrastText: themeColors.light,
        },
        secondary: {
          main: themeColors.accent,
          light: themeColors.light,
          contrastText: themeColors.dark,
        },
        warning: {
          main: themeColors.accent,
          contrastText: themeColors.dark,
        },
        info: {
          main: themeColors.accent2,
          contrastText: themeColors.light,
        },
        background: {
          default: themeColors.grey,
        },
      },
      typography: typography,
      components: components,
    },
    ptBR,
    gridPtBR,
    datePtBr,
  );

  const darkTheme = createTheme(
    {
      palette: {
        mode: "dark",
        text: {
          primary: themeColors.light,
          secondary: themeColors.accent,
        },
        primary: {
          main: themeColors.light,
          contrastText: themeColors.dark,
        },
        secondary: {
          main: themeColors.accent,
          contrastText: themeColors.dark,
        },
        warning: {
          main: themeColors.accent,
          contrastText: themeColors.dark,
        },
        info: {
          main: themeColors.accent2,
          contrastText: themeColors.light,
        },
        background: {
          default: themeColors.dark,
        },
      },
      typography: typography,
      components: components,
    },
    ptBR,
    gridPtBR,
    datePtBr,
  );

  // Theme change
  const theme = isLightMode ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        dateFormats={DateFormatProvider}
        adapterLocale="pt-br"
      >
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default AppTheme;
