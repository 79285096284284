import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {
  UpApiResult,
  UpApiResultStatus,
} from "../../../models/up-api-result-model";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

interface UpConfirmButtonProps {
  apiResult: UpApiResult<any> | undefined;
  text?: string;
  loadingText?: string;
  loadingSize?: number;
  sx?: SxProps<Theme> | undefined;
  color?:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
  icon?: [IconPrefix, IconName] | undefined;
  iconPrefix?: IconPrefix | undefined;
  disabled?: boolean | undefined;
  form?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const UpConfirmButton = ({
  text,
  loadingText,
  apiResult,
  loadingSize,
  sx,
  color,
  icon,
  iconPrefix,
  disabled,
  form,
  onClick,
}: UpConfirmButtonProps) => {
  let showText: string = "";

  if (apiResult?.status === UpApiResultStatus.loading) {
    showText = loadingText ?? "Carregando...";
  } else {
    showText = text ?? "Confirmar";
  }

  return (
    <Button
      type="submit"
      variant="contained"
      color={color ?? "primary"}
      disabled={apiResult?.status === UpApiResultStatus.loading || disabled}
      form={form}
      sx={sx}
      onClick={onClick}>
      {apiResult?.status === UpApiResultStatus.loading && (
        <CircularProgress role="spinbutton" size={loadingSize ?? 16} />
      )}
      {apiResult?.status !== UpApiResultStatus.loading && (
        <FontAwesomeIcon
          title="check"
          icon={icon ?? [iconPrefix ?? "fas", "check"]}
        />
      )}
      <Typography component="span" variant="button" sx={{ ml: 1 }}>
        {showText}
      </Typography>
    </Button>
  );
};

export default UpConfirmButton;
