import "@fontsource/noto-sans"
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { AppProvider } from "./shared/contexts/app-context.tsx"
import './index.css'
import { BrowserRouter } from "react-router"

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <AppProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </AppProvider>
  </React.StrictMode>
)
