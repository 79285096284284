export enum UpApiResultStatus {
  none = 0,
  loading = 1,
  success = 2,
  error = 3,
}

export interface ProblemDetails {
  status: number;
  title: string;
  detail?: string;
}

// @ts-ignore
export class UpApiResult<T> {
  get status() {
    if (this._isLoading && !this._data && !this._errorMessage)
      return UpApiResultStatus.loading;

    if (!this._isLoading && this._isSuccess && this._data)
      return UpApiResultStatus.success;

    if (!this._isLoading && !this._isSuccess && !!this._errorMessage)
      return UpApiResultStatus.error;

    return UpApiResultStatus.none;
  }

  private _isLoading!: boolean;
  private _isSuccess!: boolean;

  private _data: any | null = null;
  get data() {
    return this._data;
  }

  private _errorMessage: string | null = null;
  get errorMessage() {
    return this._errorMessage;
  }

  static new() {
    return new UpApiResult();
  }

  static start<T>(): UpApiResult<T> {
    let apiResult = new UpApiResult();

    apiResult._isLoading = true;

    return apiResult;
  }

  static success<T>(data: T): UpApiResult<T> {
    let apiResult = new UpApiResult();

    apiResult._isSuccess = true;
    apiResult._data = data;

    return apiResult;
  }

  static error<T>(error: ProblemDetails | string | any): UpApiResult<T> {
    let apiResult = new UpApiResult();

    apiResult._isSuccess = false;

    let errorMessage: string = "Não foi possível se conectar ao servidor.";

    if (typeof error === typeof '')
      errorMessage = `${error}`;
    else
      errorMessage = `${error.title}${(!!error.detail ? ': ' + error.detail : '')}`

    apiResult._errorMessage = errorMessage;

    return apiResult;
  }
}
