import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { Attachment } from "../models/attachment";
import { CreateAttachment } from "../models/create-attachment";

const controller = "help-desk/attachments";

export default class AttachmentService {
  static myInstance = null;

  static get instance() {
    return AttachmentService.myInstance ?? new AttachmentService();
  }

  async list(): Promise<UpApiResult<Attachment[]>> {
    const response = await HttpClient.instance.get(`/${controller}`);
    return validateHttpResponse(response);
  }

  async listByTicketId(ticketId: number): Promise<UpApiResult<Attachment[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/list/ticket/${ticketId}`,
    );
    return validateHttpResponse(response);
  }

  async listByTicketIdByInteractionAll(ticketId: number): Promise<UpApiResult<Attachment[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/list/ticket-by-interaction-all/${ticketId}`,
    );
    return validateHttpResponse(response);
  }

  async find(id: number): Promise<UpApiResult<Attachment>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async create(attachment: CreateAttachment): Promise<UpApiResult<Attachment>> {
    try {
      const response = await HttpClient.instance.post(
        `/${controller}`,
        attachment,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async update(
    id: number,
    attachment: CreateAttachment,
  ): Promise<UpApiResult<Attachment>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/${id}`,
        attachment,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: number): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }
}
