import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../../../shared/logo";
import useThemeMode from "../../../../../themes/hooks/use-theme-mode";
import useThemeModeSelector from "../../../../../themes/hooks/use-theme-mode-selector";
import { environment } from "../../../../../shared/utils/environment";


const LoginPage = () => {
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>();

  const themeMode = useThemeMode();
  const themeModeSelector = useThemeModeSelector();

  const login = () => {
    const url = `${environment.VITE_APP_WORKSPACE_URL}/login?returnUrl=${environment.VITE_APP_HELPDESK_URL}`;
    window.location.href = url;
  };

  useEffect(() => {
    fetch(
      `${environment.VITE_API_GATEWAY_URL}/workspace/random-image/download`,
    ).then(async (resp) => {
      const result = await resp.json();
      setBackgroundImage(result.url);
    });
  });

  return (
    <Box
      sx={{
        width: 1,
        height: "100dvh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "cover",
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        position: "fixed",
        top: "0%",
        left: "0%",
        backgroundImage: !backgroundImage ? "none" : `url(${backgroundImage})`,
      }}
    >
      <Card>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
            px: 8,
          }}
        >
          <Logo />

          <Typography component="div" sx={{ mt: 3 }}>
            Que bom que você voltou 😍
          </Typography>

          <Button
            onClick={login}
            variant="contained"
            color="secondary"
            sx={{ m: 5, mb: 2 }}
          >
            <FontAwesomeIcon icon={["fal", "sign-in"]} />
            <Typography sx={{ ml: 1 }}>Acessar</Typography>
          </Button>

          <Button
            onClick={() =>
              themeModeSelector(themeMode === "light" ? "dark" : "light")
            }
            variant="text"
            size="small"
            color="primary"
            sx={{ mb: 2, px: 2, textTransform: "initial" }}
          >
            <FontAwesomeIcon
              icon={["fal", themeMode === "light" ? "moon" : "sun"]}
            />
            <Typography variant="body2" sx={{ ml: 1, fontSize: 12 }}>
              Ativar modo {themeMode === "light" ? "escuro" : "claro"}
            </Typography>
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginPage;
