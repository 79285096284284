import {
  LoginResult,
  UpApiResultStatus
} from "@done/react-essentials";
import Cookies from "js-cookie";
import { useAppContext } from "../../../../shared/contexts/app-context";
import { useSetCanAccess } from "../../../can-acces/hooks/use-can-access";
import ExternalUserService from "../../../external-user/services/external-user-service";
import { useSetRole } from "../../../user-role/hooks/use-role";
import UserService from "../services/user-service";

const useSetMe = () => {
  const { setUser, setExternalUser } = useAppContext();

  const setRole = useSetRole();

  const setCanAccess = useSetCanAccess();

  const getUserWorkSpace = async (session) => {
    const retrievedUser = await UserService.instance.me();

    if (retrievedUser.status === UpApiResultStatus.success) {
      const sessionObj: LoginResult | null = JSON.parse(session!);

      if (!sessionObj) return;

      // REMOVER
      // localStorage.setItem("session", JSON.stringify(sessionObj));

      setCanAccess();
      setRole();
      setUser(retrievedUser.data);
    }
  }

  const getUserExternalUser = async (sessionExternal) => {
    const retrievedUserExternal = await ExternalUserService.instance.me();

    if (retrievedUserExternal.status === UpApiResultStatus.success) {
      const sessionObj: LoginResult | null = JSON.parse(sessionExternal!);

      if (!sessionObj) return;

      localStorage.setItem("vup_help_desk", JSON.stringify(sessionObj));

      setCanAccess();
      setRole();
      setExternalUser(retrievedUserExternal.data);
    }
  }

  return async () => {
    const session = Cookies.get("session");
    const sessionExternal = localStorage.getItem("vup_help_desk");

    if (!session && !sessionExternal) {
      setUser(null);
    }

    if (!!session)
      await getUserWorkSpace(session);
    else if (!!sessionExternal) {
      await getUserExternalUser(sessionExternal);
    }
    else {
      setUser(null);
      setExternalUser(null);
    }
  };
};

export default useSetMe;
