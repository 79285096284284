import { agenteMenu, adminMenu, externalUserMenu } from "../menus-definitions";

const useMenuItems = () => {
  return (role: string) => {
    if (role === "Agente") {
      return agenteMenu;
    }
    else if (role === "Admin") {
      return adminMenu;
    }
    else {
      return externalUserMenu;
    }
  };
};

export default useMenuItems;
