import { UpApiResult, VupConsoleAccount, VupConsoleAccountExternal } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { ExternalUser } from "../../../external-user/models/external-user";

const controller = "console/accounts";

export class AccountService {
  private static _instance: AccountService | null = null;

  static get instance(): AccountService {
    return (AccountService._instance ??= new AccountService());
  }

  async listByIds(ids: number[]): Promise<UpApiResult<VupConsoleAccount>> {
    const response = await HttpClient.instance.get(
      `/${controller}/list-by-ids?accountsIds=${ids.join("&accountsIds=")}`,
    );

    return validateHttpResponse(response);
  }

  async find(id: number): Promise<UpApiResult<VupConsoleAccountExternal>> {
    const response = await HttpClient.instance.get(
      `/${controller}/${id}`,
    );

    return validateHttpResponse(response);
  }

  async findExternal(id: number): Promise<UpApiResult<ExternalUser>> {
    const response = await HttpClient.instance.get(
      `/${controller}/external/${id}`,
    );

    return validateHttpResponse(response);
  }
}
