import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../http/http-client";
import { WorkingHours } from "../models/working-hours";
import { CreateWorkingHours } from "../models/create-working-hours";

const controller = "help-desk/workingsHours";

export default class WorkingHoursService {
  static myInstance: WorkingHoursService | null = null;

  static get instance(): WorkingHoursService {
    return (WorkingHoursService.myInstance ??= new WorkingHoursService());
  }

  async list(): Promise<UpApiResult<WorkingHours[]>> {
    const response = await HttpClient.instance.get(`/${controller}`);
    return validateHttpResponse(response);
  }

  async find(): Promise<UpApiResult<WorkingHours>> {
    const response = await HttpClient.instance.get(`/${controller}/find`);
    return validateHttpResponse(response);
  }

  async create(classification: CreateWorkingHours): Promise<UpApiResult<WorkingHours>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}`, classification);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async update(
    id: number,
    classification: CreateWorkingHours,
  ): Promise<UpApiResult<WorkingHours>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/${id}`,
        classification,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: number): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async activeOrDeactive(id: number): Promise<UpApiResult<WorkingHours>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/activeOrDeactive/${id}`,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }
}
