import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { TenantGroup } from "../models/tenant-group";

const controller = "workspace/core/tenantsGroups";

export default class TenantGroupService {
  private static myInstance = null;

  static get instance() {
    return TenantGroupService.myInstance ?? new TenantGroupService();
  }

  async findByUserId(id: number): Promise<UpApiResult<TenantGroup>> {
    const response = await HttpClient.instance.get(`/${controller}/external/findByUserId/${id}`);
    return validateHttpResponse(response);
  }

  async listByUserId(): Promise<UpApiResult<TenantGroup[]>> {
    const response = await HttpClient.instance.get(`/${controller}/external`);
    return validateHttpResponse(response);
  }

  async listAll(): Promise<UpApiResult<TenantGroup[]>> {
    const response = await HttpClient.instance.get(`/${controller}/external-all`);
    return validateHttpResponse(response);
  }

  async listActives(): Promise<UpApiResult<TenantGroup[]>> {
    const response = await HttpClient.instance.get(`/${controller}/external-actives`);
    return validateHttpResponse(response);
  }
}
