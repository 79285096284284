import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Controller, useFormState } from "react-hook-form";
import { Status } from "../../status/models/status";
import StatusService from "../../status/services/status-service";
import { CreateTicket } from "../models/create-ticket";

interface StatusFieldProps {
  control: any;
  required?: boolean;
  disabled?: boolean;
}

const StatusField = ({
  control,
  required = true,
  disabled = false,
}: StatusFieldProps) => {
  const { errors } = useFormState<CreateTicket>({ control: control });

  const [status, setStatus] = useState<UpApiResult<Status[]>>(UpApiResult.start());

  const fetchData = async () => {
    setStatus(await StatusService.instance.listActives());
  };

  useEffect(() => {
    if (status.status === UpApiResultStatus.loading) fetchData();
  }, [status]);

  const fieldValidationRules = {};

  if (required) {
    fieldValidationRules["required"] =
      "O campo 'grupo econômico' é obrigatório.";
  }

  return (
    <Controller
      name="statusId"
      control={control}
      defaultValue={null}
      rules={fieldValidationRules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={status.data || []}
          getOptionLabel={(status: Status) => status.name}
          color="warning"
          size="small"
          fullWidth
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              color="warning"
              size="small"
              label="Status"
              placeholder={
                status.status === UpApiResultStatus.loading
                  ? "Carregando..."
                  : ""
              }
              helperText={
                errors.statusId?.message ??
                (required ? "*Obrigatório" : "")
              }
              error={!!errors.statusId}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                startAdornment: status.status ===
                  UpApiResultStatus.loading && (
                    <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                      <FontAwesomeIcon icon={["fal", "loader"]} spin />
                    </InputAdornment>
                  ),
              }}
              fullWidth
              required={required}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          value={
            status.data?.find(
              (status: Status) => status.id === value,
            ) ?? null
          }
          onChange={(_, value) => {
            return onChange(value?.id ?? null);
          }}
        />
      )}
    />
  );
};

export default memo(StatusField);
