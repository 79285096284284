import React from "react";
import AppTheme from "./themes/app-theme.tsx";
import AppRouter from "./app-router.tsx";

const App = () => {
  return (
    <AppTheme>
      <AppRouter />
    </AppTheme>
  );
};

export default App;
