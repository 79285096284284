import { UpApiResult } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { memo, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { PrioritysNames } from "../../../shared/enums/priority";
import TicketClassificationsField from "../../classification/components/ticket-classifications";
import BusinessUnitField from "../../integration/business-unit/components/business-unit-field";
import DepartmentField from "../../integration/department/components/department-field";
import OccupationAreaField from "../../integration/occupation-area/components/occupation-area-field";
import TenantGroupField from "../../integration/tenant-group/components/tenant-group-field";
import TenantField from "../../integration/tenant/components/tenant-field";
import withMe from "../../integration/user/hocs/withMe";
import { User } from "../../integration/user/models/user";
import StatusField from "../../ticket/components/status-field";
import { TicketTypesNames } from "../../ticket/enums/ticket-type";
import { FilterTicket } from "../../ticket/models/filter-ticket";
import TicketService from "../../ticket/services/ticket.service";
import externalUseMe from "../../integration/user/hooks/external-use-me";
import useMe from "../../integration/user/hooks/use-me";

type FilterDrawerProps = {
  opened: boolean;
  setOpened: any;
  onFilter;
  onButtonClick;
};

const FilterDrawer = ({
  opened,
  setOpened,
  onFilter,
  onButtonClick,
}: FilterDrawerProps) => {

  const [users, setUsers] = useState<UpApiResult<User>>(UpApiResult.start());

  const me = useMe();
  const isLoggedInExternal = externalUseMe();

  const methods = useForm<FilterTicket>({
    defaultValues: {}
  });

  const {
    getValues,
    register,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
    setFocus,
    handleSubmit,
  } = methods;

  const filter: SubmitHandler<FilterTicket> = async (filter: FilterTicket) => {
    setOpened(false);

    // Atribui os classifications tickets que foram selecionados pelos ids
    if (filter.optionsSelect && filter.departmentId !== null) {
      if (filter.optionsSelect[0] !== undefined && filter.departmentId !== null)
        filter.classifications = filter.optionsSelect.filter(x => x.selectedOption !== null && x.selectedOption !== undefined).flatMap(x => x.options.filter(a => a.id == x.selectedOption));
    }

    let result = await TicketService.instance.filter(filter, isLoggedInExternal ? true : false);

    onFilter(result);
  };

  const clearFilter = () => {
    reset({
      id: null,
      subject: null,
      startDate: null,
      endDate: null,
      tenantGroupId: null,
      tenantId: null,
      businessUnitId: null,
      occupationAreaId: null,
      departmentId: null,
      statusId: null,
      type: null,
      priority: null,
      classifications: undefined
    });
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <Drawer
          anchor="right"
          open={opened}
          onClose={() => setOpened(false)}
          ModalProps={{ keepMounted: true }}
        >
          <form
            id="filter-task-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(filter)}
            style={{ height: "100%" }}
          >
            {!!opened && (
              <Stack sx={{ width: 520, p: 2, height: 1 }}>
                <Typography variant="h6">Filtrar</Typography>

                <Stack spacing={2} sx={{ flex: 1, py: 1, overflowY: "auto" }}>
                  <TextField
                    {...register("id")}
                    color="warning"
                    size="small"
                    label="ID"
                    error={!!errors.subject}
                    slotProps={{
                      input: {
                        startAdornment: <Typography component="span">#&nbsp;</Typography>
                      },
                      inputLabel: {
                        shrink: true
                      }
                    }}
                    fullWidth
                  />

                  <TextField
                    {...register("subject")}
                    color="warning"
                    size="small"
                    label="Título"
                    error={!!errors.subject}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                  <Grid container direction="row">
                    <Grid item xs={6} style={{ paddingRight: '8px' }}>
                      <Controller
                        name="startDate"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            slotProps={{
                              textField: {
                                color: "warning",
                                size: "small",
                                label: "Período início",
                                InputLabelProps: { shrink: true },
                                fullWidth: true,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '8px' }}>
                      <Controller
                        name="endDate"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            slotProps={{
                              textField: {
                                color: "warning",
                                size: "small",
                                label: "Período fim",
                                InputLabelProps: { shrink: true },
                                fullWidth: true,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <TenantGroupField
                    control={control}
                    required={false}
                  />

                  <TenantField
                    control={control}
                    required={false}
                  />

                  <StatusField
                    control={control}
                    required={false} />

                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={TicketTypesNames}
                        getOptionLabel={(type) => type.label}
                        color="warning"
                        size="small"
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            size="small"
                            label="Tipo"
                            error={!!errors.type}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        )}
                        value={
                          TicketTypesNames.find((x) => x.value === value) ??
                          null
                        }
                        onChange={(_, value) => onChange(value?.value)}
                      />
                    )}
                  />

                  <Controller
                    name="priority"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={PrioritysNames}
                        getOptionLabel={(priority) => priority.label}
                        color="warning"
                        size="small"
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            size="small"
                            label="Prioridade"
                            error={!!errors.priority}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        )}
                        value={
                          PrioritysNames.find((x) => x.value === value) ??
                          null
                        }
                        onChange={(_, value) => onChange(value?.value)}
                      />
                    )}
                  />

                  <BusinessUnitField
                    control={control}
                    setValue={setValue}
                    required={false}
                  />

                  <OccupationAreaField
                    control={control}
                    setValue={setValue}
                    required={false}
                  />

                  <DepartmentField
                    listAll={false}
                    control={control}
                    required={false} />

                  <TicketClassificationsField
                    isFilter={true}
                    required={false}
                    control={control}
                    watch={watch}
                    ticketId={undefined}
                  />
                </Stack>

                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Button type="button" variant="outlined" onClick={clearFilter}>
                    <FontAwesomeIcon icon={["fal", "filter-circle-xmark"]} />
                    <Typography variant="body1" component="span" sx={{ ml: 1 }}>
                      Limpar
                    </Typography>
                  </Button>
                  <Button type="submit" onClick={onButtonClick} variant="contained">
                    <FontAwesomeIcon icon={["fal", "filter"]} />
                    <Typography variant="body1" component="span" sx={{ ml: 1 }}>
                      Filtrar
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
            )}
          </form>
        </Drawer>
      </FormProvider>
    </Box>
  );
};

export default memo(withMe(FilterDrawer));
