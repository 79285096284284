export enum TicketType {
    Error = 0,
    Improvement = 1,
    Request = 2,
    Question = 3,
}

export const TicketTypesNames = [
    { value: TicketType.Error, label: "Erro" },
    { value: TicketType.Improvement, label: "Melhoria" },
    { value: TicketType.Request, label: "Solicitação" },
    { value: TicketType.Question, label: "Pergunta" },
  ];
