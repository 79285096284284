import { UpApiResultStatus } from "@done/react-essentials/src/models/up-api-result-model";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router";
import DefaultLayout from "../../help-desk/src/layouts/default-layout";
import AgentListPage from "./features/agents/pages/list";
import { useCanAccess, useSetCanAccess } from "./features/can-acces/hooks/use-can-access";
import CanAccesPage from "./features/can-acces/pages";
import ClassificationFormPage from "./features/classification/pages/form";
import ClassificationListPage from "./features/classification/pages/list";
import ExternalUserFormPage from "./features/external-user/pages/form";
import ExternalUserListPage from "./features/external-user/pages/list";
import useSetMe from "./features/integration/user/hooks/use-set-me";
import LoginPage from "./features/integration/user/pages/login";
import LoginExternal from "./features/login-external/pages";
import NotFoundPage from "./features/not-found/pages";
import ServiceLevelAgreementFormPage from "./features/service-level-agreements/pages/form";
import ServiceLevelAgreementListPage from "./features/service-level-agreements/pages/list";
import StatusFormPage from "./features/status/pages/form";
import StatusListPage from "./features/status/pages/list";
import TicketInteractionPage from "./features/ticket-feature/ticket-interaction/components/form/ticket-interaction";
import TicketInteractionListPage from "./features/ticket-feature/ticket-interaction/components/list";
import TicketUpdatePage from "./features/ticket/components/ticket-update";
import TicketFormCustomerPage from "./features/ticket/pages/customer-form";
import TicketFormPage from "./features/ticket/pages/form";
import TicketListPage from "./features/ticket/pages/list";
import { useRole, useSetRole } from "./features/user-role/hooks/use-role";
import NotRolePage from "./features/user-role/pages";
import WorkingHoursFormPage from "./features/working-hours/pages/form";
import BlankLayout from "./layouts/blank-layout";
import ExternalUserLayout from "./layouts/external-user-layout";
import { useAppContext } from "./shared/contexts/app-context";
import LoadingPage from "./shared/loading/page";

const GuardedRoute = ({ roles }: { roles?: string[] }) => {
    const [authorized, setAuthorized] = useState<boolean>(false);
    const { canAccess, role } = useAppContext();

    const canActivate = (): void => {
        if (!roles) {
            setAuthorized(true);
            return;
        }

        if (!role || !canAccess.data) {
            setAuthorized(false);
            return;
        }

        setAuthorized(roles.includes(role));
    };

    useEffect(() => {
        canActivate();
    }, [role, canAccess]);

    if (!authorized)
        return (<CanAccesPage />);

    return (<Outlet />);
};

const AppRouter = () => {
    const role = useRole();
    const setRole = useSetRole();

    const canAccess = useCanAccess();
    const setCanAccess = useSetCanAccess();

    useEffect(() => {
        // encontrando a liberação no wks
        if (canAccess.data === null) setCanAccess();

        // encontrando a alçada no console
        if (role === null) setRole();
    }, []);

    return (
        <BrowserRouter>
            <AppRouterRoutes />
        </BrowserRouter>
    );
};

const AppRouterRoutes = () => {
    const canAccess = useCanAccess();
    const setMe = useSetMe();
    const { role, isLoggedIn: login, user, externalUser } = useAppContext();

    // necessário para pegar o usuário quando perde mesme estando logado
    useEffect(() => {
        const fetchUser = async () => {
            await setMe();
        };

        if (!user && !externalUser)
            fetchUser();

    }, [user, externalUser, login]);

    // não estando logado é necessário ter essas rotas para o login funcionar
    if (!user && !externalUser) {
        return (
            <Routes>
                <Route path="login" element={<BlankLayout />}>
                    <Route path="" element={<LoginPage />} />
                </Route>

                <Route path=":id/external-login" element={<BlankLayout />}>
                    <Route path="" element={<LoginExternal />} />
                </Route>
            </Routes>
        );
    }
    else {
        // estando logado exibe essas rotas com validações de liberação e alçadas
        return (
            <Routes>
                {!!user && (
                    <Route path="" element={<DefaultLayout />}>
                        <Route path="" element={<GuardedRoute roles={["Agente", "Admin"]} />}>
                            <Route path="" element={<TicketListPage />} />
                        </Route>

                        <Route path="tickets" element={<GuardedRoute roles={["Agente", "Admin"]} />}>
                            <Route path="" element={<TicketListPage />} />
                            <Route path="form" element={<TicketFormPage />} />
                            <Route path="form/:id" element={<TicketFormPage />} />
                            <Route path="update/:id" element={<TicketUpdatePage />} />
                            <Route path="track/:id" element={<TicketInteractionPage />} />
                        </Route>

                        <Route path="assigned-tickets" element={<GuardedRoute roles={["Agente", "Admin"]} />}>
                            <Route path="" element={<TicketInteractionListPage />} />
                            <Route path="track/:id" element={<TicketInteractionPage />} />
                        </Route>

                        <Route path="external-users" element={<GuardedRoute roles={["Agente", "Admin"]} />}>
                            <Route path="" element={<ExternalUserListPage />} />
                            <Route path="form" element={<ExternalUserFormPage />} />
                            <Route path="form/:id" element={<ExternalUserFormPage />} />
                        </Route>

                        <Route path="agents" element={<GuardedRoute roles={["Admin"]} />}>
                            <Route path="" element={<AgentListPage />} />
                        </Route>

                        <Route path="status" element={<GuardedRoute roles={["Admin"]} />}>
                            <Route path="" element={<StatusListPage />} />
                            <Route path="form" element={<StatusFormPage />} />
                            <Route path="form/:id" element={<StatusFormPage />} />
                        </Route>

                        <Route path="contracts" element={<GuardedRoute roles={["Admin"]} />}>
                            <Route path="" element={<ServiceLevelAgreementListPage />} />
                            <Route path="form" element={<ServiceLevelAgreementFormPage />} />
                            <Route path="form/:id" element={<ServiceLevelAgreementFormPage />} />
                        </Route>

                        <Route path="classifications" element={<GuardedRoute roles={["Admin"]} />}>
                            <Route path="" element={<ClassificationListPage />} />
                            <Route path="form" element={<ClassificationFormPage />} />
                            <Route path="form/:id" element={<ClassificationFormPage />} />
                        </Route>

                        <Route path="working-hours" element={<GuardedRoute roles={["Admin"]} />}>
                            <Route path="" element={<WorkingHoursFormPage />} />
                        </Route>
                    </Route>
                )}

                {!!externalUser && (
                    <>
                        <Route path="" element={<ExternalUserLayout />}>
                            <Route path="" element={<GuardedRoute roles={["Cliente"]} />}>
                                <Route path="" element={<TicketListPage />} />
                            </Route>

                            <Route path="tickets" element={<GuardedRoute roles={["Cliente"]} />}>
                                <Route path="" element={<TicketListPage />} />
                                <Route path="form" element={<TicketFormCustomerPage />} />
                                <Route path="form/:id" element={<TicketFormCustomerPage />} />
                                <Route path="update/:id" element={<TicketUpdatePage />} />
                                <Route path="track/:id" element={<TicketInteractionPage />} />
                            </Route>

                            <Route path="assigned-tickets" element={<GuardedRoute roles={["Cliente"]} />}>
                                <Route path="" element={<TicketInteractionListPage />} />
                                <Route path="track/:id" element={<TicketInteractionPage />} />
                            </Route>
                        </Route>

                        <Route path=":id/external-login" element={<BlankLayout />}>
                            <Route path="" element={<LoginExternal />} />
                        </Route>
                    </>
                )}

                <Route path="login" element={<BlankLayout />}>
                    <Route path="" element={<LoginPage />} />
                </Route>

                {/* Quando o usuário não tem permissão do software no wks e tem alçada no console.
               joga para o layout de sem permissão */}
                {(!canAccess.data && canAccess.status !== UpApiResultStatus.loading) && (
                    <Route path="" element={<BlankLayout />}>
                        <Route path="" element={<CanAccesPage />} />
                        <Route path="*" element={<CanAccesPage />} />
                    </Route>
                )}

                {/* Quando o usuário tem permissão do software no wks e não tem alçada no console.
               joga para o layout de sem alçada */}
                {(!role && canAccess.status !== UpApiResultStatus.loading) && (
                    <Route path="" element={<BlankLayout />}>
                        <Route path="" element={<NotRolePage />} />
                        <Route path="*" element={<NotRolePage />} />
                    </Route>
                )}

                {/* Loading para carregar ate encontrar a permissão do wks */}
                {!canAccess.data && canAccess.status === UpApiResultStatus.loading && (
                    <Route path="*" element={<BlankLayout />}>
                        <Route path="*" element={<LoadingPage />} />
                    </Route>
                )}

                {/* Quando o usuário tem permissão do software no wks e tem alçada no console.
               Caso ele digita uma rota invalida, aparece o 'página não encontrada'. */}
                <Route path="*" element={<BlankLayout />}>
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
        );
    }
};

export default AppRouter;