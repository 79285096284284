import {
  UpApiResult,
  UpApiResultStatus,
  UpConfirmButton,
  UpHeader,
} from "@done/react-essentials";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import { PrioritysNames } from "../../../../shared/enums/priority";
import { environment } from "../../../../shared/utils/environment";
import TicketClassificationsField from "../../../classification/components/ticket-classifications";
import { ExternalUser } from "../../../external-user/models/external-user";
import ExternalUserService from "../../../external-user/services/external-user-service";
import AttributionField from "../../../integration/attribution/components/attribution-field";
import BusinessUnitField from "../../../integration/business-unit/components/business-unit-field";
import DepartmentField from "../../../integration/department/components/department-field";
import OccupationAreaField from "../../../integration/occupation-area/components/occupation-area-field";
import TenantGroupField from "../../../integration/tenant-group/components/tenant-group-field";
import TenantField from "../../../integration/tenant/components/tenant-field";
import ReportUser from "../../../integration/user/components/report-user";
import useMe from "../../../integration/user/hooks/use-me";
import { User } from "../../../integration/user/models/user";
import UserService from "../../../integration/user/services/user-service";
import { StatusType } from "../../../status/enums/status-type";
import { Status } from "../../../status/models/status";
import StatusService from "../../../status/services/status-service";
import AttachmentForm from "../../../ticket-feature/attachment/components/form/index";
import AttachmentList from "../../../ticket-feature/attachment/components/list/index";
import { Attachment } from "../../../ticket-feature/attachment/models/attachment";
import { TicketTypesNames } from "../../enums/ticket-type";
import { CreateTicket } from "../../models/create-ticket";
import { Ticket } from "../../models/ticket";
import TicketService from "../../services/ticket.service";
import { useAppContext } from "../../../../shared/contexts/app-context";

const TicketFormPage = () => {
  const [findApiResult, setFindApiResult] = useState<UpApiResult<Ticket>>(
    UpApiResult.start(),
  );
  const [saveApiResult, setSaveApiResult] = useState<UpApiResult<Ticket>>(
    UpApiResult.new(),
  );

  const theme = useTheme();
  const userWks = useMe();
  const [status, setStatus] = useState<Status>();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const { id } = useParams();
  const navigate = useNavigate();
  const menuItem = useMenuItem("/tickets");
  const { enqueueSnackbar } = useSnackbar();
  const { attachments, setAttachments, attachmentsInteractions } =
    useAppContext();

  //#region Participantes WKS
  // participantes que foram salvos quando o ticket foi aberto.
  const [stakeholdersOld, setStakeholdersOld] = useState<User[]>([]);

  // ids dos participantes que foram salvos quando o ticket foi aberto.
  const [stakeholdersIds, setStakeholdersIds] = useState<string[]>([]);

  // participantes que foram adicionado quando o ticket foi criado, e os que são adicionados durante a interação com o ticket.
  const [stakeholders, setStakeholders] = useState<User[]>([
    ...stakeholdersOld,
  ]);

  // todos usuários do done auth
  const [usersAll, setUsersAll] = useState<User[]>([]);
  //#endregion

  //#region Participantes Externos
  // participantes que foram salvos quando o ticket foi aberto.
  const [externalStakeholdersOld, setExternalStakeholdersOld] = useState<
    ExternalUser[]
  >([]);

  // ids dos participantes que foram salvos quando o ticket foi aberto.
  const [externalStakeholdersIds, setExternalStakeholdersIds] = useState<
    string[]
  >([]);

  // participantes que foram adicionado quando o ticket foi criado, e os que são adicionados durante a interação com o ticket.
  const [externalStakeholders, setExternalStakeholders] = useState<
    ExternalUser[]
  >([...externalStakeholdersOld]);

  // todos usuários externos
  const [externalUsersAll, setExternalUsersAll] = useState<ExternalUser[]>([]);
  //#endregion

  const methods = useForm<CreateTicket>({
    defaultValues: async () => {
      const resultUsers = await UserService.instance.listActives();
      const resultExternalUsers =
        await ExternalUserService.instance.listActives();

      if (id) {
        try {
          const result = await TicketService.instance.find(parseInt(id!));

          setFindApiResult(result);
          setUsersAll(resultUsers.data || []);
          setExternalUsersAll(resultExternalUsers.data || []);

          if (result.status === UpApiResultStatus.success && result.data) {
            return result.data;
          } else navigate(-1);
        } catch (error) {
          navigate(-1);
        }
      } else {
        setUsersAll(resultUsers.data || []);
        setExternalUsersAll(resultExternalUsers.data || []);
        setStatus(
          (await StatusService.instance.findByStatus(StatusType.New)).data,
        );
        setFindApiResult(UpApiResult.success({}));
      }
    },
  });

  const {
    register,
    reset,
    control,
    setValue,
    formState: { errors },
    setFocus,
    handleSubmit,
  } = methods;

  const save: SubmitHandler<CreateTicket> = async (createTicket) => {
    if (createTicket.tags === undefined) createTicket.tags = [];

    if (createTicket.optionsSelect[0] === undefined) {
      enqueueSnackbar("O campo classificações de ticket é obrigatório.", {
        variant: "error",
      });
      return;
    } else if (
      createTicket.optionsSelect?.filter(
        (x) => x.selectedOption !== null && x.selectedOption !== undefined,
      ).length == 0
    ) {
      enqueueSnackbar("O campo classificações de ticket é obrigatório.", {
        variant: "error",
      });
      return;
    }

    setSaveApiResult(UpApiResult.start());

    // Atribui valores seprados ao ticket
    createTicket.attachments = attachments;
    createTicket.stakeholdersIds = stakeholdersIds;
    createTicket.externalStakeholdersIds = externalStakeholdersIds;
    createTicket.statusId = status?.id ?? 0;

    // Atribui os classifications tickets que foram selecionados pelos ids
    createTicket.classifications = createTicket.optionsSelect
      .filter(
        (x) => x.selectedOption !== null && x.selectedOption !== undefined,
      )
      .flatMap((x) => x.options.filter((a) => a.id == x.selectedOption));

    setSaveApiResult(await TicketService.instance.create(createTicket));

    const listTickets = UpApiResult<Ticket[]>;
    setAttachments([]);
  };

  useEffect(() => {
    if (saveApiResult.status === UpApiResultStatus.success) {
      enqueueSnackbar(`O Ticket foi salvo com sucesso.`, {
        variant: "success",
      });

      navigate("/tickets");
    } else if (saveApiResult.status === UpApiResultStatus.error) {
      enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
    }
  }, [saveApiResult, id, enqueueSnackbar, reset, setFocus, navigate]);

  const tenantGroupIdWatch = useWatch({ control, name: "tenantGroupId" });

  useEffect(() => {
    const fetchExternalUsers = async () => {
      const resultExternalUsers = !!tenantGroupIdWatch
        ? await ExternalUserService.instance.listByTenantGroupId(
            tenantGroupIdWatch,
          )
        : await ExternalUserService.instance.listActives();

      setExternalUsersAll(resultExternalUsers.data || []);
    };

    fetchExternalUsers();
  }, [tenantGroupIdWatch]);

  return (
    <Box>
      <FormProvider {...methods}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(save)}
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <UpHeader appTitle={appTitle} menuItem={menuItem} showBack>
            <UpConfirmButton
              apiResult={saveApiResult}
              sx={{ ml: 2 }}
              color="primary"
            />
          </UpHeader>

          {findApiResult.status === UpApiResultStatus.success && (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader title="Meus dados" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <ReportUser control={control}></ReportUser>
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <TenantGroupField control={control} />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <TenantField control={control} label="Empresa" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader title="Informações do ticket" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        {...register("subject", {
                          required: "O campo título é obrigatório",
                          minLength: {
                            value: 3,
                            message:
                              "O campo título deve haver ao menos 3 caracteres",
                          },
                        })}
                        color="warning"
                        size="small"
                        label="Título"
                        helperText={errors.subject?.message ?? "*Obrigatório"}
                        error={!!errors.subject}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        autoFocus
                        sx={{ flexGrow: 1 }}
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue={0}
                        rules={{ required: "O campo tipo é obrigatório." }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Autocomplete
                              options={TicketTypesNames}
                              getOptionLabel={(type) => type.label}
                              color="warning"
                              size="small"
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="warning"
                                  size="small"
                                  label="Tipo"
                                  helperText={
                                    errors.type?.message ?? "*Obrigatório"
                                  }
                                  error={!!errors.type}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              )}
                              value={
                                TicketTypesNames.find(
                                  (x) => x.value === value,
                                ) ?? null
                              }
                              onChange={(_, value) => onChange(value?.value)}
                            />
                          </>
                        )}
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                      <Controller
                        name="priority"
                        control={control}
                        defaultValue={0}
                        rules={{
                          required: "O campo prioridade é obrigatório.",
                        }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Autocomplete
                              options={PrioritysNames}
                              getOptionLabel={(priority) => priority.label}
                              color="warning"
                              size="small"
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="warning"
                                  size="small"
                                  label="Prioridade"
                                  helperText={
                                    errors.priority?.message ?? "*Obrigatório"
                                  }
                                  error={!!errors.priority}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              )}
                              value={
                                PrioritysNames.find((x) => x.value === value) ??
                                null
                              }
                              onChange={(_, value) => onChange(value?.value)}
                            />
                          </>
                        )}
                      />
                    </Grid>

                    <Grid size={{ xs: 12 }}>
                      <TextField
                        {...register("text", {
                          required: "O campo assunto é obrigatório.",
                          minLength: {
                            value: 5,
                            message:
                              "O campo assunto deve ao menos 5 caracteres.",
                          },
                        })}
                        color="warning"
                        size="small"
                        label="Descrição"
                        helperText={errors.text?.message ?? "*Obrigatório"}
                        error={!!errors.text}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        multiline
                        rows={4}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "16px",
                            "& fieldset": {
                              borderRadius: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid container size={{ xs: 12, md: 6 }}>
                      <Grid size={{ xs: 12 }}>
                        <AttributionField control={control}></AttributionField>
                      </Grid>

                      <Grid size={{ xs: 12 }}>
                        <Autocomplete
                          multiple
                          size="small"
                          value={stakeholders}
                          onChange={(event, newValue) => {
                            // Filtrar os novos usuários que não estão em stakeholdersOld
                            const newUsersSelect = newValue.filter(
                              (newUser) =>
                                !stakeholdersOld.some(
                                  (oldUser) => oldUser.email === newUser.email,
                                ),
                            );

                            // Mapear os emails dos novos usuários
                            const emailsStakeholdersNew = newUsersSelect.map(
                              (user) => user.email,
                            );

                            // Atualizar os estados
                            setStakeholdersIds(emailsStakeholdersNew);
                            setStakeholders([
                              ...stakeholdersOld,
                              ...newUsersSelect,
                            ]);
                          }}
                          options={usersAll}
                          getOptionLabel={(user) => user.name}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((user, index) => {
                              const { key, ...tagProps } = getTagProps({
                                index,
                              });
                              return (
                                <Typography key={key}>
                                  <Tooltip
                                    title={user.email}
                                    disableInteractive
                                  >
                                    <Typography sx={{ pointerEvents: "auto" }}>
                                      <Chip
                                        label={user.nameInitials}
                                        {...tagProps}
                                        variant="filled"
                                        disabled={
                                          stakeholdersOld.indexOf(user) !== -1
                                        }
                                        avatar={
                                          <Avatar
                                            alt={user.name}
                                            src={`${environment.VITE_API_GATEWAY_URL}/onstock/images/${user.image}`}
                                          />
                                        }
                                      />
                                    </Typography>
                                  </Tooltip>
                                </Typography>
                              );
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Participantes Internos"
                              placeholder="Adicione mais participantes internos"
                              helperText="Opcional"
                            />
                          )}
                        />
                      </Grid>

                      <Grid size={{ xs: 12 }}>
                        <Autocomplete
                          multiple
                          id="fixed-tags-demo"
                          size="small"
                          value={externalStakeholders}
                          onChange={(event, newValue) => {
                            // Filtrar os novos usuários que nÃ£o estÃ£o em stakeholdersOld
                            const newExternalUsersSelect = newValue.filter(
                              (newUser) =>
                                !externalStakeholdersOld.some(
                                  (oldUser) => oldUser.email === newUser.email,
                                ),
                            );

                            // Mapear os emails dos novos usuários
                            const emailsStakeholdersNew =
                              newExternalUsersSelect.map((user) => user.email);

                            // Atualizar os estados
                            setExternalStakeholdersIds(emailsStakeholdersNew);
                            setExternalStakeholders([
                              ...externalStakeholdersOld,
                              ...newExternalUsersSelect,
                            ]);
                          }}
                          options={externalUsersAll}
                          getOptionLabel={(user) => user.name}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((user, index) => {
                              const { key, ...tagProps } = getTagProps({
                                index,
                              });
                              return (
                                <Typography key={key}>
                                  <Tooltip
                                    title={user.email}
                                    disableInteractive
                                  >
                                    <Typography sx={{ pointerEvents: "auto" }}>
                                      <Chip
                                        label={user.nameInitials}
                                        {...tagProps}
                                        variant="filled"
                                        disabled={
                                          !!userWks &&
                                          stakeholdersOld.indexOf(userWks) > -1
                                        }
                                        avatar={
                                          <Avatar
                                            alt={user.name}
                                            src={`${environment.VITE_API_GATEWAY_URL}/onstock/images/${userWks?.image}`}
                                          />
                                        }
                                      />
                                    </Typography>
                                  </Tooltip>
                                </Typography>
                              );
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Participantes Externos"
                              placeholder="Adicione mais participantes externos"
                              helperText="Opcional"
                            />
                          )}
                        />
                      </Grid>

                      <Grid size={{ xs: 12 }}>
                        <Controller
                          name="tags"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={Array.of<string>()}
                              color="warning"
                              size="small"
                              multiple
                              freeSolo
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="warning"
                                  size="small"
                                  label="Tags"
                                  placeholder="Digite a tag e pressione 'enter' para adicioná-la"
                                  helperText={
                                    errors.tags?.message ?? "Opcional"
                                  }
                                  error={!!errors.tags}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              )}
                              value={value ?? []}
                              onChange={(_, value) => onChange(value)}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid container size={{ xs: 12, md: 6 }}>
                      <Grid size={{ xs: 12 }}>
                        <Typography
                          component="p"
                          variant="body2"
                          color="secondary"
                          sx={{ mb: 1 }}
                        >
                          Adicionar anexos
                        </Typography>
                        <AttachmentForm
                          isAttachmentInteracionCreate={false}
                          ticketId={parseInt(id!)}
                        />
                        <AttachmentList
                          isAttachmentInteracionCreate={false}
                          hideTrash={false}
                          saveApiResult={null}
                          ticketId={parseInt(id!)}
                          attachments={attachments}
                          attachmentsInteractions={attachmentsInteractions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader title="Classificação do ticket" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <BusinessUnitField
                        control={control}
                        setValue={setValue}
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <OccupationAreaField
                        control={control}
                        setValue={setValue}
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                      <DepartmentField listAll={false} control={control} />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <TicketClassificationsField
                        required={true}
                        control={control}
                        watch={null}
                        ticketId={undefined}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <UpConfirmButton
                  apiResult={saveApiResult}
                  color="primary"
                  onClick={handleSubmit(save)}
                />
              </Box>
            </>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

export default TicketFormPage;
