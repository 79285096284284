import {
    UpApiResult,
    UpApiResultStatus,
    UpConfirmButton,
    UpDataGrid,
    UpHeader
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, ListItemText, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import { ExternalUser } from "../../models/external-user";
import { HardResetPassword } from "../../models/hard-reset-password";
import ExternalUserService from "../../services/external-user-service";
import useSelectedAccount from "../../../integration/user/hooks/use-selected-account";
import { environment } from "../../../../shared/utils/environment";

const ExternalUserListPage = () => {
    const menuItem = useMenuItem("/external-users");
    const navigate = useNavigate();
    const theme = useTheme();
    const account = useSelectedAccount();

    const [users, setUsers] = useState<UpApiResult<ExternalUser[]>>(UpApiResult.start());

    const [dialogOpened, setDialogOpened] = useState<{
        id: string;
        text: string;
        isActive: boolean;
    } | null>(null);

    const [dialogResetPasswordOpened, setDialogResetPasswordOpened] = useState<{
        dialogResetPasswordOpened: boolean;
        userId: string | undefined;
    } | null>(null);

    const [changePassword, setChangePassword] = useState<HardResetPassword>({
        userId: "",
        password: "",
        passwordConfirm: ""
    });

    const [currentPasswordVisible, setCurrentPasswordVisible] = useState<boolean>(false);

    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

    const [saveApiResult, setSaveApiResult] = useState<UpApiResult<ExternalUser>>(
        UpApiResult.new(),
    );

    const columns: GridColDef[] = [
        {
            field: "active",
            headerName: "Ativo",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            maxWidth: 60,
            align: "center",
            headerAlign: "center",
            renderCell: (cell) => (
                <Tooltip title={cell.value ? "Ativo" : "Inativo"}>
                    <FontAwesomeIcon
                        icon={["fal", cell.value ? "check-circle" : "ban"]}
                        style={{
                            color: cell.value
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                        }}
                    />
                </Tooltip>
            ),
        },
        { field: "name", headerName: "Nome", minWidth: 300 },
        { field: "email", headerName: "E-mail", minWidth: 300 },
        {
            field: "tenantGroup", headerName: "Grupo Econômico", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: "actions",
            headerName: "Ações",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            minWidth: 120,
            renderCell: (params) => (
                <>
                    <Tooltip title="Alterar senha" sx={{ mr: 1 }}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleEditPassword(true, params.row?.id)}
                        >
                            <FontAwesomeIcon icon={["fal", "key"]} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar" sx={{ mr: 1 }}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => navigate(`form/${params.id}`)}
                        >
                            <FontAwesomeIcon icon={["fal", "edit"]} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={params.row.active
                        ? "Ativado" : "Desativado"}>
                        <IconButton
                            size="small"
                            color={params.row.active
                                ? "success" : "error"}
                            onClick={() => openDialog(params.id)}
                        >
                            <FontAwesomeIcon
                                icon={[
                                    "fal",
                                    params.row.active
                                        ? "check-circle" : "circle-xmark",
                                ]}
                            />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    const {
        reset,
        register,
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setError,
    } = useForm<HardResetPassword>({
        defaultValues: changePassword,
    });

    const password = watch("password");

    const handleEditPassword = (confirm: boolean, userId: string) => {
        if (confirm === true) openDialogResetPassword(userId);
    };

    const openDialogResetPassword = (userId: string) => {
        setDialogResetPasswordOpened({
            dialogResetPasswordOpened: true,
            userId: userId ?? null
        });
    };

    const closeDialogResetPassword = async () => {
        setSaveApiResult(UpApiResult.success({}));

        setChangePassword({
            userId: "",
            password: "",
            passwordConfirm: ""
        });

        setDialogResetPasswordOpened({
            dialogResetPasswordOpened: false,
            userId: undefined
        });

        reset();
    };

    const save: SubmitHandler<HardResetPassword> = async (resetPassword) => {
        if (resetPassword.password !== resetPassword.passwordConfirm) {
            setError("passwordConfirm", { message: "As senhas não coincidem." });
            return;
        }
        setSaveApiResult(UpApiResult.start());

        resetPassword.userId = dialogResetPasswordOpened!.userId!;

        try {
            let result = await ExternalUserService.instance.hardResetPasswordAsync(resetPassword);
            setSaveApiResult(UpApiResult.success(result));
            enqueueSnackbar("Senha alterada com sucesso", { variant: "success" });
            closeDialogResetPassword();
        } catch (error: any) {
            enqueueSnackbar(error, { variant: "error" });
            setSaveApiResult(UpApiResult.error(error));
        }
    };

    const openDialog = (id) => {
        const selectedUser = users.data.find(
            (user) => user.id === id,
        );
        setDialogOpened({
            id: id,
            text: selectedUser?.name ?? "-",
            isActive: selectedUser?.active,
        });
    };

    const closeDialog = async (confirm: boolean | undefined) => {
        if (confirm === true) {
            const result = await ExternalUserService.instance.activeOrDeactive(dialogOpened!.id);
            setUsers(UpApiResult.start());
        }

        setDialogOpened(null);
    };

    const fetchData = async () => {
        setUsers(await ExternalUserService.instance.list());
    };

    useEffect(() => {
        if (users.status === UpApiResultStatus.loading) fetchData();
    }, [users]);

    const externalLoginUrl = `${environment.VITE_APP_HELPDESK_URL}/${account?.id}/external-login`;

    const copyExternalLoginUrl = async () => {
        await navigator.clipboard.writeText(externalLoginUrl);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <UpHeader
                appTitle={appTitle}
                menuItem={menuItem}
                createRoute="/external-users/form"
                caption={
                    <Box sx={{ mt: 1, mb: 2 }}>
                        <Typography>
                            Para os clientes efetuarem o login, eles deverão acessar o sistema através do link:

                            <Tooltip title="Clique para copiar o link">
                                <Button sx={{ textDecoration: "underline", ml: 1, px: 1, textTransform: "lowercase" }} onClick={copyExternalLoginUrl}>
                                    {externalLoginUrl}
                                </Button>
                            </Tooltip>
                        </Typography>
                    </Box>
                } />

            <UpDataGrid
                apiResult={users}
                columns={columns}
                height={window.innerHeight - 180}
                sx={{ width: '100%' }}
            />

            <Dialog
                open={!!dialogOpened}
                onClose={() => closeDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogOpened?.isActive ? "Desativar" : "Ativar"} Usuário
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Confirmar a {dialogOpened?.isActive ? "desativação" : "ativação"} do
                        status <strong>{dialogOpened?.text}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog(false)} autoFocus sx={{ mr: 1 }}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => closeDialog(true)}
                        color={dialogOpened?.isActive ? "error" : "success"}
                    >
                        {dialogOpened?.isActive ? "Desativar" : "Ativar"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={!!dialogResetPasswordOpened?.dialogResetPasswordOpened}
                onClose={() => closeDialogResetPassword()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form noValidate autoComplete="off" onSubmit={handleSubmit(save)}>
                    <DialogTitle id="alert-dialog-title">Alterar Senha</DialogTitle>
                    <DialogContent>
                        <Card >
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <ListItemText
                                            sx={{ mb: 2 }}
                                            primary={
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Nova senha
                                                </Typography>
                                            }
                                            secondary="Informe a nova senha para o usuário"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    color="warning"
                                                    size="small"
                                                    required
                                                    fullWidth
                                                    label="Senha"
                                                    type={currentPasswordVisible ? "text" : "password"}
                                                    autoComplete="current"
                                                    {...register("password", {
                                                        required: "Campo obrigatório.",
                                                        pattern: {
                                                            value:
                                                                /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
                                                            message:
                                                                "A senha deve conter ao menos um número, uma letra maiúscula, uma minúscula e um caractere especial.",
                                                        },
                                                    })}
                                                    helperText={errors.password?.message ?? ""}
                                                    error={!!errors.password}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton
                                                                tabIndex={-1}
                                                                onClick={() =>
                                                                    setCurrentPasswordVisible(
                                                                        !currentPasswordVisible,
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    size="sm"
                                                                    icon={[
                                                                        "fal",
                                                                        !currentPasswordVisible
                                                                            ? "eye"
                                                                            : "eye-slash",
                                                                    ]}
                                                                />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    sx={{ mt: 3 }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    color="warning"
                                                    size="small"
                                                    required
                                                    fullWidth
                                                    label="Confirmar senha"
                                                    type={confirmPasswordVisible ? "text" : "password"}
                                                    autoComplete="current"
                                                    {...register("passwordConfirm", {
                                                        required: "Campo obrigatório.",
                                                    })}
                                                    helperText={errors.passwordConfirm?.message ?? ""}
                                                    error={!!errors.passwordConfirm}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton
                                                                tabIndex={-1}
                                                                onClick={() =>
                                                                    setConfirmPasswordVisible(
                                                                        !confirmPasswordVisible,
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    size="sm"
                                                                    icon={[
                                                                        "fal",
                                                                        !confirmPasswordVisible
                                                                            ? "eye"
                                                                            : "eye-slash",
                                                                    ]}
                                                                />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    sx={{ mt: 3 }}
                                                />
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeDialogResetPassword()} autoFocus sx={{ mr: 1 }}>
                            Cancelar
                        </Button>
                        <UpConfirmButton
                            apiResult={saveApiResult}
                            iconPrefix="fal"
                            sx={{ ml: 2 }}
                            text="Confirmar"
                            icon={["fal", "check"]}
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
};

export default ExternalUserListPage;
