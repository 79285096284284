import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";
import withMe from "../../../../integration/user/hocs/withMe";
import { User } from "../../../../integration/user/models/user";
import UploadFile from "../../../../upload/components/upload-file";
import UploadService from "../../../../upload/services/upload.service";
import { CreateAttachment } from "../../models/create-attachment";
import { Attachment } from "../../models/attachment";
import { useAppContext } from "../../../../../shared/contexts/app-context";

type AttachmentFormProps = {
  isAttachmentInteracionCreate: boolean;
  ticketId?: number;
  me: User | null;
  onValuesChange;
};

const AttachmentForm = forwardRef(
  (
    {
      isAttachmentInteracionCreate,
      ticketId,
      me,
      onValuesChange,
    }: AttachmentFormProps,
    ref,
  ) => {
    const [createAttachment, setCreateAttachment] = useState<
      UpApiResult<Attachment>
    >(UpApiResult.new());

    const {
      attachments,
      setAttachments,
      attachmentsInteractions,
      setAttachmentsInteractions,
    } = useAppContext();

    const [fileLoading, setFileLoading] = useState<boolean>(false);

    const methods = useForm<CreateAttachment>({
      defaultValues: {
        ticketId: ticketId,
        name: "",
        url: "",
        files: undefined,
      },
    });

    const { reset, setFocus, handleSubmit, getValues, watch, control } =
      methods;

    // Expondo getValues para o pai
    useImperativeHandle(ref, () => ({
      getValues,
    }));

    // Use watch para monitorar mudanças nos valores
    useEffect(() => {
      const subscription = watch((value) => {
        if (onValuesChange) {
          onValuesChange(value); // Chama o callback sempre que os valores mudam
        }
      });

      return () => subscription.unsubscribe(); // Limpeza da inscrição
    }, [watch, onValuesChange]);

    const watchFile = useWatch({ control, name: "files" });

    useEffect(() => {
      if (createAttachment.status === UpApiResultStatus.success) {
        setFocus("name");
        reset();
      }
    }, [watchFile]);

    // Método de criar os anexos sem serem salvos
    const save: SubmitHandler<CreateAttachment> = async (
      createAttachment: CreateAttachment,
    ) => {
      setFileLoading(true);

      if (!createAttachment.files?.length) {
        const message = "Selecione um arquivo para anexar.";
        enqueueSnackbar(message, { variant: "error" });
        setCreateAttachment(UpApiResult.error(message));
        methods.setValue("name", "");
        methods.setValue("files", undefined);
        setFileLoading(false);
        return;
      }

      try {
        const fileId = await UploadService.instance.uploadFile(
          createAttachment.files[0],
        );

        if (fileId.data === null) {
          const message = "Não foi possível realizar o upload do arquivo.";
          enqueueSnackbar(message, { variant: "error" });
          setCreateAttachment(UpApiResult.error(message));
          methods.setValue("name", "");
          methods.setValue("files", undefined);
          setFileLoading(false);
          return;
        }

        createAttachment.url = fileId.data;
      } catch (error) {
        setFileLoading(false);
      }

      setFileLoading(false);

      enqueueSnackbar(`Upload realizado com sucesso!`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setFocus("name");
      methods.setValue("name", "");
      methods.setValue("files", undefined);

      if (isAttachmentInteracionCreate)
        setAttachmentsInteractions([
          ...attachmentsInteractions,
          JSON.parse(JSON.stringify(createAttachment)),
        ]);
      else {
        setAttachments([
          ...attachments,
          JSON.parse(JSON.stringify(createAttachment)),
        ]);
      }
    };

    return (
      <FormProvider {...methods}>
        <UploadFile required />

        {getValues("files") && getValues("files")!.length > 0 && (
          <Stack direction="row" gap={2} sx={{ margin: "16px 0" }}>
            <AttachmentNameField />

            <Box>
              <Button
                color="secondary"
                disabled={fileLoading}
                onClick={handleSubmit(save)}
              >
                <FontAwesomeIcon title="check" icon={["fal", "file-alt"]} />
                <Typography component="span" variant="button" sx={{ ml: 1 }}>
                  Anexar
                </Typography>
              </Button>
            </Box>
          </Stack>
        )}
      </FormProvider>
    );
  },
);

const AttachmentNameField = () => {
  const { register, setValue, control } = useFormContext<CreateAttachment>();
  const { errors } = useFormState<CreateAttachment>();

  const watchFile = useWatch({ control, name: "files" });

  useEffect(() => {
    setValue("name", watchFile?.length ? watchFile[0]?.name : "");
  }, [watchFile]);

  return (
    <TextField
      {...register("name", {
        required: "O campo 'Nome do Anexo' deve ser preenchido.",
        minLength: {
          value: 3,
          message: "O campo 'Nome do Anexo' deve conter ao menos 3 caracteres.",
        },
      })}
      color="warning"
      size="small"
      label="Nome do Anexo"
      helperText={errors.name?.message ?? ""}
      error={!!errors.name}
      InputLabelProps={{ shrink: true }}
      fullWidth
    />
  );
};

export default withMe(AttachmentForm);
