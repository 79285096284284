import { VupConsoleAccount } from "@done/react-essentials";
import Cookies from "js-cookie";

const useGetAccount = (): VupConsoleAccount => {
    const sessionStr = Cookies.get("session");
    const session = JSON.parse(sessionStr ?? "{}");
    const sessionExternal = JSON.parse(localStorage.getItem("vup_help_desk") ?? "{}");

    return session.account ?? sessionExternal.account;
};

export default useGetAccount;