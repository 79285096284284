import {
    UpApiResult,
    UpApiResultStatus,
    UpConfirmButton,
    UpDividedCard,
    UpHeader,
} from "@done/react-essentials";
import { Box, FormControlLabel, FormGroup, Grid, Switch, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import ClassificationField from "../../../classification/components/classification-field";
import BusinessUnitField from "../../../integration/business-unit/components/business-unit-field";
import DepartmentField from "../../../integration/department/components/department-field";
import OccupationAreaField from "../../../integration/occupation-area/components/occupation-area-field";
import { Classification } from "../../models/classification";
import { CreateClassification } from "../../models/create-classification";
import ClassificationService from "../../services/classification-service";

const ClassificationFormPage = () => {
    const { id } = useParams();

    const [findApiResult, setFindApiResult] = useState<UpApiResult<Classification>>(UpApiResult.start());
    const [saveApiResult, setSaveApiResult] = useState<UpApiResult<Classification>>(UpApiResult.new());

    const menuItem = useMenuItem("/classifications");
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm<CreateClassification>({
        defaultValues: async () => {

            if (id) {
                try {
                    const result = await ClassificationService.instance.find(parseInt(id!));

                    setFindApiResult(result);

                    if (result.status === UpApiResultStatus.success && result.data)
                        return result.data;
                    else navigate(-1);
                } catch (error) {
                    navigate(-1);
                }
            }
            else {
                setFindApiResult(UpApiResult.success({}));
            }
        },
    });

    const {
        register,
        reset,
        control,
        setFocus,
        watch,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm<CreateClassification>({
        defaultValues: async () => {
            if (id) {
                try {
                    const result = await ClassificationService.instance.find(parseInt(id!));

                    setFindApiResult(result);

                    if (result.status === UpApiResultStatus.success && result.data)
                        return result.data;
                    else navigate(-1);
                } catch (error) {
                    navigate(-1);
                }
            }
        },
    });

    const save: SubmitHandler<CreateClassification> = async (classification) => {
        setSaveApiResult(UpApiResult.start());

        if (id)
            setSaveApiResult(await ClassificationService.instance.update(parseInt(id), classification));
        else
            setSaveApiResult(await ClassificationService.instance.create(classification));
    };

    useEffect(() => {
        if (saveApiResult.status === UpApiResultStatus.success) {
            enqueueSnackbar(
                `A classificação [${saveApiResult.data?.name}] foi salvo com sucesso.`,
                {
                    variant: "success",
                },
            );

            navigate("/classifications");
        } else if (saveApiResult.status === UpApiResultStatus.error) {
            // setFocus("timeToFirstResponse");
            enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
        }
    }, [saveApiResult, id, enqueueSnackbar, reset, setFocus, navigate]);

    useEffect(() => { }, [findApiResult]);

    return (
        <Box>
            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(save)}
                    style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                >
                    <UpHeader appTitle={appTitle} menuItem={menuItem} showBack>
                        <UpConfirmButton
                            apiResult={saveApiResult}
                            sx={{ ml: 2 }}
                            color="primary"
                        />
                    </UpHeader>

                    {/* Dados */}
                    <UpDividedCard
                        title="Dados Cadastrais"
                        description="Informa os dados básicos da classificação"
                    >
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                {...register("name", {
                                    required: "O campo nome é obrigatório.",
                                    minLength: {
                                        value: 1,
                                        message: "O campo nome deve ter ao menos 1 caracteres.",
                                    },
                                })}
                                color="warning"
                                size="small"
                                label="Nome"
                                helperText={errors.name?.message ?? "*Obrigatório"}
                                error={!!errors.name}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required
                                autoFocus
                                type="text"
                                sx={{ flexGrow: 1 }}
                            />
                        </Box>

                        <Grid container sx={{ mt: 0 }} spacing={2}>
                            <Grid item xs={6}>
                                <BusinessUnitField
                                    control={control}
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={6}>

                                <OccupationAreaField
                                    control={control}
                                    setValue={setValue}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={6}>
                                <DepartmentField
                                    listAll={false}
                                    required={true}
                                    control={control} />
                            </Grid>
                            <Grid item xs={6}>
                                <ClassificationField
                                    required={false}
                                    control={control}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={2}>
                                <FormGroup>
                                    <Controller
                                        name="active"
                                        control={control}
                                        defaultValue={true}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        sx={{ mt: 1 }}
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                        color="secondary"
                                                    />
                                                }
                                                label="Ativo"
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </UpDividedCard>
                </form>
            </FormProvider>
        </Box>
    );
};

export default ClassificationFormPage;
