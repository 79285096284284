import {
    UpApiResult,
    UpApiResultStatus,
    UpDataGrid,
    UpHeader
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, CircularProgress, Grid, IconButton, Stack, Tab, Tooltip, Typography, colors, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../../../../../src/shared/utils/up-date-time-converter";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import useMe from "../../../integration/user/hooks/use-me";
import { StatusType } from "../../../status/enums/status-type";
import FilterDrawer from "../../../ticket-feature/filter/index";
import { TicketType } from "../../enums/ticket-type";
import { Ticket } from "../../models/ticket";
import TicketService from "../../services/ticket.service";
import externalUseMe from "../../../integration/user/hooks/external-use-me";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { enqueueSnackbar } from "notistack";
import EmployeeService from "../../../integration/employee/services/employee-service";

const TicketListPage = () => {
    const me = useMe();
    const theme = useTheme();
    const navigate = useNavigate();
    const menuItem = useMenuItem("/tickets");
    const isLoggedInExternal = externalUseMe();

    // todos
    const [ticketsAll, setTicketsAll] = useState<UpApiResult<Ticket[]>>(UpApiResult.start());

    // tickets que eu participo
    const [ticketsParticipate, setTicketsParticipate] = useState<UpApiResult<Ticket[]>>(UpApiResult.start());

    // tickets atribuídos a mim
    const [assignedTickets, setAssignedTickets] = useState<UpApiResult<Ticket[]>>(UpApiResult.start());

    // tickets criados por mim
    const [tickets, setTickets] = useState<UpApiResult<Ticket[]>>(UpApiResult.start());

    const columns: GridColDef[] = [
        {
            field: "actions",
            headerName: "Ações",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <Tooltip title="Responder" sx={{ mr: 1 }}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {

                                if (location.pathname.includes('tickets'))
                                    navigate(`track/${params.id}`)
                                else {
                                    navigate(`tickets/track/${params.id}`)
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={["fal", "message"]} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: "id", headerName: "ID", minWidth: 30,
            renderCell: (params) => (
                <Typography>#{params.value}</Typography>
            ),
        },
        {
            field: "subject", headerName: "Título", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: "status", headerName: "Status", minWidth: 150,
            renderCell: (params) => {
                if (params.value?.type === StatusType.New) {
                    return (
                        <>
                            <Tooltip title={params.value?.name} >
                                <Box sx={{ mr: 1 }}>
                                    <FontAwesomeIcon color="red" icon={["fal", "exclamation-circle"]} size="xl" />
                                </Box>
                            </Tooltip>
                            <Typography>{params.value?.name}</Typography>
                        </>
                    );
                }
                else if (params.value?.type === StatusType.Open) {
                    return (
                        <>
                            <Tooltip title={params.value?.name} >
                                <Box sx={{ mr: 1 }}>
                                    <span className="fa-layers fa-xl">
                                        <FontAwesomeIcon color="dodgerblue" icon={["fal", "circle"]} />
                                        <FontAwesomeIcon color="dodgerblue" icon={["fal", "headset"]} transform="shrink-8" />
                                    </span>
                                </Box>
                            </Tooltip>
                            <Typography>{params.value?.name}</Typography>
                        </>
                    );
                }
                else if (params.value?.type === StatusType.Waiting) {
                    return (
                        <>
                            <Tooltip title={params.value?.name} >
                                <Box sx={{ mr: 1 }}>
                                    <FontAwesomeIcon color="goldenrod" icon={["fal", "clock"]} size="xl" />
                                </Box>
                            </Tooltip>
                            <Typography>{params.value?.name}</Typography>
                        </>
                    );
                }
                else {
                    return (
                        <>
                            <Tooltip title={params.value?.name} >
                                <Box sx={{ mr: 1 }}>
                                    <FontAwesomeIcon color="darkgreen" icon={["fal", "circle-check"]} size="xl" />
                                </Box>
                            </Tooltip>
                            <Typography>{params.value?.name}</Typography>
                        </>
                    );
                }
            },
        },
        {
            field: "type", headerName: "Tipo", minWidth: 120,
            renderCell: (params) => {
                const ticket: Ticket = tickets?.data?.find(
                    (x) => x.id === params?.row?.id,
                ) || ticketsParticipate?.data?.find(
                    (x) => x.id === params?.row?.id,
                ) || ticketsAll?.data?.find(
                    (x) => x.id === params?.row?.id,
                ) || assignedTickets?.data?.find(
                    (x) => x.id === params?.row?.id,
                );

                const getTicketColor = (): string => {
                    if (ticket?.type === TicketType.Error)
                        return colors.red.A700;

                    if (ticket?.type === TicketType.Improvement)
                        return colors.blue.A700;

                    if (ticket?.type === TicketType.Question)
                        return colors.yellow.A700;

                    if (ticket?.type === TicketType.Request)
                        return colors.orange.A700;

                    return '';
                };

                return (
                    <Typography
                        variant="overline"
                        fontWeight={"bold"}
                        sx={{
                            backgroundColor: getTicketColor(),
                            px: 1,
                            lineHeight: 2,
                            borderRadius: 8,
                            color: "white"
                        }}
                    >
                        {statusOptions.find((x) => params.value === x.id)?.description}
                    </Typography>
                );
            },
        },
        {
            field: "creationDate", headerName: "Data de Abertura", minWidth: 220,
            renderCell: (params) => (
                <Typography>{params.value.toUpLocalDateTime(true)}</Typography>
            ),
        },
        {
            field: "completionDate", headerName: "Data de Conclusão", minWidth: 220,
            renderCell: (params) => (
                <Typography>{params.value?.toUpLocalDateTime(true) ?? '-'}</Typography>
            ),
        },
        {
            field: "tenantGroup", headerName: "Grupo Econômico", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: "tenant", headerName: "Cliente", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: "department", headerName: "Departamento", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
    ];

    const [loading, setLoading] = useState(false);

    const [filterOpened, setFilterOpened] = useState<boolean>(false);

    const statusOptions = [
        { id: 0, description: "Erro" },
        { id: 1, description: "Melhoria" },
        { id: 2, description: "Solicitação" },
        { id: 3, description: "Pergunta" },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const employees = !!me ? await EmployeeService.instance.find(me.email) : null;
                const departmentId = !!employees ? employees.data[0]?.departmentId : 0

                // Usando Promise.all para realizar as requisições simultaneamente
                const [allTickets, myCreatedTickets, ticketsParticipate, assignedTickets] = await Promise.all([
                    !!me ? TicketService.instance.listByDepartment(departmentId) : TicketService.instance.listByTenantGroup(isLoggedInExternal!.tenantGroupId),
                    !!me ? TicketService.instance.listMyCreatedTickets(me.id) : TicketService.instance.listMyCreatedTickets(isLoggedInExternal!.id.toString()),
                    !!me ? TicketService.instance.listTicketsParticipate(me.userName) : TicketService.instance.listTicketsParticipateExternal(),
                    !!me ? TicketService.instance.listTicketsAssigned(me.userName) : Promise.resolve(UpApiResult.success([])) // Se não estiver logado, retorna uma lista vazia
                ]);

                // Atualizando os estados com os dados retornados
                setTickets(UpApiResult.success(myCreatedTickets.data || []));
                setTicketsParticipate(UpApiResult.success(ticketsParticipate.data || []));
                setAssignedTickets(UpApiResult.success(assignedTickets.data || []));
                setTicketsAll(UpApiResult.success(allTickets.data || []));
            } catch (err) {
                setLoading(false);
                enqueueSnackbar(err, { variant: "error" });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [me, isLoggedInExternal]);

    const goToCreate = () => {
        navigate("/tickets/form");
    };

    const fetchFilteredTickets = (filter) => {
        setTickets(UpApiResult.success(filter.data.myTicketsCreated));
        setTicketsParticipate(UpApiResult.success(filter.data.ticketsParticipate));
        setAssignedTickets(UpApiResult.success(filter.data.assignedTickets));
        setTicketsAll(UpApiResult.success(filter.data.ticketsAll));

        setLoading(false);
    };

    const handleButtonClick = () => {
        // Aqui é quando o botão do filho é clicado
        setLoading(true);
    };

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <UpHeader
                appTitle={appTitle}
                menuItem={menuItem}
                sx={{ marginBottom: '0px' }}
            >

                <Stack
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                    sx={{ width: 1 }}
                >
                    <Grid container sx={{ justifyContent: 'flex-end' }} columnSpacing={1}>
                        <Grid item >
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setFilterOpened(true)}
                                // disabled={!workflowId}
                                fullWidth
                            >
                                <FontAwesomeIcon icon={["fal", "filter"]} />
                                <Typography variant="body1" sx={{ ml: 1 }}>
                                    Filtrar
                                </Typography>
                            </Button>
                        </Grid>

                        <Grid item >
                            <Button variant="outlined" color="primary" sx={{ justifyContent: 'flex-end' }} onClick={goToCreate}>
                                <FontAwesomeIcon
                                    title="Adicionar"
                                    icon="plus"
                                />
                                <Typography component="span" variant="button" sx={{ ml: 1 }}>
                                    Adicionar
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </UpHeader>

            {/* Filtro */}
            <FilterDrawer
                opened={filterOpened}
                setOpened={setFilterOpened}
                onFilter={fetchFilteredTickets}
                onButtonClick={handleButtonClick} // Passa a função como prop
            />

            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={<Typography>Todos <Chip sx={{ fontSize: 10, p: 0 }} size="small" label={ticketsAll.data?.length || 0} /></Typography>} value="1" />
                        <Tab label={<Typography>Tickets que participo <Chip sx={{ fontSize: 10, p: 0 }} size="small" label={ticketsParticipate.data?.length || 0} /></Typography>} value="2" />
                        {!!me && (
                            <Tab label={<Typography>Atribuídos a mim <Chip sx={{ fontSize: 10, p: 0 }} size="small" label={assignedTickets.data?.length || 0} /></Typography>} value="3" />
                        )}
                        <Tab label={<Typography>Criados por mim <Chip sx={{ fontSize: 10, p: 0 }} size="small" label={tickets.data?.length || 0} /></Typography>} value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ paddingLeft: '0px', paddingTop: '14px', paddingRight: '0px', paddingBottom: '0px', }}>
                    {loading && (
                        <Box sx={{ p: 5, textAlign: "center" }}>
                            <CircularProgress />
                        </Box>

                    )}

                    {!loading && (
                        <UpDataGrid
                            apiResult={ticketsAll}
                            columns={columns}
                            height={window.innerHeight - 200}
                            sx={{ width: '100%' }}
                        />
                    )}
                </TabPanel>
                <TabPanel value="2" sx={{ paddingLeft: '0px', paddingTop: '14px', paddingRight: '0px', paddingBottom: '0px', }}>
                    {loading && (
                        <Box sx={{ p: 5, textAlign: "center" }}>
                            <CircularProgress />
                        </Box>

                    )}

                    {!loading && (
                        <UpDataGrid
                            apiResult={ticketsParticipate}
                            columns={columns}
                            height={window.innerHeight - 200}
                            sx={{ width: '100%' }}
                        />
                    )}
                </TabPanel>
                <TabPanel value="3" sx={{ paddingLeft: '0px', paddingTop: '14px', paddingRight: '0px', paddingBottom: '0px', }}>
                    {loading && (
                        <Box sx={{ p: 5, textAlign: "center" }}>
                            <CircularProgress />
                        </Box>

                    )}

                    {!loading && (
                        <UpDataGrid
                            apiResult={assignedTickets}
                            columns={columns}
                            height={window.innerHeight - 200}
                            sx={{ width: '100%' }}
                        />
                    )}
                </TabPanel>
                <TabPanel value="4" sx={{ paddingLeft: '0px', paddingTop: '14px', paddingRight: '0px', paddingBottom: '0px', }}>
                    {loading && (
                        <Box sx={{ p: 5, textAlign: "center" }}>
                            <CircularProgress />
                        </Box>

                    )}

                    {!loading && (
                        <UpDataGrid
                            apiResult={tickets}
                            columns={columns}
                            height={window.innerHeight - 200}
                            sx={{ width: '100%' }}
                        />
                    )}
                </TabPanel>
            </TabContext>


        </Box>
    );
};

export default TicketListPage;

