import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { Interaction } from "../models/interaction";
import { CreateInteraction } from "../models/create-interaction";

const controller = "help-desk/interactions";

export default class InteractionService {
  static myInstance = null;

  static get instance() {
    return InteractionService.myInstance ?? new InteractionService();
  }

  async listByTicketId(ticketId: number): Promise<UpApiResult<Interaction[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/listByTicketId/${ticketId}`,
    );
    return validateHttpResponse(response);
  }

  async find(id: number): Promise<UpApiResult<Interaction>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async create(interaction: CreateInteraction): Promise<UpApiResult<Interaction>> {
    try {
      const response = await HttpClient.instance.post(
        `/${controller}`,
        interaction,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async update(
    id: number,
    interaction: CreateInteraction,
  ): Promise<UpApiResult<Interaction>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/${id}`,
        interaction,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: number): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }
}
