import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

const UploadFile = (s) => {
  const theme = useTheme();
  const { register, setValue, control } = useFormContext();
  const watchFile = useWatch({ control, name: "files" });

  const errorColor = "#f44336";
  const text = watchFile?.length
    ? watchFile[0]?.name?.replace("C:\\fakepath\\", "")
    : "Clique aqui ou arraste o arquivo para fazer o upload";

  const UploadFileLabel = styled("label")({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "172px",
    width: "100%",
    borderRadius: "1.2rem",
    marginBottom: "0px",
    cursor: "pointer",
    backgroundColor: theme.palette.background.default,
  });

  const removeFile = (e: React.ChangeEvent<any>) => {
    setValue("files", []);
    setValue("name", '');
  };

  return (
    <UploadFileLabel>
      <Box>
        <FontAwesomeIcon icon={["fal", "file-upload"]} size="5x" />
        <Typography sx={{ mt: 2 }}>{text}</Typography>
      </Box>
      <input
        {...register("files")}
        id="input"
        type="file"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          cursor: "pointer",
          opacity: 0,
        }}
      />

      {!!watchFile?.length && (
        <Tooltip title="Remover arquivo" onClick={removeFile}>
          <IconButton
            sx={{
              width: 42,
              height: 42,
              position: "absolute",
              right: 8,
              top: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={["fal", "trash-alt"]} color={errorColor} />
          </IconButton>
        </Tooltip>
      )}
    </UploadFileLabel>
  );
};

export default UploadFile;
