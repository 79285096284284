import { UpApiResult, UpMenuItem } from "@done/react-essentials";
import React, { createContext, useContext, useState } from "react";
import { ExternalUser } from "../../features/external-user/models/external-user";
import { User } from "../../features/integration/user/models/user";
import {
  adminMenu,
  agenteMenu,
  externalUserMenu,
} from "../../menus-items/menus-definitions";
import { PaletteMode } from "@mui/material";
import { Attachment } from "../../features/ticket-feature/attachment/models/attachment";
import { CreateAttachment } from "../../features/ticket-feature/attachment/models/create-attachment";
import { Interaction } from "../../features/ticket-feature/interaction/models/interaction";

interface AppContextContract {
  role: string | null;
  setRole: React.Dispatch<React.SetStateAction<string | null>>;
  canAccess: UpApiResult<boolean>;
  setCanAccess: React.Dispatch<React.SetStateAction<UpApiResult<boolean>>>;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  externalUser: ExternalUser | null;
  setExternalUser: React.Dispatch<React.SetStateAction<ExternalUser | null>>;
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  menuItems: UpMenuItem[];
  setMenuItems: React.Dispatch<React.SetStateAction<UpMenuItem[]>>;
  themeMode: PaletteMode;
  setThemeMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
  attachments: Attachment[];
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  attachmentsInteractions: CreateAttachment[];
  setAttachmentsInteractions: React.Dispatch<
    React.SetStateAction<CreateAttachment[]>
  >;
  interactions: UpApiResult<Interaction[]>;
  setInteractions: React.Dispatch<React.SetStateAction<UpApiResult<Interaction[]>>>;
}

const AppContext = createContext<AppContextContract>({} as AppContextContract);

const AppProvider = ({ children }: any) => {
  const [role, setRole] = useState<string | null>(null);
  const [canAccess, setCanAccess] = useState<UpApiResult<boolean>>(
    UpApiResult.start(),
  );
  const [user, setUser] = useState<User | null>(null);
  const [externalUser, setExternalUser] = useState<ExternalUser | null>(null);
  const [login, setLogin] = useState<boolean>(
    !!window.localStorage.getItem("vup_help_desk"),
  );
  const [menuItems, setMenuItems] = useState<UpMenuItem[]>([
    ...new Set([...adminMenu, ...agenteMenu, ...externalUserMenu]),
  ]);
  const [themeMode, setThemeMode] = useState<PaletteMode>(
    (window.localStorage.getItem("themeMode") as PaletteMode) ?? "dark",
  );
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [attachmentsInteractions, setAttachmentsInteractions] = useState<
    CreateAttachment[]
  >([]);
  const [interactions, setInteractions] = useState<UpApiResult<Interaction[]>>(
    UpApiResult.start(),
  );

  const context: AppContextContract = {
    role,
    setRole,
    canAccess,
    setCanAccess,
    user,
    setUser,
    externalUser,
    setExternalUser,
    isLoggedIn: login,
    setIsLoggedIn: setLogin,
    menuItems,
    setMenuItems,
    themeMode,
    setThemeMode,
    attachments,
    setAttachments,
    attachmentsInteractions,
    setAttachmentsInteractions,
    interactions,
    setInteractions,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider, useAppContext };
