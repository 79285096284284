import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../http/http-client";
import { CreateServiceLevelAgreement } from "../models/create-service-level-agreement";
import { ServiceLevelAgreement } from "../models/service-level-agreement";
import { ServiceLevelAgreementGroup } from "../models/service-level-agreement-group";

const controller = "help-desk/serviceLevelAgreements";

export default class ServiceLevelAgreementService {
  static myInstance: ServiceLevelAgreementService | null = null;

  static get instance(): ServiceLevelAgreementService {
    return (ServiceLevelAgreementService.myInstance ??= new ServiceLevelAgreementService());
  }

  async list(): Promise<UpApiResult<ServiceLevelAgreement[]>> {
    const response = await HttpClient.instance.get(`/${controller}`);
    return validateHttpResponse(response);
  }

  async listByTenantGroup(tenantGroupId: string): Promise<UpApiResult<ServiceLevelAgreement[]>> {
    const response = await HttpClient.instance.get(`/${controller}/by-tenantGroup/${tenantGroupId}`);
    return validateHttpResponse(response);
  }

  async listGroupTenantGroup(): Promise<UpApiResult<ServiceLevelAgreementGroup[]>> {
    const response = await HttpClient.instance.get(`/${controller}/sla-dto`);
    return validateHttpResponse(response);
  }

  async find(id: number): Promise<UpApiResult<ServiceLevelAgreement>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async create(serviceLevelAgreement: CreateServiceLevelAgreement): Promise<UpApiResult<ServiceLevelAgreement>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}`, serviceLevelAgreement);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async createAll(serviceLevelAgreements: CreateServiceLevelAgreement[]): Promise<UpApiResult<ServiceLevelAgreement[]>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}/all`, serviceLevelAgreements);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async updateAll(
    tenantGroupId: number,
    serviceLevelAgreements: CreateServiceLevelAgreement[],
  ): Promise<UpApiResult<ServiceLevelAgreement>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/${tenantGroupId}/all`,
        serviceLevelAgreements,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: number): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async activeOrDeactive(id: number): Promise<UpApiResult<ServiceLevelAgreement[]>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/activeOrDeactive/${id}`,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }
}
