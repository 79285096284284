import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { UpApiResult } from "@done/react-essentials";
import { User } from "../models/user";

const controller = "auth/users";

export default class UserService {
  private static myInstance = null;

  static get instance() {
    return UserService.myInstance ?? new UserService();
  }

  async list(): Promise<UpApiResult<User[]>> {
    const response = await HttpClient.instance.get(`/${controller}/all`);
    return validateHttpResponse(response);
  }

  async listByDoneSoftwareAccess(): Promise<UpApiResult<User[]>> {
    const response = await HttpClient.instance.get(`/${controller}/list-all-accessGroup-by-software`);
    return validateHttpResponse(response);
  }

  async listActives(): Promise<UpApiResult<User[]>> {
    const response = await HttpClient.instance.get(`/${controller}/actives`);
    return validateHttpResponse(response);
  }

  async find(id: string): Promise<UpApiResult<User>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async listByEmployees(emails: string[]): Promise<UpApiResult<User[]>> {
    const response = await HttpClient.instance.post(
      `/${controller}/list-by-emails`,
      emails,
    );

    return validateHttpResponse(response);
  }

  async me(): Promise<UpApiResult<User>> {
    const response = await HttpClient.instance.get(`/${controller}/me`);
    return validateHttpResponse(response);
  }
}
