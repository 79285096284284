import { PaletteMode } from "@mui/material";
import { useAppContext } from "../../shared/contexts/app-context";

const useThemeModeSelector = () => {
  const {setThemeMode} = useAppContext();

  return (themeMode: PaletteMode) => {
    if (themeMode !== "light" && themeMode !== "dark") themeMode = "light";

    window.localStorage.setItem("themeMode", themeMode);

    setThemeMode(themeMode);
  };
};

export default useThemeModeSelector;
