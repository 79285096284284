import axios, {
  AxiosInstance,
  HttpStatusCode
} from "axios";
import ExternalUserService from "../features/external-user/services/external-user-service";
import { LoginResult } from "../features/login-external/models/login-result";
import { environment } from "../shared/utils/environment";

export default class ExternalHttpClient {

  static get instance(): AxiosInstance {
    const sessionStr = window.localStorage.getItem("vup_help_desk");
    const session: LoginResult | null = !sessionStr
      ? null
      : JSON.parse(window.localStorage.getItem("vup_help_desk")!);

    const client = axios.create({
      baseURL: `${environment.VITE_API_GATEWAY_URL}`,
      headers: {
        Authorization: session?.token ? `Bearer ${session.token}` : "",
        "X-Account-Domain": session?.account?.domain ?? "",
        "X-Account-Id": session?.account?.id ?? "",
      },
    });

    client.interceptors.response.use(
      (response: any) => response,
      (rejection: any) => {
        // TODO WL: Refresh token
        if (rejection.response.status === HttpStatusCode.Unauthorized) {
          if (!rejection.request.responseURL.includes("logout")) {
            ExternalUserService.instance.logoutAsync();
          }
        }

        return rejection.response;
      },
    );

    return client;
  }
}
