import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { User } from "../../user/models/user";

const controller = "workspace/core/employees";

export default class EmployeeService {
  private static myInstance = null;

  static get instance() {
    return EmployeeService.myInstance ?? new EmployeeService();
  }

  async find(email: string): Promise<UpApiResult<User[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/listByEmails/external?emails=${email}`,
    );
    return validateHttpResponse(response);
  }

  async listActivesUsersIdsByResponsible(email: string): Promise<UpApiResult<User[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/actives-users-by-responsible/${email}`,
    );
    return validateHttpResponse(response);
  }
}
