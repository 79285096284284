import {
    UpApiResult,
    UpApiResultStatus,
    UpConfirmButton,
    UpHeader
} from "@done/react-essentials";
import { Autocomplete, Avatar, Box, Card, CardContent, Chip, Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import useMenuItem from "../../../menus-items/hooks/use-menu-item";
import { PrioritysNames } from "../../../shared/enums/priority";
import { environment } from "../../../shared/utils/environment";
import TicketClassificationsField from "../../classification/components/ticket-classifications";
import { ExternalUser } from "../../external-user/models/external-user";
import ExternalUserService from "../../external-user/services/external-user-service";
import BusinessUnitField from "../../integration/business-unit/components/business-unit-field";
import DepartmentField from "../../integration/department/components/department-field";
import OccupationAreaField from "../../integration/occupation-area/components/occupation-area-field";
import TenantGroupField from "../../integration/tenant-group/components/tenant-group-field";
import TenantField from "../../integration/tenant/components/tenant-field";
import ReportUser from "../../integration/user/components/report-user";
import externalUseMe from "../../integration/user/hooks/external-use-me";
import { User } from "../../integration/user/models/user";
import UserService from "../../integration/user/services/user-service";
import { StatusType } from "../../status/enums/status-type";
import { Status } from "../../status/models/status";
import StatusService from "../../status/services/status-service";
import AttachmentList from "../../ticket-feature/attachment/components/list";
import { Attachment } from "../../ticket-feature/attachment/models/attachment";
import { TicketTypesNames } from "../enums/ticket-type";
import { CreateTicket } from "../models/create-ticket";
import { Ticket } from "../models/ticket";
import TicketService from "../services/ticket.service";
import StatusField from "./status-field";

const TicketUpdatePage = () => {
    const [findApiResult, setFindApiResult] = useState<UpApiResult<Ticket>>(
        UpApiResult.start(),
    );

    const [saveApiResult, setSaveApiResult] = useState<UpApiResult<Ticket>>(
        UpApiResult.new(),
    );

    const theme = useTheme();
    const { id } = useParams();
    const [tags, setTags] = useState<string[]>([]);
    const [status, setStatus] = useState<Status>();
    const [disable, setDisable] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<Attachment[]>([]);

    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    const loggedUser = externalUseMe();
    const navigate = useNavigate();
    const menuItem = useMenuItem("/tickets");
    const { enqueueSnackbar } = useSnackbar();

    //#region Participantes WKS
    // participantes que foram salvos quando o ticket foi aberto.
    const [stakeholdersOld, setStakeholdersOld] = useState<User[]>([]);

    // ids dos participantes que foram salvos quando o ticket foi aberto.
    const [stakeholdersIds, setStakeholdersIds] = useState<string[]>([]);

    // participantes que foram adicionado quando o ticket foi criado, e os que é adicionado durante a iteração com o ticket.
    const [stakeholders, setStakeholders] = useState<User[]>([...stakeholdersOld]);

    // todos usuários do done auth
    const [usersAll, setUsersAll] = useState<User[]>([]);
    //#endregion

    //#region Participantes Externos
    // participantes que foram salvos quando o ticket foi aberto.
    const [externalStakeholdersOld, setExternalStakeholdersOld] = useState<ExternalUser[]>([]);

    // ids dos participantes que foram salvos quando o ticket foi aberto.
    const [externalStakeholdersIds, setExternalStakeholdersIds] = useState<string[]>([]);

    // participantes que foram adicionado quando o ticket foi criado, e os que é adicionado durante a iteração com o ticket.
    const [externalStakeholders, setExternalStakeholders] = useState<ExternalUser[]>(externalStakeholdersOld !== undefined ? [...externalStakeholdersOld] : []);

    // todos usuários externos 
    const [externalUsersAll, setExternalUsersAll] = useState<ExternalUser[]>([]);
    //#endregion

    const methods = useForm<CreateTicket>({
        defaultValues: async () => {

            if (id) {
                try {
                    const result = await TicketService.instance.find(parseInt(id!));

                    setFindApiResult(result);

                    if (result.status === UpApiResultStatus.success && result.data) {
                        const resultUsers = (await UserService.instance.listActives());
                        const resultExternalUsers = (await ExternalUserService.instance.listByTenantGroupId(loggedUser?.tenantGroupId ?? 0));

                        if (result.data?.status) {
                            if (result.data?.status.type === StatusType.Close)
                                setDisable(true);
                        }

                        setTags(result.data.tags);
                        setAttachments(result.data.attachments);

                        // wks
                        setUsersAll(resultUsers?.data || []);
                        setStakeholders(result.data?.stakeholders || []);
                        setStakeholdersOld(result.data?.stakeholders || []);
                        setStakeholdersIds(result.data?.stakeholdersIds || []);

                        // externos
                        setExternalUsersAll(resultExternalUsers?.data || []);
                        setExternalStakeholders(result.data?.externalStakeholders || []);
                        setExternalStakeholdersOld(result.data?.externalStakeholders || [])
                        setExternalStakeholdersIds(result.data?.externalStakeholdersIds || []);

                        return result.data;
                    }
                    else navigate(-1);
                } catch (error) {
                    navigate(-1);
                }
            }
            else {
                setStatus((await StatusService.instance.findByStatus(StatusType.New)).data);

                setFindApiResult(UpApiResult.success({}));
            }
        },
    });

    const {
        register,
        reset,
        control,
        watch,
        setValue,
        formState: { errors },
        setFocus,
        handleSubmit,
    } = methods;

    const save: SubmitHandler<CreateTicket> = async (createTicket) => {

        // Atribui valores seprados ao ticket
        createTicket.tags = tags;
        createTicket.attachments = attachments;

        // Wks
        for (const stakeholdersId of stakeholdersIds) {

            if (!createTicket.stakeholdersIds.includes(stakeholdersId))
                createTicket.stakeholdersIds.push(stakeholdersId);
        }

        // External
        for (const externalStakeholdersId of externalStakeholdersIds) {

            if (!createTicket.externalStakeholdersIds.includes(externalStakeholdersId))
                createTicket.externalStakeholdersIds.push(externalStakeholdersId);
        }

        // Quando cria ele atribui o status do tipo New para o chamado.
        if (!id)
            createTicket.statusId = status?.id ?? 0;

        // Atribui os classifications tickets que foram selecionados
        createTicket.classifications = createTicket.optionsSelect?.filter(x => x.selectedOption !== null && x.selectedOption !== undefined).flatMap(x => x.options.filter(a => a.id == x.selectedOption));

        const listTickets = UpApiResult<Ticket[]>

        if (createTicket.classifications.length > 0) {
            setSaveApiResult(UpApiResult.start());

            setSaveApiResult(
                await TicketService.instance.update(parseInt(id!), createTicket),
            );
        }
        else {
            enqueueSnackbar(
                `Não é possivel atualizar um ticket sem classificações.`,
                {
                    variant: "error",
                },
            );
        }
    };

    useEffect(() => {
        if (saveApiResult.status === UpApiResultStatus.success) {
            enqueueSnackbar(
                `O Ticket foi salvo com sucesso.`,
                {
                    variant: "success",
                },
            );

            navigate("/tickets");
        } else if (saveApiResult.status === UpApiResultStatus.error) {
            // setFocus("timeToFirstResponse");
            enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
        }
    }, [saveApiResult, id, enqueueSnackbar, reset, setFocus, navigate]);

    useEffect(() => { }, [findApiResult]);

    return (
        <Box>
            <FormProvider {...methods}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(save)}
                    style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                >

                    <UpHeader appTitle={"appTitle"} menuItem={menuItem} showBack>
                        <UpConfirmButton
                            apiResult={saveApiResult}
                            disabled={disable}
                            sx={{ ml: 2 }}
                            color="secondary"
                        />
                    </UpHeader>

                    {findApiResult.status === UpApiResultStatus.success && (
                        <>
                            <Card sx={{ mb: 3 }}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>

                                            <Grid container direction={isMdDown ? "column" : "row"} spacing={2}>
                                                <Grid item xs={6} sx={{ mt: 3 }}>
                                                    <ReportUser
                                                        control={control}>
                                                    </ReportUser>
                                                </Grid>
                                                <Grid item xs={6} sx={{ mt: 3 }}>
                                                    <TextField
                                                        {...register("text", {
                                                            required: "O campo nome é obrigatório.",
                                                            minLength: {
                                                                value: 3,
                                                                message: "O campo nome deve ao menos 3 caracteres.",
                                                            },
                                                        })}
                                                        disabled
                                                        color="warning"
                                                        size="small"
                                                        label="Titulo"
                                                        helperText={errors.text?.message ?? "*Obrigatório"}
                                                        error={!!errors.text}
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        required
                                                        autoFocus
                                                        sx={{ flexGrow: 1 }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        sx={{ mt: 2 }}
                                                        multiple
                                                        id="fixed-tags-demo"
                                                        value={stakeholders}
                                                        onChange={(event, newValue) => {

                                                            // Filtrar os novos usuários que não estão em stakeholdersOld
                                                            const newUsersSelect = newValue.filter(newUser =>
                                                                !stakeholdersOld.some(oldUser => oldUser.email === newUser.email)
                                                            );

                                                            // Mapear os emails dos novos usuários
                                                            const emailsStakeholdersNew = newUsersSelect.map(user => user.email);

                                                            // Atualizar os estados
                                                            setStakeholdersIds(emailsStakeholdersNew);
                                                            setStakeholders([
                                                                ...stakeholdersOld,
                                                                ...newUsersSelect,
                                                            ]);
                                                        }}
                                                        options={usersAll}
                                                        getOptionLabel={(user) => user.email}
                                                        renderTags={(tagValue, getTagProps) =>
                                                            tagValue.map((user, index) => {
                                                                const { key, ...tagProps } = getTagProps({ index });
                                                                return (
                                                                    <Chip
                                                                        key={key}
                                                                        label={user.email}
                                                                        {...tagProps}
                                                                        variant="filled"
                                                                        disabled={stakeholdersOld.indexOf(user) !== -1}
                                                                        avatar={<Avatar alt={user.name} src={`${environment.VITE_API_GATEWAY_URL}/onstock/images/${user.image}`} />}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Participantes Internos" placeholder="Adicione mais participantes internos" />
                                                        )}
                                                        disabled={disable}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        sx={{ mt: 5 }}
                                                        multiple
                                                        id="fixed-tags-demo"
                                                        value={externalStakeholders}
                                                        onChange={(event, newValue) => {

                                                            // Filtrar os novos usuários que não estão em externalStakeholdersOld
                                                            const newExternalUsersSelect = newValue.filter(newUser =>
                                                                !externalStakeholdersOld.some(oldUser => oldUser.email === newUser.email)
                                                            );

                                                            // Mapear os emails dos novos usuários
                                                            const emailsStakeholdersNew = newExternalUsersSelect.map(user => user.email);

                                                            // Atualizar os estados
                                                            setExternalStakeholdersIds(emailsStakeholdersNew);
                                                            setExternalStakeholders([
                                                                ...externalStakeholdersOld,
                                                                ...newExternalUsersSelect,
                                                            ]);
                                                        }}
                                                        options={externalUsersAll}
                                                        getOptionLabel={(user) => user.email}
                                                        renderTags={(tagValue, getTagProps) =>
                                                            tagValue.map((user, index) => {
                                                                const { key, ...tagProps } = getTagProps({ index });
                                                                return (
                                                                    <Chip
                                                                        key={key}
                                                                        label={user.email}
                                                                        {...tagProps}
                                                                        variant="filled"
                                                                        disabled={externalStakeholdersOld.indexOf(user) !== -1}
                                                                        avatar={<Avatar alt={user.name} src={`${environment.VITE_API_GATEWAY_URL}/onstock/images/${user.image}`} />}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Participantes Externos" placeholder="Adicione mais participantes externos" />
                                                        )}
                                                        disabled={disable}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 3 }} spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        {...register("subject", {
                                                            required: "O campo assunto é obrigatório.",
                                                            minLength: {
                                                                value: 5,
                                                                message: "O campo assunto deve ao menos 5 caracteres.",
                                                            },
                                                        })}
                                                        disabled
                                                        color="warning"
                                                        size="small"
                                                        label="Assunto"
                                                        helperText={errors.subject?.message ?? "*Obrigatório"}
                                                        error={!!errors.subject}
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows={4}
                                                        autoFocus
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                borderRadius: '16px',
                                                                '& fieldset': {
                                                                    borderRadius: '16px',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 0 }} direction={isMdDown ? "column" : "row"} spacing={2}>
                                                <Grid item xs={6}>
                                                    <StatusField
                                                        control={control}
                                                        disabled={disable}>
                                                    </StatusField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Controller
                                                        name="type"
                                                        control={control}
                                                        defaultValue={0}
                                                        rules={{ required: "O campo tipo é obrigatório." }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <Autocomplete
                                                                    options={TicketTypesNames}
                                                                    getOptionLabel={(type) => type.label}
                                                                    color="warning"
                                                                    size="small"
                                                                    fullWidth
                                                                    disabled={disable}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            color="warning"
                                                                            size="small"
                                                                            label="Tipo"
                                                                            helperText={errors.type?.message ?? "*Obrigatório"}
                                                                            error={!!errors.type}
                                                                            InputLabelProps={{ shrink: true }}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                    value={
                                                                        TicketTypesNames.find((x) => x.value === value) ??
                                                                        null
                                                                    }
                                                                    onChange={(_, value) => onChange(value?.value)}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 0 }} direction={isMdDown ? "column" : "row"} spacing={2}>
                                                <Grid item xs={6}>
                                                    <TenantGroupField
                                                        control={control}
                                                        disabled={disable}>
                                                    </TenantGroupField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TenantField
                                                        control={control}
                                                        disabled={disable}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 0 }} direction={isMdDown ? "column" : "row"} spacing={2}>
                                                <Grid item xs={6}>
                                                    <BusinessUnitField
                                                        control={control}
                                                        setValue={setValue}
                                                        disabled={disable}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>

                                                    <OccupationAreaField
                                                        control={control}
                                                        setValue={setValue}
                                                        disabled={disable}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 0 }} direction={isMdDown ? "column" : "row"} spacing={2}>
                                                <Grid item xs={6}>
                                                    <DepartmentField
                                                        listAll={false}
                                                        control={control}
                                                        disabled={disable} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Controller
                                                        name="priority"
                                                        control={control}
                                                        defaultValue={0}
                                                        rules={{ required: "O campo prioridade é obrigatório." }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <Autocomplete
                                                                    options={PrioritysNames}
                                                                    getOptionLabel={(priority) => priority.label}
                                                                    color="warning"
                                                                    size="small"
                                                                    fullWidth
                                                                    disabled={disable}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            color="warning"
                                                                            size="small"
                                                                            label="Prioridade"
                                                                            helperText={errors.priority?.message ?? "*Obrigatório"}
                                                                            error={!!errors.priority}
                                                                            InputLabelProps={{ shrink: true }}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                    value={
                                                                        PrioritysNames.find((x) => x.value === value) ??
                                                                        null
                                                                    }
                                                                    onChange={(_, value) => onChange(value?.value)}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 0 }} direction={isMdDown ? "column" : "row"} spacing={2} >
                                                <Grid item xs={6}>
                                                    <TicketClassificationsField
                                                        required={true}
                                                        control={control}
                                                        watch={watch}
                                                        ticketId={parseInt(id!)}
                                                        disabled={disable}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 0 }} spacing={2} >
                                                <Grid item xs={12}>
                                                    <Box sx={{ ml: 2, mb: 1 }}>Tags</Box>


                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ mt: 2 }} spacing={2} >
                                                <Grid item xs={12}>
                                                    {/* <AttachmentList
                                                        isAttachmentInteracionCreate={false}
                                                        saveApiResult={saveApiResult}
                                                        hideTrash={true}
                                                        ticketId={parseInt(id!)} /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    )}

                </form>
            </FormProvider>
        </Box>
    );
};

export default memo(TicketUpdatePage);
