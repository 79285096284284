import { Skeleton, SxProps, Theme } from "@mui/material";
import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";

interface UpDataGridLoadingProps {
  columns: GridColDef[];
  rowsCount: number;
  height?: number;
  sx?: SxProps<Theme> | undefined;
}

const UpDataGridLoading = (props: UpDataGridLoadingProps) => {
  const clone = Object.assign({}, props);
  const { columns, rowsCount, sx, height } = clone;
  const rows: any[] = [];

  for (let index = 0; index < columns.length; index++) {
    columns[index].sortable = false;
    columns[index].filterable = false;
    columns[index].hideable = false;
    columns[index].disableColumnMenu = true;
    columns[index].renderCell = (params) => (
      <Skeleton variant="rounded" height={25} width={params.colDef.minWidth} />
    );
  }

  for (let index = 0; index < rowsCount; index++) {
    rows.push({ id: index });
  }

  return (
    <div role="progressbar" style={{ height: `${height}px` }}>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={rows}
        pageSizeOptions={[10, 25, 50, 75, 100]}
        sx={sx}
      />
    </div>
  );
};

export default UpDataGridLoading;
