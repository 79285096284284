import {
  Avatar,
  Link,
  Skeleton,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { User } from "../models/user";
import withMe from "../hocs/withMe";
import { environment } from "../../../../shared/utils/environment";

type MeProps = {
  me: User | null;
  variant?: "name" | "nameEmail" | "nameAvatar";
  sx?: SxProps<Theme> | undefined;
};

type MeInnerProps = {
  me: User | null;
  sx?: SxProps<Theme> | undefined;
};

const Me = ({ variant = "name", sx, me }: MeProps) => {
  
  switch (variant) {
    case "nameEmail":
      return <MeNameEmail me={me} sx={sx} />;
    case "nameAvatar":
      return <MeNameAvatar me={me} sx={sx} />;
    default:
      return <MeName me={me} sx={sx} />;
  }
};

const MeName = ({ me, sx }: MeInnerProps) => {
  if (!me) return <Skeleton sx={{ ...sx, mx: 1, width: 100, height: 36 }} />;

  return (
    <Typography variant="h6" sx={{ ...sx, mx: 1, fontSize: 14 }}>
      {me.name}
    </Typography>
  );
};

const MeNameEmail = ({ me, sx }: MeInnerProps) => {
  if (!me)
    return (
      <>
        <Skeleton sx={{ ...sx, mx: 1, width: 120, height: 42 }} />
        <Skeleton sx={{ ...sx, mx: 1, width: 160, height: 28 }} />
      </>
    );

  return (
    <>
      <Typography
        color="primary"
        variant="h6"
        sx={{ ...sx, mt: 2, textAlign: "center" }}
      >
        {" "}
        {me.name}
      </Typography>
      {!!me.email && (
        <Link
          href={`mailto:${me.email}`}
          color="primary.main"
          sx={{ ...sx, textAlign: "center", fontSize: 12 }}
        >
          {me.email}
        </Link>
      )}
    </>
  );
};

const MeNameAvatar = ({ me, sx }: MeInnerProps) => {
  if (!me)
    return (
      <>
        <Skeleton variant="circular" sx={{ mx: 1, width: 36, height: 36 }} />
        <Skeleton
          sx={{
            ...sx,
            mx: 1,
            width: 120,
            height: 36,
            display: { xs: "none", lg: "initial" },
          }}
        />
      </>
    );

  return (
    <>
      <Avatar
        src={
          me.image
            ? `${environment.VITE_API_GATEWAY_URL}/onstock/images/${me.image}`
            : undefined
        }
        alt={me.name}
        sx={{ mr: 1 }}
      />
      <MeName me={me} sx={{ ...sx, display: { xs: "none", lg: "initial" } }} />
    </>
  );
};

export default withMe(Me);
