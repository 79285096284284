import { LoginResult, UpApiResult } from "@done/react-essentials";
import axios, { AxiosInstance, AxiosResponse, HttpStatusCode } from "axios";
import Cookies from "js-cookie";
import { environment } from "../shared/utils/environment";

const intercept = (client: AxiosInstance) => {
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const sessionStr = Cookies.get("session");
      const session: LoginResult | null = !sessionStr ? null : JSON.parse(sessionStr);

      const sessionExternalStr = window.localStorage.getItem("vup_help_desk");
      const sessionExternal: LoginResult | null = !sessionExternalStr ? null : JSON.parse(sessionExternalStr);

      if (window.location.pathname === '/' && !session && !sessionExternal)
        window.location.href = `${environment.VITE_APP_HELPDESK_URL}/login`;
      else if (error.response.status === 401 && window.location.pathname.includes("external-login")) {
        return error.response;
      }
      else if (error.response.status === 401 && !["/login", "/session"].includes(window.location.pathname)) {
        window.location.href = `${environment.VITE_APP_WORKSPACE_URL}/login/refresh-token?returnUrl=${window.location.href}`;
      }

      return error.response;
    },
  );
};

class HttpClient {
  private static _instance: AxiosInstance;

  static get instance(): AxiosInstance {
    const sessionStr = Cookies.get("session");
    const session: LoginResult | null = !sessionStr ? null : JSON.parse(sessionStr);

    const sessionExternalStr = window.localStorage.getItem("vup_help_desk");
    const sessionExternal: LoginResult | null = !sessionExternalStr ? null : JSON.parse(sessionExternalStr);

    const client =
      HttpClient._instance ??
      axios.create({
        baseURL: environment.VITE_API_GATEWAY_URL,
        headers: {
          Authorization: session?.token ? `Bearer ${session.token}` : sessionExternal?.token ? `Bearer ${sessionExternal.token}` : "",
          "X-Account-Domain": session?.account?.domain ?? sessionExternal?.account?.domain ?? "",
          "X-Account-Id": session?.account?.id ?? sessionExternal?.account?.id ?? "",
        },
      });

    intercept(client);

    return client;
  }
}

const validateHttpResponse = (response: AxiosResponse<any, any>) => {
  if (response.status !== HttpStatusCode.Ok)
    return UpApiResult.error(response.data);

  return UpApiResult.success(response.data);
};

export { HttpClient, validateHttpResponse };
