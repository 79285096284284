import {
  UpApiResult,
  UpApiResultStatus,
  UpConfirmButton,
  UpDividedCard,
  UpHeader,
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { useNavigate, useParams } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import { themeColors } from "../../../../themes/app-theme";
import TenantGroupField from "../../../integration/tenant-group/components/tenant-group-field";
import { CreateExternalUser } from "../../models/create-external-user";
import { ExternalUser } from "../../models/external-user";
import ExternalUserService from "../../services/external-user-service";

const ExternalUserFormPage = () => {
  const { id } = useParams();

  const [findApiResult, setFindApiResult] = useState<UpApiResult<ExternalUser>>(
    UpApiResult.start(),
  );

  const [saveApiResult, setSaveApiResult] = useState<UpApiResult<ExternalUser>>(
    UpApiResult.new(),
  );

  const [removeApiResult, setRemoveApiResult] = useState<UpApiResult<boolean>>(
    UpApiResult.new(),
  );

  const [dialogOpened, setDialogOpened] = useState<{
    id: string;
    text: string;
  } | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const menuItem = useMenuItem("/external-users");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const {
    register,
    reset,
    control,
    setFocus,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<CreateExternalUser>({
    defaultValues: async () => {
      if (id) {
        try {
          var result = await ExternalUserService.instance.find(id);

          setFindApiResult(result);

          if (result.status === UpApiResultStatus.success && result.data)
            return result.data;
          else navigate(-1);
        } catch (error) {
          navigate(-1);
        }
      }
    },
  });

  const save: SubmitHandler<CreateExternalUser> = async (user) => {
    setSaveApiResult(UpApiResult.start());

    setFocus("name");

    if (id) {
      setSaveApiResult(await ExternalUserService.instance.update(id, user));
    } else setSaveApiResult(await ExternalUserService.instance.create(user));
  };

  useEffect(() => {
    if (saveApiResult.status === UpApiResultStatus.success) {
      enqueueSnackbar(
        `O cliente ${saveApiResult.data?.name} salvo com sucesso.`,
        {
          variant: "success",
        },
      );

      navigate("/external-users");
    } else if (saveApiResult.status === UpApiResultStatus.error) {
      setFocus("name");
      enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
    }
  }, [saveApiResult, id, enqueueSnackbar, reset, setFocus, navigate]);

  useEffect(() => {
    if (removeApiResult.status === UpApiResultStatus.success) {
      enqueueSnackbar(`Cliente removido com sucesso.`, {
        variant: "success",
      });

      navigate("/external-users");
    } else if (removeApiResult.status === UpApiResultStatus.error) {
      setFocus("name");
      enqueueSnackbar(removeApiResult.errorMessage, { variant: "error" });
    }
  }, [removeApiResult]);

  useEffect(() => { }, [findApiResult]);

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [newPasswordConfirmationVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);

  const remove = async () => {
    setIsLoading(true);

    var result = await ExternalUserService.instance.remove(id!);

    if (result.errorMessage !== null)
      setRemoveApiResult(UpApiResult.error(result.errorMessage));
    else {
      setRemoveApiResult(UpApiResult.success(true));
    }
  };

  const openDialog = () => {
    setDialogOpened({
      id: id!!,
      text: findApiResult?.data.name ?? "-",
    });
  };

  const closeDialog = async (confirm: boolean | undefined) => {
    if (confirm === true) remove();

    setDialogOpened(null);
  };

  return (
    <Box>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(save)}
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <UpHeader appTitle={appTitle} menuItem={menuItem} showBack>
          <UpConfirmButton
            apiResult={saveApiResult}
            sx={{ ml: 2 }}
            color="primary"
          />
        </UpHeader>

        {/* Dados */}
        <UpDividedCard
          title="Dados Cadastrais"
          description="Informa os dados básicos de um cliente"
        >
          <Dialog
            open={!!dialogOpened}
            onClose={() => closeDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remover Cliente</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Confirmar a remoção do cliente{" "}
                <strong>{dialogOpened?.text}</strong>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => closeDialog(false)}
                autoFocus
                sx={{ mr: 1 }}
              >
                Cancelar
              </Button>
              <Button onClick={() => closeDialog(true)} color={"error"}>
                Remover
              </Button>
            </DialogActions>
          </Dialog>

          <Box sx={{ mt: 3 }}>
            <TenantGroupField control={control}></TenantGroupField>
          </Box>

          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("name", {
                required: "O campo nome é obrigatório.",
                minLength: {
                  value: 3,
                  message: "O campo nome deve ao menos 3 caracteres.",
                },
              })}
              color="warning"
              size="small"
              label="Nome do usuário"
              helperText={errors.name?.message ?? "*Obrigatório"}
              error={!!errors.name}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              autoFocus
              sx={{ flexGrow: 1 }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("email", {
                required: "O campo email é obrigatório.",
                minLength: {
                  value: 3,
                  message: "O campo email deve ao menos 3 caracteres.",
                },
              })}
              color="warning"
              size="small"
              label="Email"
              helperText={errors.email?.message ?? "*Obrigatório"}
              error={!!errors.email}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              autoFocus
              type="email"
              sx={{ flexGrow: 1 }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("phoneNumber")}
              color="warning"
              size="small"
              label="Telefone"
              helperText={errors.phoneNumber?.message}
              error={!!errors.phoneNumber}
              InputLabelProps={{ shrink: true }}
              fullWidth
              autoFocus
              type="tel"
              sx={{ flexGrow: 1 }}
              InputProps={{
                inputComponent: IMaskInput,
                inputProps: {
                  mask: "(00) 00000-0000", // Define a máscara de horas
                },
              }}
            />
          </Box>

          {!id && (
            <Grid
              container
              direction={isMdDown ? "column" : "row"}
              sx={{ mt: 1 }}
              spacing={2}
            >
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  {...register("password", {
                    required: "O campo senha é obrigatório.",
                    pattern: {
                      value:
                        /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
                      message:
                        "A senha deve conter ao menos um número, uma letra maiúscula, uma minúscula e um caractere especial.",
                    },
                  })}
                  color="warning"
                  size="small"
                  label="Senha"
                  helperText={errors.password?.message ?? "*Obrigatório"}
                  error={!!errors.password}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        tabIndex={-1}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        <FontAwesomeIcon
                          size="sm"
                          icon={["fal", !passwordVisible ? "eye" : "eye-slash"]}
                        />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                  required
                  autoFocus
                  type={passwordVisible ? "text" : "password"}
                  sx={{ flexGrow: 1 }}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  {...register("passwordConfirm", {
                    required: "O campo confirmar senha é obrigatório.",
                    validate: (value) =>
                      value === getValues("password") ||
                      "As senhas não coincidem.",
                    pattern: {
                      value:
                        /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
                      message:
                        "A senha deve conter ao menos um número, uma letra maiúscula, uma minúscula e um caractere especial.",
                    },
                  })}
                  color="warning"
                  size="small"
                  label="Confirmar senha"
                  helperText={errors.passwordConfirm?.message ?? "*Obrigatório"}
                  error={!!errors.passwordConfirm}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        tabIndex={-1}
                        onClick={() =>
                          setConfirmPasswordVisible(
                            !newPasswordConfirmationVisible,
                          )
                        }
                      >
                        <FontAwesomeIcon
                          size="sm"
                          icon={[
                            "fal",
                            !newPasswordConfirmationVisible
                              ? "eye"
                              : "eye-slash",
                          ]}
                        />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                  required
                  autoFocus
                  type={newPasswordConfirmationVisible ? "text" : "password"}
                  sx={{ flexGrow: 1 }}
                />
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item md={2}>
              <FormGroup>
                <Controller
                  name="active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ mt: 1 }}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="secondary"
                        />
                      }
                      label="Ativo"
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>

          {id && (
            <Box
              sx={{
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                disabled={removeApiResult?.status === UpApiResultStatus.loading}
                sx={{ color: themeColors.errorColor }}
                onClick={openDialog}
              >
                {removeApiResult?.status === UpApiResultStatus.loading && (
                  <CircularProgress role="spinbutton" size={16} />
                )}
                {removeApiResult?.status !== UpApiResultStatus.loading && (
                  <FontAwesomeIcon title="check" icon={["fas", "trash"]} />
                )}
                <Typography component="span" variant="button" sx={{ ml: 1 }}>
                  Remover
                </Typography>
              </Button>
            </Box>
          )}
        </UpDividedCard>
      </form>
    </Box>
  );
};

export default ExternalUserFormPage;
