import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessUnitService from "../services/business-unit-service";
import { BusinessUnit } from "../models/business-unit";
import { CreateTicket } from "../../../ticket/models/create-ticket";

interface BusinessUnitFieldProps {
  setValue;
  control: any;
  required?: boolean;
  ServiceLevelAgreementId?: number;
  disabled?: boolean;
}

const BusinessUnitField = ({
  setValue,
  control,
  required = true,
  disabled = false,
}: BusinessUnitFieldProps) => {
  const { errors } = useFormState<CreateTicket>({ control: control });

  const [businessUnits, setBusinessUnits] = useState<UpApiResult<BusinessUnit[]>>(UpApiResult.start());

  const fetchData = async () => {
    setBusinessUnits(await BusinessUnitService.instance.list());
  };

  useEffect(() => {
    if (businessUnits.status === UpApiResultStatus.loading) fetchData();
  }, [businessUnits]);

  const fieldValidationRules = {};

  if (required) {
    fieldValidationRules["required"] =
      "O campo unidade de negócio é obrigatório.";
  }

  return (
    <Controller
      name="businessUnitId"
      control={control}
      defaultValue={null}
      rules={fieldValidationRules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={businessUnits.data || []}
          getOptionLabel={(businessUnit: BusinessUnit) => businessUnit.name}
          color="warning"
          size="small"
          fullWidth
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              color="warning"
              size="small"
              label="Unidade de negócio"
              placeholder={
                businessUnits.status === UpApiResultStatus.loading
                  ? "Carregando..."
                  : ""
              }
              helperText={
                errors.businessUnitId?.message ??
                (required ? "*Obrigatório" : "")
              }
              error={!!errors.businessUnitId}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                startAdornment: businessUnits.status ===
                  UpApiResultStatus.loading && (
                    <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                      <FontAwesomeIcon icon={["fal", "loader"]} spin />
                    </InputAdornment>
                  ),
              }}
              fullWidth
              required={required}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          value={
            businessUnits.data?.find(
              (businessUnit: BusinessUnit) => businessUnit.id === value,
            ) ?? null
          }
          onChange={(_, value) => {
            setValue("occupationAreaId", null);
            setValue("departmentId", null);

            return onChange(value?.id ?? null);
          }}
        />
      )}
    />
  );
};

export default memo(BusinessUnitField);
