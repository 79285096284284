import { Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

const IntervalField = ({
    day,
    register,
    firstRangeStart, firstRangeEnd,
    secondRangeStart, secondRangeEnd,
    thirdRangeStart, thirdRangeEnd
}) => (
    <Card sx={{ mb: 3 }}>
        <CardContent>
            <Typography sx={{ mb: 1 }} variant="h6">{day}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ ml: 1 }}>Primeiro turno</Typography>
                    <Grid container sx={{ mt: 0 }} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                {...register(firstRangeStart)}
                                color="warning"
                                size="small"
                                label="Início"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="string"
                                defaultValue="00:00"
                                required
                                InputProps={{
                                    inputComponent: IMaskInput,
                                    inputProps: {
                                        mask: '00:00', // Define a máscara de horas
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...register(firstRangeEnd)}
                                color="warning"
                                size="small"
                                label="Fim"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="string"
                                defaultValue="00:00"
                                required
                                InputProps={{
                                    inputComponent: IMaskInput,
                                    inputProps: {
                                        mask: '00:00', // Define a máscara de horas
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{ ml: 1 }}>Segundo turno</Typography>
                    <Grid container sx={{ mt: 0 }} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                {...register(secondRangeStart)}
                                color="warning"
                                size="small"
                                label="Início"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="string"
                                defaultValue="00:00"
                                required
                                InputProps={{
                                    inputComponent: IMaskInput,
                                    inputProps: {
                                        mask: '00:00', // Define a máscara de horas
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...register(secondRangeEnd)}
                                color="warning"
                                size="small"
                                label="Fim"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="string"
                                defaultValue="00:00"
                                required
                                InputProps={{
                                    inputComponent: IMaskInput,
                                    inputProps: {
                                        mask: '00:00', // Define a máscara de horas
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{ ml: 1 }}>Terceiro turno</Typography>
                    <Grid container sx={{ mt: 0 }} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                {...register(thirdRangeStart)}
                                color="warning"
                                size="small"
                                label="Início"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="string"
                                defaultValue="00:00"
                                required
                                InputProps={{
                                    inputComponent: IMaskInput,
                                    inputProps: {
                                        mask: '00:00', // Define a máscara de horas
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...register(thirdRangeEnd)}
                                color="warning"
                                size="small"
                                label="Fim"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="string"
                                defaultValue="00:00"
                                required
                                InputProps={{
                                    inputComponent: IMaskInput,
                                    inputProps: {
                                        mask: '00:00', // Define a máscara de horas
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default IntervalField;