import { Tenant } from "../models/tenant";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { UpApiResult } from "@done/react-essentials";

const controller = "workspace/core/tenants";

export default class TenantService {
  private static myInstance = null;

  static get instance() {
    return TenantService.myInstance ?? new TenantService();
  }

  async listByGroup(groupId: number): Promise<UpApiResult<Tenant[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/external/group/${groupId}`,
    );
    return validateHttpResponse(response);
  }
}
