import { To } from "react-router";
import { useAppContext } from "../../shared/contexts/app-context";

const useMenuItem = (route: To) => {
  const { menuItems } = useAppContext();
  const item = menuItems.find((x) => x.route === route);

  if (!item) throw new Error(`Item with route "${route}" not found.`);

  return item;
};

export default useMenuItem;
