import { UpApiResult, UpConfirmButton } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, ListItemText, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { useRecoilState } from "recoil";
import Logo from "../../../shared/logo";
import { ExternalUser } from "../../external-user/models/external-user";
import ExternalUserService from "../../external-user/services/external-user-service";
import { AccountService } from "../../integration/account/services/account-service";
import useSetMe from "../../integration/user/hooks/use-set-me";
import { LoginRequest } from "../models/login-request";
import { ResetPassword } from "../models/reset-password";
import { ResetPasswordEmail } from "../models/reset-password-email";
import {useAppContext} from "../../../shared/contexts/app-context";

const LoginExternal = () => {
  const {
    register,
    reset,
    setFocus,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginRequest>();

  const setMe = useSetMe();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  
  const { id } = useParams();
  const {isLoggedIn: login, setIsLoggedIn: setLogin} = useAppContext();
  
  const [params, _] = useSearchParams();
  const recoveryToken: string | null = params.get('recovery_token');
  const email: string | null = params.get('u_email');

  const doLogin: SubmitHandler<LoginRequest> = async (model) => {

    let account = await AccountService.instance.findExternal(parseInt(id!));

    const result = await ExternalUserService.instance.loginAsync(model, account.data);

    if (!!result.data) {
      await setMe();

      setLogin(true);
    } else {
      enqueueSnackbar(result.errorMessage, { variant: "error" });
      setFocus("userName");
    }
  };

  useEffect(() => {
    if (!!login)
      navigate(`/`);
  }, [login]);

  return (
    <>
      <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
        <Container
          maxWidth="sm"
          sx={{ textAlign: "center", justifyContent: "center" }}
        >
          <Box
            sx={{
              width: "240px",
              mb: 5,
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            <Logo />
          </Box>

          {!!recoveryToken && !!email && (
            <ResetPasswordContent email={email} recoveryToken={recoveryToken} />
          )}

          {!recoveryToken && !email && (
            <Card sx={{ py: 5, borderRadius: '30px' }}>
              <Typography
                component="h1"
                variant="h4"
                color="primary"
                sx={{ px: 5 }}
              >
                Faça seu Login
              </Typography>
              <Divider
                sx={{ my: 5, borderWidth: 4, borderColor: "warning.main" }}
              />
              <Box sx={{ px: 5 }}>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(doLogin)}
                >
                  <TextField
                    {...register("userName", {
                      required: "O campo usuário é obrigatório.",
                      minLength: {
                        value: 3,
                        message: "O campo usuário deve ao menos 3 caracteres.",
                      },
                    })}
                    color="warning"
                    size="small"
                    label="Usuário"
                    helperText={errors.userName?.message ?? "*Obrigatório"}
                    error={!!errors.userName}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                    autoFocus
                    sx={{ mb: 2 }}
                  />

                  <TextField
                    {...register("password", {
                      required: "O campo senha é obrigatório.",
                      minLength: {
                        value: 3,
                        message: "O campo senha deve ao menos 3 caracteres.",
                      },
                    })}
                    color="warning"
                    size="small"
                    label="Senha"
                    type="password"
                    helperText={errors.password?.message ?? "*Obrigatório"}
                    error={!!errors.password}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                  />

                  <Grid container direction={isMdDown ? "column" : "row"} spacing={2}>
                    <Grid item xs={6}>
                      <ForgotPassword />
                    </Grid>
                    <Grid item xs={6}>
                      <UpConfirmButton
                        apiResult={UpApiResult.new()}
                        icon={["fal", "sign-in"]}
                        text="Acessar Help Desk"
                      />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Card>
          )}
        </Container>
      </Box>

    </>
  );
};

const ForgotPassword = () => {
  const [formDialogOpened, setFormDialogOpened] = useState<{
    dialogOpened: boolean;
  } | null>(null);

  const [sendEmailApiResult, setSendEmailApiResult] = useState<UpApiResult<ResetPasswordEmail>>(
    UpApiResult.new(),
  );

  const openModalForgotPassword = (): void => {
    setFormDialogOpened({ dialogOpened: true });
  };

  const closeFormDialog = async () => {
    setFormDialogOpened(null);
  };

  const sendEmail: SubmitHandler<ResetPasswordEmail> = async (resetPasswordEmail) => {
    setSendEmailApiResult(UpApiResult.start());

    try {
      let result = await ExternalUserService.instance.requestPassworRecoverydAsync(resetPasswordEmail.email);
      setSendEmailApiResult(UpApiResult.success(result));
      enqueueSnackbar("E-mail enviado com sucesso", { variant: "success" });
      closeFormDialog();
    } catch (error: any) {
      enqueueSnackbar(error, { variant: "error" });
      setSendEmailApiResult(UpApiResult.error(error));
    }
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordEmail>({
  });

  return (
    <>
      <Button sx={{ color: 'gray' }} onClick={() => openModalForgotPassword()}>Esqueceu sua senha?</Button>

      <Dialog
        open={!!formDialogOpened}
        onClose={() => closeFormDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form noValidate autoComplete="off" onSubmit={handleSubmit(sendEmail)}>
          <DialogTitle id="alert-dialog-title">Recuperar senha</DialogTitle>
          <DialogContent sx={{ marginTop: '0px', paddingBottom: '0px' }}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <ListItemText
                      sx={{ mb: 1 }}
                      secondary="Informe o seu e-mail para receber o link de restauração de senha."
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      {...register("email", {
                        required: "O campo e-mail é obrigatório.",
                        pattern: {
                          value: /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/gi,
                          message: "Formato inválido.",
                        },
                      })}
                      color="warning"
                      size="small"
                      inputMode="email"
                      label="E-mail"
                      helperText={errors.email?.message?.toString()}
                      error={!!errors.email}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      sx={{ mt: 2 }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeFormDialog()} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <UpConfirmButton
              apiResult={sendEmailApiResult}
              iconPrefix="fal"
              sx={{ ml: 2 }}
              text="Confirmar"
              icon={["fal", "check"]}
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const ResetPasswordContent = (dto) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [saveApiResult, setSaveApiResult] = useState<UpApiResult<ExternalUser>>(
    UpApiResult.new(),
  );

  const [resetPassword, setResetPassword] = useState<ResetPassword>({
    password: "",
    newPassword: "",
    recoveryCode: "",
    email: "",
  });

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [newPasswordConfirmationVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);

  const {
    reset,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPassword>({
    defaultValues: resetPassword,
  });

  const password = useWatch<ResetPassword>({
    name: "newPassword",
    control: control,
  });

  const save: SubmitHandler<ResetPassword> = async (reset) => {
    setSaveApiResult(UpApiResult.start());

    reset.recoveryCode = dto.recoveryToken;
    reset.email = dto.email;

    try {
      let result = await ExternalUserService.instance.recoverPasswordAsync(reset);
      setSaveApiResult(UpApiResult.success(result));
      enqueueSnackbar("Senha recuperada com sucesso", { variant: "success" });
      navigate(`/${id}/external-login`);
    } catch (error: any) {
      enqueueSnackbar(error, { variant: "error" });
      setSaveApiResult(UpApiResult.error(error));
    }
  };

  return (
    <>
      <Card sx={{ py: 5, borderRadius: '30px' }}>
        <Typography
          component="h1"
          variant="h4"
          color="primary"
          sx={{ px: 5 }}
        >
          Recuperar Senha
        </Typography>
        <Divider
          sx={{ my: 5, borderWidth: 4, borderColor: "warning.main" }}
        />
        <Box sx={{ px: 5 }}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(save)}
          >

            <TextField
              color="warning"
              size="small"
              required
              fullWidth
              label="Senha"
              type={passwordVisible ? "text" : "password"}
              autoComplete="password"
              {...register("newPassword", {
                required: "Campo obrigatório.",
                pattern: {
                  value:
                    /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
                  message:
                    "A senha deve conter ao menos um número, uma letra maiúscula, uma minúscula e um caractere especial.",
                },
              })}
              helperText={errors.newPassword?.message ?? ""}
              error={!!errors.newPassword}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    tabIndex={-1}
                    onClick={() =>
                      setPasswordVisible(!passwordVisible)
                    }
                  >
                    <FontAwesomeIcon
                      size="sm"
                      icon={[
                        "fal",
                        !passwordVisible ? "eye" : "eye-slash",
                      ]}
                    />
                  </IconButton>
                ),
              }}
            />

            <TextField
              sx={{ my: 3 }}
              color="warning"
              size="small"
              required
              fullWidth
              label="Confirmar senha"
              type={
                newPasswordConfirmationVisible ? "text" : "password"
              }
              {...register("password", {
                required: "Campo obrigatório.",
                validate: (value) =>
                  value === password || "As senhas não coincidem.",
                pattern: {
                  value:
                    /(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/g,
                  message:
                    "A senha deve conter ao menos um número, uma letra maiúscula, uma minúscula e um caractere especial.",
                },
              })}
              helperText={
                errors.password?.message ?? ""
              }
              error={!!errors.password}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    tabIndex={-1}
                    onClick={() =>
                      setConfirmPasswordVisible(
                        !newPasswordConfirmationVisible,
                      )
                    }
                  >
                    <FontAwesomeIcon
                      size="sm"
                      icon={[
                        "fal",
                        !newPasswordConfirmationVisible
                          ? "eye"
                          : "eye-slash",
                      ]}
                    />
                  </IconButton>
                ),
              }}
            />

            <Typography component="h1"
              variant="subtitle2"
              color="primary"
              sx={{ px: 5 }}>
              -  A senha deve conter ao menos uma letra maiúscula
            </Typography>
            <Typography component="h1"
              variant="subtitle2"
              color="primary"
              sx={{ px: 5 }}>
              -  A senha deve conter ao menos uma letra minúscula
            </Typography>
            <Typography component="h1"
              variant="subtitle2"
              color="primary"
              sx={{ px: 5 }}>
              -  A senha deve conter ao menos um caractere especial
            </Typography>
            <Typography component="h1"
              variant="subtitle2"
              color="primary"
              sx={{ px: 5 }}>
              -  A senha deve conter ao menos um número
            </Typography>

            <Grid item xs={6} sx={{ textAlign: "end", mt: 3 }}>
              <UpConfirmButton
                apiResult={UpApiResult.new()}
                icon={["fal", "sign-in"]}
                text="Confirmar"
              />
            </Grid>
          </form>
        </Box>
      </Card>
    </>
  )
}

export default LoginExternal;
