import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import UploadService from "../../../../upload/services/upload.service";
import { Attachment } from "../../models/attachment";
import { CreateAttachment } from "../../models/create-attachment";
import AttachmentService from "../../services/attachment-service";
import { useAppContext } from "../../../../../shared/contexts/app-context";

interface AttachmentListProps {
  isAttachmentInteracionCreate: boolean;
  ticketId?: number;
  hideTrash: boolean;
  isVisibleAllAttachments?: boolean | undefined;
  saveApiResult;
  attachments: Attachment[];
  attachmentsInteractions: CreateAttachment[];
}

const AttachmentList = ({
  isAttachmentInteracionCreate,
  ticketId,
  hideTrash,
  isVisibleAllAttachments,
  saveApiResult,
  attachments,
  attachmentsInteractions,
}: AttachmentListProps) => {
  const { setAttachments, setAttachmentsInteractions } = useAppContext();

  const listByTicket = async () => {
    const result = await AttachmentService.instance.listByTicketId(ticketId!);

    if (result.data) setAttachments(result.data);
  };

  const listByTicketByInteractionAll = async () => {
    const result =
      await AttachmentService.instance.listByTicketIdByInteractionAll(
        ticketId!,
      );

    if (result.data) setAttachments(result.data);
  };

  useEffect(() => {
    // caso for do ticket principal, lista apenas os anexos do ticket
    if (!!!isAttachmentInteracionCreate) {
      if (ticketId) {
        if (!!isVisibleAllAttachments) listByTicketByInteractionAll();
        else {
          listByTicket();
        }
      } else {
        setAttachments([]);
      }
    }
  }, [ticketId, saveApiResult]);

  const download = (attachment: CreateAttachment) => {
    window.open(`${UploadService.downloadBaseUrl}/${attachment.url}`, "_blank");
  };

  const remove = async (attachment: CreateAttachment) => {
    try {
      var lista = attachments.filter((item) => item.url !== attachment.url);
      setAttachments(lista);

      enqueueSnackbar(`Anexo excluído com sucesso`, {
        variant: "success",
      });
    } catch (error) {
      alert(error);
    }
  };

  const removeAttachmentInteraction = async (attachment: CreateAttachment) => {
    try {
      var lista = attachmentsInteractions.filter(
        (item) => item.url !== attachment.url,
      );
      setAttachmentsInteractions(lista);

      enqueueSnackbar(`Anexo excluído com sucesso`, {
        variant: "success",
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
        {!!!isAttachmentInteracionCreate && (
          <>
            {attachments.length > 0 && (
              <Typography variant="h6" sx={{ mx: 1 }}>
                Anexos
              </Typography>
            )}

            {attachments.length > 0 && (
              <List>
                {attachments.map((attachment: CreateAttachment, index) => (
                  <React.Fragment key={attachment.url}>
                    <ListItem disablePadding>
                      <ListItemText primary={attachment.name} />

                      <ListItemIcon sx={{ margin: "2px" }}>
                        <IconButton onClick={() => download(attachment)}>
                          <FontAwesomeIcon icon={["fal", "download"]} size="sm" />
                        </IconButton>
                        {!hideTrash && (
                          <IconButton onClick={() => remove(attachment)}>
                            <FontAwesomeIcon
                              icon={["fal", "trash-alt"]}
                              size="sm"
                            />
                          </IconButton>
                        )}
                      </ListItemIcon>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            )}
          </>
        )}

        {isAttachmentInteracionCreate && (
          <>
            {!!ticketId && attachmentsInteractions.length > 0 && (
              <Typography variant="h6" sx={{ mx: 1 }}>
                Anexos
              </Typography>
            )}

            {!!ticketId && attachmentsInteractions.length > 0 && (
              <List>
                {attachmentsInteractions.map(
                  (attachment: CreateAttachment, index) => (
                    <React.Fragment key={attachment.url}>
                      <ListItem disablePadding>
                        <ListItemText primary={attachment.name} />

                        <ListItemIcon sx={{ margin: "2px" }}>
                          <IconButton onClick={() => download(attachment)}>
                            <FontAwesomeIcon
                              icon={["fal", "download"]}
                              size="sm"
                            />
                          </IconButton>
                          {!hideTrash && (
                            <IconButton
                              onClick={() =>
                                removeAttachmentInteraction(attachment)
                              }
                            >
                              <FontAwesomeIcon
                                icon={["fal", "trash-alt"]}
                                size="sm"
                              />
                            </IconButton>
                          )}
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ),
                )}
              </List>
            )}
          </>
        )}
    </>
  );
};

export default AttachmentList;
