import { LoginResult } from "@done/react-essentials";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserService } from "../../integration/account-user/services/account-user-service";
import Cookies from "js-cookie";
import { useAppContext } from "../../../shared/contexts/app-context";


const useRole = () => {
    const { role } = useAppContext();

    return role;
};

const useSetRole = () => {
    const { setRole } = useAppContext();

    return async () => {
        try {
            const session = Cookies.get("session");
            const sessionExternal = localStorage.getItem("vup_help_desk");

            if (!!session) {
                const sessionObj: LoginResult | null = JSON.parse(session!);

                if (sessionObj) {
                    let role = sessionObj.role;

                    if (sessionObj.account) {
                        if (!role) {
                            const alcada = await AccountUserService.instance.findAlcadaHelpDesk(
                                sessionObj.id,
                                sessionObj.account?.id ?? 0
                            );

                            if (alcada.data === '' || alcada.data === null || alcada.data === undefined)
                                setRole(null);
                            else {
                                setRole(alcada.data);
                            }
                        } else setRole(role);
                    }
                }
            }

            if (!!sessionExternal){
                const sessionObjExternal: LoginResult | null = JSON.parse(sessionExternal!);

                if (sessionObjExternal) {

                    if (sessionObjExternal.account) {
                        let role = sessionObjExternal.role;
    
                        if (!role) {
                            setRole("Cliente");
                        }
                    }
                }
            }
          
        } catch (e: any) {
            setRole(null);
        }
    };
};

export { useRole, useSetRole };