import { Typography } from "@mui/material";
import error from "../../assets/images/error.png";

interface UpErrorProps {
  errorMessage: string | null;
}

const UpError = ({ errorMessage }: UpErrorProps) => {
  return (
    <div role="status" style={{ textAlign: "center" }}>
      <img src={error} style={{ height: "300px" }} alt={errorMessage ?? ""} />
      <Typography role="alert" variant="subtitle1" className="text-danger mt-3">
        {errorMessage}
      </Typography>
    </div>
  );
};

export default UpError;
