import { UpMenuItem } from "@done/react-essentials";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBoxTaped,
  faBoxesStacked,
  faBriefcase,
  faGear,
  faHome,
  faPeopleGroup,
  faPersonDollyEmpty,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faHome,
  faGear,
  faBriefcase,
  faUserPlus,
  faUsers,
  faPersonDollyEmpty,
  faPeopleGroup,
  faBoxTaped,
  faBoxesStacked,
);

const homeMenu = new UpMenuItem(
  ["fal", "home"],
  "Página Inicial",
  "Página Inicial",
  "/",
);

const statusMenu = new UpMenuItem(
  ["fal", "user-shield"],
  "Status",
  "Status",
  "/status",
  null,
);

const serviceLevelAgreementMenu = new UpMenuItem(
  ["fal", "file-contract"],
  "Contrato de Serviço",
  "Contrato de Serviço",
  "/contracts",
  null,
);

const classificarionMenu = new UpMenuItem(
  ["fal", "list-ul"],
  "Classificação",
  "Classificação",
  "/classifications",
  null,
);

const workingHoursMenu = new UpMenuItem(
  ["fal", "business-time"],
  "Jornada de trabalho",
  "Jornada de trabalho",
  "/working-hours",
  null,
);

const ticketMenu = new UpMenuItem(
  ["fal", "tickets-perforated"],
  "Tickets",
  "Tickets",
  "/tickets",
  null,
);

const assignedTicketsMenu = new UpMenuItem(
  ["fal", "tickets-perforated"],
  "Tickets Atribuídos",
  "Tickets Atribuídos",
  "/assigned-tickets",
  null,
);

const agentsMenu = new UpMenuItem(
  ["fal", "users-gear"],
  "Agentes",
  "Agentes",
  "/agents",
  null,
);

const externalUsersMenu = new UpMenuItem(
  ["fal", "users"],
  "Clientes",
  "Clientes",
  "/external-users",
  null,
);

export const adminMenu: UpMenuItem[] = [
  homeMenu,
  statusMenu,
  serviceLevelAgreementMenu,
  classificarionMenu,
  workingHoursMenu,
  externalUsersMenu,
  agentsMenu,
  ticketMenu,
];

export const agenteMenu: UpMenuItem[] = [
  homeMenu,
  externalUsersMenu,
  ticketMenu,
];

export const externalUserMenu: UpMenuItem[] = [
  homeMenu,
  ticketMenu,
];
