import {
    LoginResult,
    UpApiResult,
    UpApiResultStatus,
    UpDataGrid,
    UpHeader
} from "@done/react-essentials";
import { Box, Tooltip, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appTitle } from "../../../../shared/app-title";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { AccountUserService } from "../../../integration/account-user/services/account-user-service";
import { PermissionAccountUser } from "../../../integration/account-user/models/permission-account-user";
import Cookies from "js-cookie";

const AgentListPage = () => {
    const menuItem = useMenuItem("/agents");
    const theme = useTheme();
    const [users, setUsers] = useState<UpApiResult<PermissionAccountUser[]>>(UpApiResult.start());

    const columns: GridColDef[] = [
        {
            field: "userName",
            headerName: "Nome",
            minWidth: 200,
        },
        {
            field: "userEmail",
            headerName: "Email",
            minWidth: 200,
        },
        {
            field: "active",
            headerName: "Ativo",
            maxWidth: 60,
            align: "center",
            headerAlign: "center",
            renderCell: (cell) => (
                <Tooltip title={cell.value ? "Ativo" : "Inativo"}>
                    <FontAwesomeIcon
                        icon={["fal", cell.value ? "user" : "user-slash"]}
                        style={{
                            color: cell.value
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                        }}
                    />
                </Tooltip>
            ),
        }
    ];

    const session = Cookies.get("session");
    const sessionObj: LoginResult | null = session ? JSON.parse(session) : null;
    
    const fetchData = async () => {
        if (!sessionObj) return;

        const accountId = sessionObj.account?.id ?? 0;
        const agents = await AccountUserService.instance.listAgentsByAccountAsync(accountId);
        setUsers(agents);
    };

    useEffect(() => {
        if (users.status === UpApiResultStatus.loading) {
            fetchData();
        }
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <UpHeader
                appTitle={appTitle}
                menuItem={menuItem}
            />

            <UpDataGrid
                apiResult={users}
                columns={columns}
                height={window.innerHeight - 180}
                sx={{ width: '100%' }}
            />
        </Box>
    );
};

export default AgentListPage;
