import { UpBackButton } from "@done/react-essentials";
import { Box, Card, Typography } from "@mui/material";
import React from "react";

const CanAccesPage = () => {
    return (
        <Box
            sx={{
                height: "100dvh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Card sx={{ p: 5, textAlign: "center" }}>
                <Typography component="h2" variant="h5">
                    Esse usuário ou conta não possui permissão para utilizar o done! Help Desk.
                </Typography>
                <UpBackButton sx={{ mt: 3 }} />
            </Card>
        </Box>
    );
};

export default CanAccesPage;