import { Box } from "@mui/material";
import React, { Suspense } from "react";
import { Outlet } from "react-router";

const BlankLayout = () => {
  return (
    <Box component="main" sx={{ width: "100%", height: "100%" }}>
      <Suspense fallback={<p>Carregando...</p>}>
        <Outlet />
      </Suspense>
    </Box>
  );
};

export default BlankLayout;
