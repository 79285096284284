import { IconPrefix, IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface UpBackButtonProps {
  text?: string;
  children?: ReactNode;
  sx?: SxProps<Theme> | undefined;
  color?:
  | "secondary"
  | "inherit"
  | "primary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
  icon?: [IconPrefix, IconName] | undefined;
  iconPrefix?: IconPrefix | undefined;
  url?: string;
}

const UpBackButton = ({
  text,
  children,
  sx,
  color,
  icon,
  iconPrefix,
  url
}: UpBackButtonProps) => {
  const navigate = useNavigate();

  const back = () => {
    !!url ? navigate(url) : navigate(-1);
  };

  return (
    <Button color={color ?? "secondary"} type="button" onClick={back} sx={sx}>
      {children}
      {!children && (
        <FontAwesomeIcon
          title="arrow-left"
          icon={icon ?? [iconPrefix ?? "fas", "arrow-left"]}
        />
      )}
      <Typography component="span" variant="button" sx={{ ml: 1 }}>
        {text ?? "Voltar"}
      </Typography>
    </Button>
  );
};

export default UpBackButton;
