import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreateClassification } from "../models/create-classification";
import ClassificationService from "../services/classification-service";
import { Classification } from "../models/classification";

interface ClassificationFieldProps {
    control: any;
    required?: boolean;
    ServiceLevelAgreementId?: number;
    disabled?: boolean;
}

const ClassificationField = ({
    control,
    required = true,
    disabled = false,
}: ClassificationFieldProps) => {

    const watchDepartmentId = useWatch({ control, name: "departmentId" });
    const watchOccupationAreaId = useWatch({ control, name: "occupationAreaId" });

    const { errors } = useFormState<CreateClassification>({ control: control });

    const [classifications, setClassifications] = useState<UpApiResult<Classification[]>>(
        UpApiResult.success([]),
    );

    const fetchData = async () => {
        if (!watchDepartmentId) {
            return;
        }

        setClassifications(await ClassificationService.instance.listActivesByDepartment(watchDepartmentId));
    };

    useEffect(() => {
        fetchData();
    }, [watchDepartmentId]);

    const fieldValidationRules = {};

    if (required) {
        fieldValidationRules["required"] =
            "O campo 'classificação' é obrigatório.";
    }

    return (
        <Controller
            name="parentId"
            control={control}
            defaultValue={null}
            rules={fieldValidationRules}
            disabled={disabled}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    options={classifications.data || []}
                    getOptionLabel={(classification: Classification) => classification.name}
                    color="warning"
                    size="small"
                    fullWidth
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="warning"
                            size="small"
                            label="Classificação Pai"
                            placeholder={
                                classifications.status === UpApiResultStatus.loading
                                    ? "Carregando..."
                                    : ""
                            }
                            helperText={
                                errors.parentId?.message ??
                                (required ? "*Obrigatório" : "")
                            }
                            error={!!errors.parentId}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: classifications.status ===
                                    UpApiResultStatus.loading && (
                                        <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                                            <FontAwesomeIcon icon={["fal", "loader"]} spin />
                                        </InputAdornment>
                                    ),
                            }}
                            fullWidth
                            required={required}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    value={
                        classifications.data?.find(
                            (classification: Classification) => classification.id === value,
                        ) ?? null
                    }
                    onChange={(_, value) => {
                        return onChange(value?.id ?? null);
                    }}
                />
            )}
        />
    );
};

export default memo(ClassificationField);
