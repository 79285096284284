import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useState } from "react";
import { Controller, useFormState } from "react-hook-form";
import externalUseMe from "../../user/hooks/external-use-me";
import { TenantGroup } from "../models/tenant-group";
import TenantGroupService from "../services/tenant-group-service";
import { useAppContext } from "../../../../shared/contexts/app-context";
import { useRole } from "../../../user-role/hooks/use-role";

interface TenantGroupFieldProps {
  control: any;
  required?: boolean;
  disabled?: boolean;
}

const TenantGroupField = ({
  control,
  required = true,
  disabled = false,
}: TenantGroupFieldProps) => {
  const externalMe = externalUseMe();
  const  role  = useRole();
  const { enqueueSnackbar } = useSnackbar();

  const [tenantsGroups, setTenantsGroups] = useState<UpApiResult<TenantGroup[]>>(UpApiResult.start());

  const fetchData = async () => {
    if (role === 'Admin')
      setTenantsGroups(await TenantGroupService.instance.listActives());
    else if (role === 'Agente') {
      setTenantsGroups(await TenantGroupService.instance.listByUserId());
    }
    else if (role === 'Cliente') {
      let tenantGroup = await TenantGroupService.instance.findByUserId(externalMe!.tenantGroupId);

      setTenantsGroups(UpApiResult.success([tenantGroup.data]))
    }
    else {
      enqueueSnackbar(
        `Alçada não encontrada.`,
        {
          variant: "error",
        },
      );
    }
  };

  useEffect(() => {
    if (tenantsGroups.status === UpApiResultStatus.loading) fetchData();
  }, [tenantsGroups]);

  const fieldValidationRules = {};

  //if (required) {
  //   fieldValidationRules["required"] =
  //     "O campo grupo econômico é obrigatório.";
  //}


  return (
    <Controller
      name="tenantGroupId"
      control={control}
      defaultValue={null}
      rules={{ required: required && "O campo grupo econômico é obrigtório" }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          options={tenantsGroups.data || []}
          getOptionLabel={(group: TenantGroup) => group.name}
          color="warning"
          size="small"
          fullWidth
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              color="warning"
              size="small"
              label="Grupo Econômico"
              placeholder={
                tenantsGroups.status === UpApiResultStatus.loading
                  ? "Carregando..."
                  : ""
              }
              helperText={
                error?.message ??
                (required ? "*Obrigatório" : "")
              }
              error={!!error}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                startAdornment: tenantsGroups.status ===
                  UpApiResultStatus.loading && (
                    <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                      <FontAwesomeIcon icon={["fal", "loader"]} spin />
                    </InputAdornment>
                  ),
              }}
              fullWidth
              required={required}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          value={
            tenantsGroups.data?.find(
              (group: TenantGroup) => group?.id === value,
            ) ?? null
          }
          onChange={(_, value) => {
            return onChange(value?.id ?? null);
          }}
        />
      )}
    />
  );
};

export default memo(TenantGroupField);
