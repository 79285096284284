import { SxProps, Theme } from "@mui/material";
import { DataGrid, GridColDef, GridEventListener, GridFooter, GridFooterContainer, GridRowIdGetter, ptBR } from "@mui/x-data-grid";
import { ReactNode } from "react";
import {
  UpApiResult,
  UpApiResultStatus,
} from "../../models/up-api-result-model";
import UpDataGridLoading from "../up-data-grid-loading";
import UpError from "../up-error";

interface UpDataGridProps {
  columns: GridColDef[] | any;
  apiResult: UpApiResult<any>;
  height?: number;
  sx?: SxProps<Theme> | undefined;
  getRowId?: GridRowIdGetter<any> | undefined;
  onRowClick?: GridEventListener<"rowClick">;
  children?: ReactNode;
}

const UpDataGrid = ({ sx, columns, apiResult, height, getRowId, onRowClick, children }: UpDataGridProps) => {

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        {children}
        <GridFooter />
      </GridFooterContainer>
    )
  }

  return (
    <>
      {apiResult?.status === UpApiResultStatus.loading && (
        <UpDataGridLoading height={height} columns={columns} rowsCount={3} sx={sx} />
      )}

      {apiResult?.status === UpApiResultStatus.error && (
        <UpError errorMessage={apiResult.errorMessage} />
      )}

      {apiResult?.status === UpApiResultStatus.success && (
        <div style={{ height: `${height ?? window.innerHeight}px` }}>
          {<DataGrid
            sx={sx}
            aria-label="content-grid"
            rows={apiResult?.data}
            columns={columns}
            pageSizeOptions={[25, 50, 100]}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              footer: CustomFooter
            }}
            getRowId={getRowId}
            onRowClick={onRowClick}
          />}
        </div >
      )}
    </>
  );
};

export default UpDataGrid;
