import { UpBackButton } from "@done/react-essentials";
import { Box, Card, Typography } from "@mui/material";
import React from "react";

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        height: "100dvh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ p: 5, textAlign: "center" }}>
        <Typography component="h1" variant="h4">
          404
        </Typography>
        <Typography component="h2" variant="h5">
          Não Encontrado
        </Typography>
        <UpBackButton sx={{ mt: 3 }} />
      </Card>
    </Box>
  );
};

export default NotFoundPage;
