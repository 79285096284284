import { UpApiResult, UpMenuItem } from "@done/react-essentials";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { Outlet, To, useLocation, useNavigate } from "react-router";
import useMenuItems from "../../menus-items/hooks/use-menu-items";
import Logo from "../../shared/logo";
import useThemeMode from "../../themes/hooks/use-theme-mode";
import useThemeModeSelector from "../../themes/hooks/use-theme-mode-selector";
import Me from "../../features/integration/user/components/me";
import { environment } from "../../shared/utils/environment";
import { useAppContext } from "../../shared/contexts/app-context";

library.add(fal, fas);

const AppHeader = ({ drawerWidth, sidebarStatus, setSidebarStatus }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const themeMode = useThemeMode();
  const themeModeSelector = useThemeModeSelector();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (confirm: boolean) => {
    setAnchorEl(null);

    if (confirm === true)
      window.location.href = `${environment.VITE_APP_WORKSPACE_URL}/login?logout=true&returnUrl=${environment.VITE_APP_HELPDESK_URL}/login`;
  };

  const backToWorkspace = () => {
    window.location.href = `${environment.VITE_APP_WORKSPACE_URL}`;
  };

  return (
    <AppBar enableColorOnDark position="fixed">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {!sidebarStatus && (
          <IconButton onClick={() => setSidebarStatus(true)}>
            <Box sx={{ height: "24px", width: "24px" }}>
              <FontAwesomeIcon icon={["fal", "bars"]} />
            </Box>
          </IconButton>
        )}

        <Box
          sx={{
            height: 64,
            width: drawerWidth - 48,
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
          <Logo />
        </Box>

        <Box>
          <Button color="primary" onClick={handleMenu}>
            <Me variant="nameAvatar" />
            <FontAwesomeIcon icon={["fal", "chevron-down"]} />
          </Button>

          <Menu
            keepMounted
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                themeModeSelector(themeMode === "light" ? "dark" : "light")
              }
            >
              <FontAwesomeIcon
                icon={["fal", themeMode === "light" ? "moon" : "sun"]}
              />
              <Typography sx={{ ml: 1 }}>
                Ativar modo {themeMode === "light" ? "escuro" : "claro"}
              </Typography>
            </MenuItem>

            <MenuItem onClick={backToWorkspace}>
              <FontAwesomeIcon icon={["fal", "arrow-left"]} />
              <Typography sx={{ ml: 1 }}>Voltar para Workspace</Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={() => handleClose(true)}>
              <FontAwesomeIcon icon={["fal", "sign-out"]} />{" "}
              <Typography sx={{ ml: 1 }}>Fazer Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const Sidebar = ({ drawerWidth, sidebarStatus, setSidebarStatus }) => {
  const [menus, setMenus] = useState<UpMenuItem[]>([]);
  const theme = useTheme();
  const isGreaterThanMd = useMediaQuery(theme.breakpoints.up("md"));
  const getMenu = useMenuItems();
  const { role } = useAppContext();

  useEffect(() => {
    if (role) {
      setMenus(getMenu(role));
    }
  }, [role]);

  useEffect(() => {
    setTimeout(() => {
      toggleDrawer(isGreaterThanMd, true);
    });
  }, [isGreaterThanMd]);

  const toggleDrawer = (value: boolean, fromMediaQuery: boolean = false) => {
    if (fromMediaQuery) {
      setSidebarStatus(value);
    } else {
      if (isGreaterThanMd) setSidebarStatus(true);
      else setSidebarStatus(value);
    }
  };

  return (
    <>
      <Box sx={{ width: isGreaterThanMd ? drawerWidth + 48 : 0 }}>
        {/* Div que serve como divisor entre a sidebar e o main content */}
      </Box>

      <Drawer
        variant={isGreaterThanMd ? "permanent" : "temporary"}
        open={sidebarStatus}
        onClose={() => toggleDrawer(false)}
        sx={{ zIndex: isGreaterThanMd ? 0 : 9999 }}
      >
        <Paper color="primary" sx={{ width: drawerWidth, height: 1 }}>
          <Box
            sx={{
              width: 1,
              height: 57,
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            <Logo />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <List sx={{ width: 1 }}>
              {menus.map((item, i) => (
                <UpListItem
                  key={i}
                  item={item}
                  onClick={() => toggleDrawer(false)}
                />
              ))}
            </List>
          </Box>
        </Paper>
      </Drawer>
    </>
  );
};

const MainContent = () => {
  return (
    <Box
      component="main"
      sx={{ width: 1, maxWidth: 1, overflowX: "hidden", mt: 10, px: 3 }}
    >
      <Suspense fallback={<p>Carregando...</p>}>
        <Outlet />
      </Suspense>
    </Box>
  );
};

const UpListItem = ({
  item,
  onClick,
}: {
  item: UpMenuItem;
  onClick: Function;
}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const goTo = (route: To) => {
    onClick();
    navigate(route);
  };

  const isActive =
    location.pathname === "/"
      ? item.route === "/"
      : location.pathname
          .substring(1)
          .startsWith(item.route?.toString().substring(1) || "◬");
  const color = isActive
    ? theme.palette.warning.main
    : theme.palette.primary.main;

  return (
    <ListItemButton onClick={() => goTo(item.route!)}>
      <Box sx={{ width: "24px", textAlign: "center" }}>
        <FontAwesomeIcon icon={item.icon} color={color} />
      </Box>
      <Typography color={color} sx={{ ml: 1 }}>
        {item.text}
      </Typography>
    </ListItemButton>
  );
};

const DefaultLayout = () => {
  const drawerWidth = 240;
  const [sidebarStatus, setSidebarStatus] = useState<boolean>(true);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <AppHeader
        drawerWidth={drawerWidth}
        sidebarStatus={sidebarStatus}
        setSidebarStatus={setSidebarStatus}
      />

      <Stack direction="row" sx={{ display: "flex" }}>
        <Sidebar
          drawerWidth={drawerWidth}
          sidebarStatus={sidebarStatus}
          setSidebarStatus={setSidebarStatus}
        />
        <MainContent />
      </Stack>
    </Box>
  );
};

export default DefaultLayout;
