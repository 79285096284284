import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { BusinessUnit } from "../models/business-unit";

const controller = "workspace/core/businessUnits";

export default class BusinessUnitService {
    private static myInstance = null;

    static get instance() {
        return BusinessUnitService.myInstance ?? new BusinessUnitService();
    }

    async list(): Promise<UpApiResult<BusinessUnit[]>> {
        const response = await HttpClient.instance.get(`/${controller}/external/active`);
        return validateHttpResponse(response);
    }
}
