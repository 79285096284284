import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { Department } from "../models/department";

const controller = "workspace/core/departments";

export default class DepartmentService {
  private static myInstance = null;

  static get instance() {
    return DepartmentService.myInstance ?? new DepartmentService();
  }

  async listByOccupationArea(occupationAreaId: number): Promise<UpApiResult<Department[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/external/occupationArea/${occupationAreaId}`,
    );
    return validateHttpResponse(response);
  }

  async list(): Promise<UpApiResult<Department[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/external`,
    );
    return validateHttpResponse(response);
  }

  async listActives(): Promise<UpApiResult<Department[]>> {
    const response = await HttpClient.instance.get(
      `/${controller}/external-active`,
    );
    return validateHttpResponse(response);
  }
}
