import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Control, Controller, useFormState, useWatch } from "react-hook-form";
import { Tenant } from "../models/tenant";
import TenantService from "../services/tenant-service";
import { CreateTicket } from "../../../ticket/models/create-ticket";

interface TenantFieldProps {
  control: Control<any>;
  required?: boolean;
  disabled?: boolean;
  label?: string;
}

const TenantField = ({
  control,
  required = true,
  disabled = false,
  label
}: TenantFieldProps) => {
  const watchTenantGroupId = useWatch({ control, name: "tenantGroupId" });
  const { errors } = useFormState<CreateTicket>({ control: control });

  const [tenants, setTenants] = useState<UpApiResult<Tenant[]>>(UpApiResult.start());

  const fetchData = async () => {
    if (!watchTenantGroupId) {
      setTenants(UpApiResult.new());
      return;
    }

    const preventRelist = (tenants.data as Tenant[])?.some(
      (x) => x.groupId === watchTenantGroupId,
    );

    if (preventRelist) return;

    setTenants(UpApiResult.start());

    setTenants(await TenantService.instance.listByGroup(watchTenantGroupId));
  };

  useEffect(() => {
    fetchData();
  }, [watchTenantGroupId]);

  const fieldValidationRules = {};

  if (required) {
    fieldValidationRules["required"] = `O campo ${label?.toLocaleLowerCase() ?? "cliente"} é obrigatório.`;
  }

  return (
    <Controller
      name="tenantId"
      control={control}
      defaultValue={null}
      rules={fieldValidationRules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={tenants.data || []}
          getOptionLabel={(tenant: Tenant) => tenant.legalName}
          color="warning"
          size="small"
          fullWidth
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              color="warning"
              size="small"
              label={label ?? "Cliente"}
              placeholder={
                tenants.status === UpApiResultStatus.loading
                  ? "Carregando..."
                  : ""
              }
              helperText={
                errors.tenantId?.message ?? (required ? "*Obrigatório" : "")
              }
              error={!!errors.tenantId}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                startAdornment: tenants.status ===
                  UpApiResultStatus.loading && (
                    <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                      <FontAwesomeIcon icon={["fal", "loader"]} spin />
                    </InputAdornment>
                  ),
              }}
              fullWidth
              required={required}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.legalName}
            </li>
          )}
          value={
            tenants.data?.find((tenant: Tenant) => tenant.id === value) ?? null
          }
          onChange={(_, value) => {
            return onChange(value?.id ?? null);
          }}
        />
      )}
    />
  );
};

export default memo(TenantField);
