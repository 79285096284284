import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../../http/http-client";
import { OccupationArea } from "../models/occupation-area";

const controller = "workspace/core/occupationsAreas";

export default class OccupationAreaService {
    private static myInstance = null;

    static get instance() {
        return OccupationAreaService.myInstance ?? new OccupationAreaService();
    }

    async listByBusinessUnit(businessUnitId: number): Promise<UpApiResult<OccupationArea[]>> {
        const response = await HttpClient.instance.get(
          `/${controller}/external/business-unit/${businessUnitId}`,
        );
        return validateHttpResponse(response);
      }
}
