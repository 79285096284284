import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { To, useNavigate } from "react-router-dom";
import { UpMenuItem } from "../../models/up-menu-item";
import BackButton from "../buttons/up-back-button";

export interface UpHeaderProps {
  appTitle?: string;
  menuItem: UpMenuItem;
  createIcon?: [IconPrefix, IconName];
  createText?: string;
  createRoute?: To;
  listIcon?: [IconPrefix, IconName];
  listText?: string;
  listRoute?: To;
  showBack?: boolean;
  sx?: SxProps<Theme> | undefined;
  children?: ReactNode;
  backUrl?: string;
  caption?: React.ReactNode;
}

const UpHeader = ({
  appTitle,
  menuItem,
  createIcon,
  createText,
  createRoute,
  listIcon,
  listText,
  listRoute,
  showBack,
  sx,
  children,
  backUrl,
  caption
}: UpHeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  document.title = `${menuItem.tooltip} | ${appTitle ?? "done! Help Desk"} `;

  const goToCreate = () => {
    navigate(createRoute!);
  };

  const goToList = () => {
    navigate(listRoute!);
  };

  return (
    <Box component="header" sx={{ mb: 3, ...sx }}>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "stretch" : "space-between",
          flexWrap: isMobile ? "wrap" : "initial",
          rowGap: 2,
          mb: !caption ? 1 : 0,
          py: !caption ? .65 : 0
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={menuItem.icon}
            fontSize={20}
            style={{ width: "32px" }}
          />
          <Typography component="h5" variant="h5" sx={{ ml: 1, mr: 2, textWrap: "nowrap" }}>
            {menuItem.tooltip}
          </Typography>
        </Box>

        <Box sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "stretch" : "end",
          flexWrap: isMobile ? "wrap" : "initial",
          rowGap: 2,
          columnGap: 1
        }}>
          {!!showBack && !!backUrl && <BackButton url={backUrl} iconPrefix={menuItem.icon[0]} />}

          {!!listRoute && (
            <Button variant="outlined" sx={{ px: 3 }} onClick={goToList}>
              <FontAwesomeIcon
                title={listIcon ? listIcon[1] : "list-ul"}
                icon={listIcon ?? [menuItem.icon[0], "list-ul"]}
              />
              <Typography component="span" variant="button" sx={{ ml: 1 }}>
                {listText ?? "Listar"}
              </Typography>
            </Button>
          )}

          {!!createRoute && (
            <Button variant="outlined" sx={{ px: 3 }} onClick={goToCreate}>
              <FontAwesomeIcon
                title={createIcon ? createIcon[1] : "plus"}
                icon={createIcon ?? [menuItem.icon[0], "plus"]}
              />
              <Typography component="span" variant="button" sx={{ ml: 1 }}>
                {createText ?? "Adicionar"}
              </Typography>
            </Button>
          )}

          {children}
        </Box>

      </Box>

      {caption}
      <Divider />
    </Box >
  );
};

export default UpHeader;
