import {
    UpApiResult,
    UpApiResultStatus,
    UpDataGrid,
    UpHeader
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, useTheme } from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import StatusService from "../../services/status-service";
import { Status } from "../../models/status";

const StatusListPage = () => {
    const menuItem = useMenuItem("/status");
    const navigate = useNavigate();
    const theme = useTheme();

    const [statuses, setStatuses] = useState<UpApiResult<Status[]>>(UpApiResult.start());

    const [dialogOpened, setDialogOpened] = useState<{
        id: GridRowId;
        text: string;
        isActive: boolean;
    } | null>(null);

    const columns: GridColDef[] = [
        {
            field: "active",
            headerName: "Ativo",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            maxWidth: 60,
            align: "center",
            headerAlign: "center",
            renderCell: (cell) => (
                <Tooltip title={cell.value ? "Ativo" : "Inativo"}>
                    <FontAwesomeIcon
                        icon={["fal", cell.value ? "check-circle" : "ban"]}
                        style={{
                            color: cell.value
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                        }}
                    />
                </Tooltip>
            ),
        },
        { field: "name", headerName: "Nome", minWidth: 300 },
        {
            field: "type",
            headerName: "Tipo Status",
            minWidth: 300,
            renderCell: (params) => {
                return typeOptions.find((x) => params.value === x.id)?.description;
            },
        },
        {
            field: "actions",
            headerName: "Ações",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar" sx={{ mr: 1 }}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => navigate(`form/${params.id}`)}
                        >
                            <FontAwesomeIcon icon={["fal", "edit"]} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={params.row.active
                        ? "Ativado" : "Desativado"}>
                        <IconButton
                            size="small"
                            color={params.row.active
                                ? "success" : "error"}
                            onClick={() => openDialog(params.id)}
                        >
                            <FontAwesomeIcon
                                icon={[
                                    "fal",
                                    params.row.active
                                        ? "check-circle" : "circle-xmark",
                                ]}
                            />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    const openDialog = (id: GridRowId) => {
        const selectedStatus = statuses.data.find(
            (status) => status.id === id,
        );
        setDialogOpened({
            id: id,
            text: selectedStatus?.name ?? "-",
            isActive: selectedStatus?.active,
        });
    };

    const closeDialog = async (confirm: boolean | undefined) => {
        if (confirm === true) {
            const result = await StatusService.instance.activeOrDeactive(
                parseInt(dialogOpened!.id.toString()),
            );
            setStatuses(UpApiResult.start());
        }

        setDialogOpened(null);
    };

    const typeOptions = [
        { id: 0, description: "Novo" },
        { id: 1, description: "Aberto" },
        { id: 2, description: "Aguardando" },
        { id: 3, description: "Fechado" },
    ];

    const fetchData = async () => {
        setStatuses(await StatusService.instance.list());
    };

    useEffect(() => {
        if (statuses.status === UpApiResultStatus.loading) fetchData();
    }, [statuses]);

    return (
        <Box sx={{ width: '100%' }}>
            <UpHeader
                appTitle={appTitle}
                menuItem={menuItem}
                createRoute="/status/form" />

            <UpDataGrid
                apiResult={statuses}
                columns={columns}
                height={window.innerHeight - 180}
                sx={{ width: '100%' }}
            />

            <Dialog
                open={!!dialogOpened}
                onClose={() => closeDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogOpened?.isActive ? "Desativar" : "Ativar"} Status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Confirmar a {dialogOpened?.isActive ? "desativação" : "ativação"} do
                        status <strong>{dialogOpened?.text}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog(false)} autoFocus sx={{ mr: 1 }}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => closeDialog(true)}
                        color={dialogOpened?.isActive ? "error" : "success"}
                    >
                        {dialogOpened?.isActive ? "Desativar" : "Ativar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default StatusListPage;
