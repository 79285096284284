import {
  UpApiResult,
  UpApiResultStatus,
  UpConfirmButton,
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import withMe from "../../../../integration/user/hocs/withMe";
import { User } from "../../../../integration/user/models/user";
import AttachmentForm from "../../../attachment/components/form/index";
import AttachmentList from "../../../attachment/components/list";
import { CreateAttachment } from "../../../attachment/models/create-attachment";
import { Visibility } from "../../enums/visibility";
import { CreateInteraction } from "../../models/create-interaction";
import { useRole } from "../../../../user-role/hooks/use-role";
import { Ticket } from "../../../../ticket/models/ticket";

type InteractionFormProps = {
  ticketId: number;
  me: User | null;
  disable: boolean;
  createInteractionApiResult: UpApiResult<Ticket>;
  attachmentsInteractions: CreateAttachment[];
  onSubmit;
};

const InteractionForm = ({
  ticketId,
  me,
  onSubmit,
  disable,
  createInteractionApiResult,
  attachmentsInteractions,
}: InteractionFormProps) => {
  const role = useRole();

  const [dialogOpened, setDialogOpened] = useState<{
    id: GridRowId;
  } | null>(null);

  const [isVisible, setIsVisible] = useState<Boolean>(false);

  const openDialog = (id: GridRowId) => {
    setDialogOpened({
      id: id,
    });
  };

  const {
    register,
    reset,
    setFocus,
    setValue,
    watch,
    // handleSubmit,
    formState: { errors },
  } = useForm<CreateInteraction>({
    defaultValues: {
      ticketId: ticketId,
      userId: me?.id,
      text: "",
      visibility: Visibility.Public,
    },
  });

  useEffect(() => {
    if (createInteractionApiResult.status === UpApiResultStatus.success) {
      setFocus("text");
      reset();
    }
  }, [attachmentsInteractions]);

  const visibility = watch("visibility");
  const toggleVisibility = () => {
    setValue(
      "visibility",
      visibility === Visibility.Public ? Visibility.Private : Visibility.Public,
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObj: any = Object.fromEntries(formData.entries());
    formDataObj.visibility = visibility;

    setFocus("text");
    reset();
    onSubmit(formDataObj); // Envia os dados para o componente principal
  };

  const closeDialog = async (confirm: boolean | undefined) => {
    setDialogOpened(null);
  };

  // pega o valor do form de AttachmentList, quando á alguma mudança
  const handleValuesChange = (values) => {
    if (!!values.files) {
      if (values.files.length > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Stack direction="row" gap={2} sx={{ mt: 5, width: "100%" }}>
          <TextField
            {...register("text", {
              required: "O campo 'resposta' deve ser preenchido.",
              minLength: {
                value: 3,
                message:
                  "O campo 'resposta' deve conter ao menos 3 caracteres.",
              },
            })}
            color="warning"
            size="small"
            label="Resposta"
            placeholder={
              attachmentsInteractions.length > 0
                ? "É necessário inserir um texto para enviar os anexos."
                : "Comece a digitar sua resposta aqui..."
            }
            helperText={errors.text?.message}
            error={!!errors.text}
            slotProps={{
              input: {
                endAdornment:
                  role !== "Cliente" ? (
                    <Tooltip
                      title={
                        visibility === Visibility.Public
                          ? "Resposta visível para todos"
                          : "Resposta visível somente para agentes"
                      }
                    >
                      <Button onClick={toggleVisibility}>
                        <FontAwesomeIcon
                          icon={[
                            "fal",
                            visibility === Visibility.Public
                              ? "lock-open"
                              : "lock",
                          ]}
                        />
                      </Button>
                    </Tooltip>
                  ) : undefined,
                sx: {
                  backgroundColor:
                    visibility === Visibility.Public
                      ? "inherit"
                      : "rgba(255, 220, 115, .2)",
                },
              },
              inputLabel: {
                shrink: true,
              },
            }}
            fullWidth
            multiline
            minRows={1}
            disabled={disable}
            sx={{
              mb: 2,
            }}
          />

          <Box sx={{ display: "flex" }} gap={2}>
            <Box component={"div"}>
              <Button
                color="secondary"
                onClick={() => openDialog(ticketId)}
                disabled={disable}
              >
                <FontAwesomeIcon icon={["fal", "paperclip-vertical"]} />
                <Typography component="span" variant="button" sx={{ ml: 1 }}>
                  Anexar
                </Typography>
              </Button>
            </Box>

            <Box component={"div"}>
              <UpConfirmButton
                apiResult={createInteractionApiResult}
                icon={["fal", "send"]}
                text="Atualizar"
                color="secondary"
                disabled={
                  (watch().text === "" && attachmentsInteractions.length > 0
                    ? true
                    : false) || disable
                }
              />
            </Box>
          </Box>
        </Stack>
      </form>

      <Dialog
        open={!!dialogOpened}
        onClose={(event, reason) => {
          // Verifica se o fechamento foi causado por um clique fora do modal
          if (reason !== "backdropClick") {
            closeDialog(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AttachmentForm
            isAttachmentInteracionCreate={true}
            ticketId={ticketId}
            onValuesChange={handleValuesChange}
          />
          {attachmentsInteractions.length > 0 && (
            <>
              <Box sx={{ mt: 3 }} />
              <AttachmentList
                isAttachmentInteracionCreate={true}
                hideTrash={false}
                saveApiResult={null}
                ticketId={ticketId}
                attachments={[]}
                attachmentsInteractions={attachmentsInteractions}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          {!isVisible && (
            <Button onClick={() => closeDialog(false)}>Cancelar</Button>
          )}

          {isVisible === false && attachmentsInteractions.length > 0 && (
            <Button
              onClick={() => closeDialog(false)}
              variant="contained"
              color="secondary"
            >
              <FontAwesomeIcon icon={["fas", "check"]} />
              <Typography sx={{ ml: 1 }}> Salvar </Typography>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withMe(InteractionForm);
