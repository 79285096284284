import { Autocomplete, Box, Grid2, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { Classification } from "../models/classification";
import { ClassificationTemp } from "../models/classification-temp";
import { CreateClassification } from "../models/create-classification";
import ClassificationService from "../services/classification-service";

interface TicketClassificationsFieldProps {
    control: any;
    watch;
    ticketId?: number;
    required?: boolean;
    ServiceLevelAgreementId?: number;
    disabled?: boolean;
    isFilter?: boolean;
    fullWidth?: boolean;
}

const TicketClassificationsField = ({
    control,
    watch,
    ticketId,
    required = true,
    disabled = false,
    isFilter = false,
    fullWidth = false
}: TicketClassificationsFieldProps) => {
    const watchDepartmentId = useWatch({ control, name: "departmentId" });
    const watchOccupationAreaId = useWatch({ control, name: "occupationAreaId" });

    const { setValue } = useFormContext();
    const { errors } = useFormState<CreateClassification>({ control: control });
    const [options, setOptions] = useState<ClassificationTemp[]>([]);
    const [selectOptions, setSelectOptions] = useState<ClassificationTemp[]>([]);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(isFilter);

    const fetchData = async () => {

        if (!watchDepartmentId) {
            setIsOpenModal(false);
            return;
        }

        setIsOpenModal(false);

        // Listar primeira vez
        const upApiResult = await ClassificationService.instance.listActivesByDepartment(watchDepartmentId);

        if (upApiResult.data !== null) {
            const result = Map.groupBy(upApiResult.data, ({ parentId }) => parentId);

            let opts: ClassificationTemp[] = [];

            for (const res of result) {
                opts = [...opts, {
                    parentId: res[0],
                    options: res[1],
                    selectedOption: undefined
                }];
            }

            setOptions(opts);

            if (ticketId || isFilter && isOpenModal) {
                const listaSelectedCreate: ClassificationTemp[] = [];
                const classifications = watch('ticketClassifications');

                if (classifications) {
                    const listaSelected: ClassificationTemp[] = opts.filter(x => x.options.filter(a => classifications.map(x => x.classificationId).includes(a.id)));

                    for (const item of classifications) {
                        let temp: ClassificationTemp | undefined = listaSelected.find(x => x.options.find(z => z.id === item.classificationId));

                        if (temp) {
                            temp.selectedOption = item.classificationId;
                            listaSelectedCreate.push(temp);
                        }
                    }

                    const lastClassification = listaSelectedCreate[listaSelectedCreate.length - 1];

                    if (!!lastClassification.options.length && !!lastClassification.selectedOption) {
                        const option = opts.find(x => x.parentId == lastClassification.selectedOption);

                        if (!!option) {
                            listaSelectedCreate.push({
                                parentId: lastClassification.selectedOption,
                                options: option.options,
                                selectedOption: undefined
                            });
                        }
                    }

                    setSelectOptions(listaSelectedCreate);
                }
            } else {
                setSelectOptions([opts.find(x => x.parentId === null)!]);
            }

        }
    };

    useEffect(() => {
        fetchData();
    }, [watchDepartmentId]);

    useEffect(() => {
        setValue("optionsSelect", selectOptions);
    }, [selectOptions])

    const ruleSelectClassification = (value, index) => {
        if (value !== null) {
            let newSelectedOptions: ClassificationTemp[] = [];

            for (const opt of selectOptions) {
                const idx = selectOptions.indexOf(opt);

                if (idx < index)
                    newSelectedOptions = [...newSelectedOptions, opt];
                else if (idx === index) {
                    const newOption = JSON.parse(JSON.stringify(options.find(x => x.parentId === value?.id)) ?? null);
                    const lastOption = JSON.parse(JSON.stringify(selectOptions[index]));

                    lastOption.selectedOption = !!newOption ? value?.id : undefined;

                    newSelectedOptions = [
                        ...newSelectedOptions,
                        lastOption
                    ];

                    if (!!newOption) {
                        newOption.selectedOption = undefined;
                        newSelectedOptions = [
                            ...newSelectedOptions,
                            newOption
                        ];
                    } else {
                        // Quando seleciona o ultimo item.
                        lastOption.selectedOption = value?.id;

                        newSelectedOptions = [
                            ...newSelectedOptions,
                        ];
                    }

                    setSelectOptions(newSelectedOptions);
                    break;
                }
            }
        }
        //quando clica no x
        else {
            let newSelectedOptions: ClassificationTemp[] = [];

            // necessário cria uma cópia da lista para não dar erro de atribuir o valor no selectedOption
            let newCopySelectOptions = JSON.parse(JSON.stringify(selectOptions));

            for (const opt of newCopySelectOptions) {
                const idx = newCopySelectOptions.indexOf(opt);

                if (idx < index) {
                    newSelectedOptions = [...newSelectedOptions, opt];
                }
                else if (idx === index) {
                    opt.selectedOption = undefined;
                    newSelectedOptions = [...newSelectedOptions, opt];
                }
            }

            setSelectOptions(newSelectedOptions);
        }
    }

    return (
        <>
            {(watchDepartmentId === null || watchDepartmentId === undefined ||
                watchOccupationAreaId === null || watchOccupationAreaId === undefined) && (
                    <Grid2 container columnSpacing={2}>
                        <Grid2 size={{ xs: 12, md: !!fullWidth ? 12 : 6, lg: !!fullWidth ? 12 : 4 }} sx={{ mt: 2 }}>
                            <Autocomplete
                                options={[]}
                                getOptionLabel={(classification: Classification) => classification.name}
                                color="warning"
                                size="small"
                                fullWidth
                                disabled={disabled}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        color="warning"
                                        size="small"
                                        label="Classificações de Ticket 1"
                                        placeholder={
                                            ""
                                        }
                                        helperText={
                                            errors.parentId?.message ??
                                            (required ? "*Obrigatório" : "")
                                        }
                                        error={!!errors.parentId}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        required={required}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                )}
                                onChange={(_, value) => {
                                    return value;
                                }}
                            />
                        </Grid2>
                    </Grid2>
                )}

            {(watchDepartmentId > 0 && watchOccupationAreaId > 0 && selectOptions.length > 0) && (
                <Grid2 container columnSpacing={2}>
                    {selectOptions.map((temp, index) => (
                        <Grid2 size={{ xs: 12, md: !!fullWidth ? 12 : 6, lg: !!fullWidth ? 12 : 4 }} key={`classification-${temp?.parentId}`} sx={{ mt: 2 }}>
                            <Autocomplete
                                options={temp?.options || []}
                                getOptionLabel={(classification: Classification) => classification.name}
                                color="warning"
                                size="small"
                                fullWidth
                                disabled={disabled}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        color="warning"
                                        size="small"
                                        label={`Classificações de Ticket ${index + 1}`}
                                        helperText={
                                            errors.parentId?.message ??
                                            (required ? "*Obrigatório" : "")
                                        }
                                        error={!!errors.parentId}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        required={required}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                )}
                                value={selectOptions[index]?.options.find(x => x.id === temp?.selectedOption) ?? null}
                                onChange={(_, value) => {
                                    ruleSelectClassification(value, index);
                                    return value;
                                }}
                            />
                        </Grid2>
                    ))}
                </Grid2>
            )}

        </>

    );
};

export default memo(TicketClassificationsField);