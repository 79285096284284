import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { CreateClassification } from "../../../classification/models/create-classification";
import { Department } from "../models/department";
import DepartmentService from "../services/department-service";

interface DepartmentFieldProps {
    listAll: boolean;
    control: any;
    required?: boolean;
    disabled?: boolean;
}

const DepartmentField = ({
    listAll = true,
    control,
    required = true,
    disabled = false,
}: DepartmentFieldProps) => {
    const watchOccupationAreaId = useWatch({ control, name: "occupationAreaId" });
    const { errors } = useFormState<CreateClassification>({ control: control });

    const [departments, setDepartments] = useState<UpApiResult<Department[]>>(UpApiResult.start());

    const fetchData = async () => {

        if (!watchOccupationAreaId && !listAll) {
            setDepartments(UpApiResult.new());
            return;
        }

        const preventRelist = (departments.data as Department[])?.some(
            (x) => x.occupationAreaId === watchOccupationAreaId,
        );

        if (preventRelist) return;

        setDepartments(UpApiResult.start());

        if (!listAll)
            setDepartments(await DepartmentService.instance.listByOccupationArea(watchOccupationAreaId));
        else {
            setDepartments(await DepartmentService.instance.listActives());
        }
    };

    useEffect(() => {
        fetchData();
    }, [watchOccupationAreaId]);

    const fieldValidationRules = {};

    if (required) {
        fieldValidationRules["required"] =
            "O campo departamento é obrigatório.";
    }

    return (
        <Controller
            name="departmentId"
            control={control}
            defaultValue={null}
            rules={fieldValidationRules}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    options={departments.data || []}
                    getOptionLabel={(department: Department) => department.name}
                    color="warning"
                    size="small"
                    fullWidth
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="warning"
                            size="small"
                            label="Departamento"
                            placeholder={
                                departments.status === UpApiResultStatus.loading
                                    ? "Carregando..."
                                    : ""
                            }
                            helperText={
                                errors.departmentId?.message ??
                                (required ? "*Obrigatório" : "")
                            }
                            error={!!errors.departmentId}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: departments.status ===
                                    UpApiResultStatus.loading && (
                                        <InputAdornment position="start" sx={{ width: 16, pl: 0.85 }}>
                                            <FontAwesomeIcon icon={["fal", "loader"]} spin />
                                        </InputAdornment>
                                    ),
                            }}
                            fullWidth
                            required={required}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    value={
                        departments.data?.find(
                            (department: Department) => department.id === value,
                        ) ?? null
                    }
                    onChange={(_, value) => {
                        return onChange(value?.id ?? null);
                    }}
                />
            )}
        />
    );
};

export default memo(DepartmentField);
