import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../http/http-client";
import { Ticket } from "../models/ticket";
import { CreateTicket } from "../models/create-ticket";
import { TickectInteractionDto } from "../../ticket-feature/interaction/dtos/ticket-interaction-dto";
import { FilterTicket } from "../models/filter-ticket";
import { FilterResult } from "../models/filter-result";

const controller = "help-desk/tickets";

export default class TicketService {
  static myInstance: TicketService | null = null;

  static get instance(): TicketService {
    return (TicketService.myInstance ??= new TicketService());
  }

  async filter(
    filter?: FilterTicket,
    isLoggedInExternal?: boolean
  ): Promise<UpApiResult<FilterResult>> {
    const response = await HttpClient.instance.post(`/${controller}/filter/${isLoggedInExternal}`, filter);
    return validateHttpResponse(response);
  }

  async list(): Promise<UpApiResult<Ticket[]>> {
    const response = await HttpClient.instance.get(`/${controller}`);
    return validateHttpResponse(response);
  }

  async listByDepartment(departmentId: number): Promise<UpApiResult<Ticket[]>> {
    const response = await HttpClient.instance.get(`/${controller}/department/${departmentId}`);
    return validateHttpResponse(response);
  }

  async listByTenantGroup(tenantGroupId: number): Promise<UpApiResult<Ticket[]>> {
    const response = await HttpClient.instance.get(`/${controller}/tenantGroup/${tenantGroupId}`);
    return validateHttpResponse(response);
  }

  async listTicketsAssigned(email: string): Promise<UpApiResult<Ticket[]>> {
    const response = await HttpClient.instance.get(`/${controller}/list-tickets-assigned/${email}`);
    return validateHttpResponse(response);
  }

  async listTicketsParticipate(email: string): Promise<UpApiResult<Ticket[]>> {
    const response = await HttpClient.instance.get(`/${controller}/list-tickets-participate/${email}`);
    return validateHttpResponse(response);
  }

  async listTicketsParticipateExternal(): Promise<UpApiResult<Ticket[]>> {
    const response = await HttpClient.instance.get(`/${controller}/list-tickets-participate-external`);
    return validateHttpResponse(response);
  }

  async listMyCreatedTickets(id: string): Promise<UpApiResult<Ticket[]>> {
    const response = await HttpClient.instance.get(`/${controller}/list-my-created-tickets/${id}`);
    return validateHttpResponse(response);
  }

  async find(id: number): Promise<UpApiResult<Ticket>> {
    const response = await HttpClient.instance.get(`/${controller}/${id}`);
    return validateHttpResponse(response);
  }

  async create(task: CreateTicket): Promise<UpApiResult<Ticket>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}`, task);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async updateTicketCreateInteraction(dto: TickectInteractionDto): Promise<UpApiResult<Ticket>> {
    try {
      const response = await HttpClient.instance.post(`/${controller}/interaction-create-update-ticket`, dto);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async update(
    id: number,
    task: CreateTicket,
  ): Promise<UpApiResult<Ticket>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/${id}`,
        task,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async remove(id: number): Promise<UpApiResult<boolean>> {
    try {
      const response = await HttpClient.instance.delete(`/${controller}/${id}`);
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }

  async activeOrDeactive(id: number): Promise<UpApiResult<Ticket>> {
    try {
      const response = await HttpClient.instance.put(
        `/${controller}/activeOrDeactive/${id}`,
      );
      return validateHttpResponse(response);
    } catch (error) {
      return UpApiResult.error(error.message);
    }
  }
}
