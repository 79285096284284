import {
    UpApiResult,
    UpApiResultStatus,
    UpDataGrid,
    UpHeader
} from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, IconButton, Stack, Tooltip, Typography, colors } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useMenuItem from "../../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../../shared/app-title";
import useMe from "../../../../integration/user/hooks/use-me";
import { StatusType } from "../../../../status/enums/status-type";
import FilterDrawer from "../../../../ticket-feature/filter/index";
import { TicketType } from "../../../../ticket/enums/ticket-type";
import { Ticket } from "../../../../ticket/models/ticket";
import TicketService from "../../../../ticket/services/ticket.service";
import externalUseMe from "../../../../integration/user/hooks/external-use-me";

const TicketInteractionListPage = () => {
    const me = useMe();
    const navigate = useNavigate();
    const isLoggedInExternal = externalUseMe();
    const menuItem = useMenuItem("/assigned-tickets");
    const [assignedTickets, setAssignedTickets] = useState<UpApiResult<Ticket[]>>(UpApiResult.start());

    const columns: GridColDef[] = [
        {
            field: "actions",
            headerName: "Ações",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <Tooltip title="Responder" sx={{ mr: 1 }}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => navigate(`track/${params.id}`)}
                        >
                            <FontAwesomeIcon icon={["fal", "message"]} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: "status", headerName: "Status", minWidth: 250,
            renderCell: (params) => (
                <>
                    {params.value.type === StatusType.New && (
                        <>
                            <Tooltip title={params.value.name} >
                                <Box sx={{ mr: 1 }}>
                                    <FontAwesomeIcon color="red" fontSize={19} icon={["fal", "exclamation-circle"]} />
                                </Box>
                            </Tooltip>
                            <Typography>{params.value.name}</Typography>
                        </>
                    )}

                    {params.value.type === StatusType.Open && (
                        <>
                            <Tooltip title={params.value.name} >
                                <Box sx={{ mr: 1 }}>
                                    <FontAwesomeIcon style={{ color: 'mediumseagreen' }} fontSize={19} icon={["fal", "headset"]} />
                                </Box>
                            </Tooltip>
                            <Typography>{params.value.name}</Typography>
                        </>
                    )}

                    {params.value.type === StatusType.Waiting && (
                        <>
                            <Tooltip title={params.value.name} >
                                <Box sx={{ mr: 1 }}>
                                    <FontAwesomeIcon color="yellow" fontSize={19} icon={["fal", "clock"]} />
                                </Box>
                            </Tooltip>
                            <Typography>{params.value.name}</Typography>
                        </>
                    )}

                    {params.value.type === StatusType.Close && (
                        <>
                            <Tooltip title={params.value.name} >
                                <Box sx={{ mr: 1 }}>
                                    <FontAwesomeIcon color="gray" fontSize={19} icon={["fal", "lock-alt"]} />
                                </Box>
                            </Tooltip>
                            <Typography>{params.value.name}</Typography>
                        </>
                    )}

                </>
            ),
        },
        {
            field: "type", headerName: "Tipo", minWidth: 120,
            renderCell: (params) => {
                const ticket: Ticket = assignedTickets.data.find(
                    (x) => x.id === params.row.id,
                );

                const getTicketColor = (): string => {
                    if (ticket.type === TicketType.Error)
                        return colors.red.A700;

                    if (ticket.type === TicketType.Improvement)
                        return colors.blue.A700;

                    if (ticket.type === TicketType.Question)
                        return colors.yellow.A700;

                    if (ticket.type === TicketType.Request)
                        return colors.orange.A700;

                    return '';
                };

                return (
                    <Typography
                        variant="overline"
                        fontWeight={"bold"}
                        sx={{
                            backgroundColor: getTicketColor(),
                            px: 1,
                            lineHeight: 2,
                            borderRadius: 8,
                        }}
                    >
                        {statusOptions.find((x) => params.value === x.id)?.description}
                    </Typography>
                );
            },
        },
        {
            field: "text", headerName: "Título", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: "creationDate", headerName: "Data de Abertura", minWidth: 220,
            renderCell: (params) => (
                <Typography>{params.value.toUpLocalDateTime(true)}</Typography>
            ),
        },
        {
            field: "completionDate", headerName: "Data de Conclusão", minWidth: 220,
            renderCell: (params) => (
                <Typography>{params.value?.toUpLocalDateTime(true) ?? '-'}</Typography>
            ),
        },
        {
            field: "tenantGroup", headerName: "Grupo Econômico", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: "tenant", headerName: "Cliente", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: "department", headerName: "Departamento", minWidth: 300,
            renderCell: (params) => (
                <Typography>{params.value}</Typography>
            ),
        },
    ];

    const [filterOpened, setFilterOpened] = useState<boolean>(false);

    const statusOptions = [
        { id: 0, description: "Erro" },
        { id: 1, description: "Melhoria" },
        { id: 2, description: "Solicitação" },
        { id: 3, description: "Pergunta" },
    ];

    const fetchData = async () => {
        // if (!!me)
        //     setAssignedTickets(await TicketService.instance.listAssignedTickets(me!.userName));
        // else if (!!isLoggedInExternal) {
        //     setAssignedTickets(await TicketService.instance.listAssignedExternalTickets());
        // }
    };

    useEffect(() => {
        if (assignedTickets.status === UpApiResultStatus.loading) fetchData();
    }, [assignedTickets]);

    const fetchFilteredTickets = (tickets) => {
        setAssignedTickets(tickets);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <UpHeader
                appTitle={appTitle}
                menuItem={menuItem}>

                <Stack
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                    sx={{ width: 1 }}
                >
                    <Grid container sx={{ justifyContent: 'flex-end' }} columnSpacing={1}>
                        <Grid item lg={2} >
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setFilterOpened(true)}
                                // disabled={!workflowId}
                                fullWidth
                            >
                                <FontAwesomeIcon icon={["fal", "filter"]} />
                                <Typography variant="body1" sx={{ ml: 1 }}>
                                    Filtrar
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </UpHeader>

            {/* Filtro */}
            <FilterDrawer
                opened={filterOpened}
                setOpened={setFilterOpened}
                isMyCreatedTickets={false}
                onFilter={fetchFilteredTickets}
            />

            <UpDataGrid
                apiResult={assignedTickets}
                columns={columns}
                height={window.innerHeight - 180}
                sx={{ width: '100%' }}
            />
        </Box>
    );
};

export default TicketInteractionListPage;

