import { LoginResult, UpApiResult } from "@done/react-essentials";
import Cookies from "js-cookie";
import { useAppContext } from "../../../shared/contexts/app-context";
import { AccountUserService } from "../../integration/account-user/services/account-user-service";

const useCanAccess = () => {
  const { canAccess } = useAppContext();
  return canAccess;
};

const useSetCanAccess = () => {
  const { setCanAccess } = useAppContext();

  return async () => {
    try {
      const session = Cookies.get("session");
      const sessionExternal = localStorage.getItem("vup_help_desk");

      if (!!session) {
        const sessionObj: LoginResult | null = JSON.parse(session!);

        if (sessionObj) {
          let role = sessionObj.role;

          if (sessionObj.account) {
            if (!role) {
              const canAccess = await AccountUserService.instance.canAccess(
                sessionObj.id,
                sessionObj.account?.id ?? 0
              );

              setCanAccess(canAccess);
            }
            else setCanAccess(UpApiResult.error("Erro"));
          }
        }
      }
      else if (!!sessionExternal) {
        setCanAccess(UpApiResult.success(true));
      }
    } catch (e: any) {
      setCanAccess(UpApiResult.error("Erro"));
    }
  };
};

export { useCanAccess, useSetCanAccess };
