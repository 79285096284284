import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faBolt,
  faCheckCircle,
  faClock,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography } from "@mui/material";

library.add(faClock, faBolt, faBan, faCheckCircle, faTriangleExclamation);

export enum UpSyncStatusValue {
  PendingSubmission = "PENDING_SUBMISSION",
  WaitingForAction = "WAITING_FOR_ACTION",
  Success = "SUCCESS",
  Error = "ERROR",
}

interface UpSyncStatusProps {
  syncStatus: UpSyncStatusValue;
}

interface UpSyncStatusDef {
  icon: IconProp;
  color: string;
  tooltip: string;
}

const UpSyncStatus = (props: UpSyncStatusProps) => {
  const { syncStatus } = props;

  let defs: UpSyncStatusDef;

  switch (syncStatus) {
    case UpSyncStatusValue.PendingSubmission:
      defs = {
        icon: "clock",
        color: "info.light",
        tooltip: "Aguardando Sincronização",
      };
      break;
    case UpSyncStatusValue.WaitingForAction:
      defs = {
        icon: "bolt",
        color: "secondary.light",
        tooltip: "Aguardando Ação Externa",
      };
      break;
    case UpSyncStatusValue.Error:
      defs = {
        icon: "ban",
        color: "error.light",
        tooltip: "Erro de Sincronização",
      };
      break;
    case UpSyncStatusValue.Success:
      defs = {
        icon: "check-circle",
        color: "success.light",
        tooltip: "Sucesso na Sincronização",
      };
      break;
    default:
      defs = {
        icon: "triangle-exclamation",
        color: "warning.light",
        tooltip: "Status de Sincronização Desconhecido",
      };
      break;
  }

  return (
    <Tooltip title={defs.tooltip}>
      <Typography component="i" color={defs.color}>
        <FontAwesomeIcon icon={defs.icon} />
      </Typography>
    </Tooltip>
  );
};

export default UpSyncStatus;
