import { useEffect } from "react";
import useMe from "../hooks/use-me";
import useSetMe from "../hooks/use-set-me";
import React from "react";
import externalUseMe from "../hooks/external-use-me";

const withMe = (WrappedComponent: any) => {
  return (props: any) => {
    // user wks
    const me = useMe();

    // external user
    const externalUser = externalUseMe();

    const setMe = useSetMe();

    useEffect(() => {
      const fetchUser = async () => {
        await setMe();
      };

      if (!me && !externalUser) fetchUser();
    }, [me, externalUser]);

    const moreProps = { ...props, me: me ?? externalUser };

    if (!me && !externalUser) return "...";

    return <WrappedComponent {...moreProps} />;
  };
};

export default withMe;