import {
    UpApiResult,
    UpApiResultStatus,
    UpConfirmButton,
    UpHeader
} from "@done/react-essentials";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import IntervalField from "../../components/interval-field";
import { CreateWorkingHours } from "../../models/create-working-hours";
import { WorkingHours } from "../../models/working-hours";
import WorkingHoursService from "../../services/working-hours-service";

const WorkingHoursFormPage = () => {
    const [findApiResult, setFindApiResult] = useState<UpApiResult<WorkingHours | null>>(
        UpApiResult.start(),
    );
    const [saveApiResult, setSaveApiResult] = useState<UpApiResult<WorkingHours>>(
        UpApiResult.new(),
    );

    const [id, setId] = useState<number>(0);
    const menuItem = useMenuItem("/working-hours");
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const removeSecondsFromTime = (time) => time.slice(0, -3);

    const formatWorkingHours = (data) => {
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const ranges = ['firstRange', 'secondRange', 'thirdRange'];

        daysOfWeek.forEach((day) => {
            ranges.forEach((range) => {
                data[day][range].start = removeSecondsFromTime(data[day][range].start);
                data[day][range].end = removeSecondsFromTime(data[day][range].end);
            });
        });

        return data;
    };

    const {
        register,
        reset,
        control,
        setFocus,
        handleSubmit,
    } = useForm<CreateWorkingHours>({
        defaultValues: async () => {
            try {
                const result = await WorkingHoursService.instance.find();

                if (result.data) {
                    setFindApiResult(result);
                    setId(result.data.id);

                    // Necessário formatar as horas para tirar os ':00' que vem da api aqui
                    if (result.status === UpApiResultStatus.success && result.data) {
                        const formattedData = formatWorkingHours(result.data);
                        return formattedData;
                    }
                } else {
                    setFindApiResult(UpApiResult.success({}));
                }
            } catch (error) {
            }
        },
    });



    const save: SubmitHandler<CreateWorkingHours> = async (workingHours) => {

        if (!validateAllCompletedField(workingHours)) {
            enqueueSnackbar(`É necessário preencher todos os campos`, { variant: "error" });
            return null;
        }

        setSaveApiResult(UpApiResult.start());

        if (id > 0) {
            setSaveApiResult(
                await WorkingHoursService.instance.update(id, workingHours),
            );
        } else {
            let create = await WorkingHoursService.instance.create(workingHours);

            setSaveApiResult(create);
            setId(create.data?.id)
        }
    };

    const validateAllCompletedField = (workingHours) => {
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        for (const day of daysOfWeek) {
            const ranges = ['firstRange', 'secondRange', 'thirdRange'];

            for (const range of ranges) {
                if (
                    workingHours[day][range].start === "" ||
                    workingHours[day][range].end === ""
                ) {
                    return false;
                }
            }
        }

        return true;
    };

    useEffect(() => {
        if (saveApiResult.status === UpApiResultStatus.success) {
            enqueueSnackbar(
                `A Jornada de Trabalho foi salva com sucesso.`,
                {
                    variant: "success",
                },
            );
        } else if (saveApiResult.status === UpApiResultStatus.error) {
            // setFocus("timeToFirstResponse");
            enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
        }
    }, [saveApiResult, enqueueSnackbar, reset, setFocus, navigate]);

    useEffect(() => { }, [findApiResult]);

    return (
        <Box>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(save)}
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
            >
                <UpHeader appTitle={appTitle} menuItem={menuItem}>
                    <UpConfirmButton
                        apiResult={saveApiResult}
                        sx={{ ml: 2 }}
                        color="primary"
                    />
                </UpHeader>

                {findApiResult.status === UpApiResultStatus.success && (
                    <>
                        <IntervalField
                            day="Segunda-Feira"
                            register={register}
                            firstRangeStart="sunday.firstRange.start"
                            firstRangeEnd="sunday.firstRange.end"
                            secondRangeStart="sunday.secondRange.start"
                            secondRangeEnd="sunday.secondRange.end"
                            thirdRangeStart="sunday.thirdRange.start"
                            thirdRangeEnd="sunday.thirdRange.end"
                        />

                        <IntervalField
                            day="Terça-Feira"
                            register={register}
                            firstRangeStart="monday.firstRange.start"
                            firstRangeEnd="monday.firstRange.end"
                            secondRangeStart="monday.secondRange.start"
                            secondRangeEnd="monday.secondRange.end"
                            thirdRangeStart="monday.thirdRange.start"
                            thirdRangeEnd="monday.thirdRange.end"
                        />

                        <IntervalField
                            day="Quarta-Feira"
                            register={register}
                            firstRangeStart="tuesday.firstRange.start"
                            firstRangeEnd="tuesday.firstRange.end"
                            secondRangeStart="tuesday.secondRange.start"
                            secondRangeEnd="tuesday.secondRange.end"
                            thirdRangeStart="tuesday.thirdRange.start"
                            thirdRangeEnd="tuesday.thirdRange.end"
                        />

                        <IntervalField
                            day="Quinta-Feira"
                            register={register}
                            firstRangeStart="wednesday.firstRange.start"
                            firstRangeEnd="wednesday.firstRange.end"
                            secondRangeStart="wednesday.secondRange.start"
                            secondRangeEnd="wednesday.secondRange.end"
                            thirdRangeStart="wednesday.thirdRange.start"
                            thirdRangeEnd="wednesday.thirdRange.end"
                        />

                        <IntervalField
                            day="Sexta-Feira"
                            register={register}
                            firstRangeStart="thursday.firstRange.start"
                            firstRangeEnd="thursday.firstRange.end"
                            secondRangeStart="thursday.secondRange.start"
                            secondRangeEnd="thursday.secondRange.end"
                            thirdRangeStart="thursday.thirdRange.start"
                            thirdRangeEnd="thursday.thirdRange.end"
                        />

                        <IntervalField
                            day="Sábado"
                            register={register}
                            firstRangeStart="friday.firstRange.start"
                            firstRangeEnd="friday.firstRange.end"
                            secondRangeStart="friday.secondRange.start"
                            secondRangeEnd="friday.secondRange.end"
                            thirdRangeStart="friday.thirdRange.start"
                            thirdRangeEnd="friday.thirdRange.end"
                        />

                        <IntervalField
                            day="Domingo"
                            register={register}
                            firstRangeStart="saturday.firstRange.start"
                            firstRangeEnd="saturday.firstRange.end"
                            secondRangeStart="saturday.secondRange.start"
                            secondRangeEnd="saturday.secondRange.end"
                            thirdRangeStart="saturday.thirdRange.start"
                            thirdRangeEnd="saturday.thirdRange.end"
                        />
                    </>
                )}

            </form>
        </Box>
    );
};

export default WorkingHoursFormPage;
