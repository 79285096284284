import { UpApiResult, UpApiResultStatus } from "@done/react-essentials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  InputAdornment,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Control, Controller, useFormState } from "react-hook-form";
import { CreateTicket } from "../../../ticket/models/create-ticket";
import withMe from "../hocs/withMe";
import { User } from "../models/user";
import UserService from "../services/user-service";
import { LoginResult } from "../../../login-external/models/login-result";
import ExternalUserService from "../../../external-user/services/external-user-service";
import Cookies from "js-cookie";

type ReportUserProps = {
  control: Control<any, any>;
  userId: string;
  me: User | null;
};

const ReportUser = ({
  control,
  userId,
  me,
}: ReportUserProps) => {
  const { errors } = useFormState<CreateTicket>({ control: control });
  const [user, setUser] = useState<UpApiResult<User>>(UpApiResult.start());


  const fetchData = async () => {
    const fetchData = async () => {

      const sessionStr = Cookies.get("session");
      
      const session: LoginResult | null = !sessionStr
        ? null
        : JSON.parse(Cookies.get("session")!);

      const sessionExternalStr = window.localStorage.getItem("vup_help_desk");
      const sessionExternal: LoginResult | null = !sessionExternalStr
        ? null
        : JSON.parse(window.localStorage.getItem("vup_help_desk")!);

      // caso venha userId, ele preenche com quem criou o ticket
      if (userId !== null && userId !== undefined) {
        let user = await UserService.instance.find(userId!);

        if (!!user.data)
          setUser(user);
        else {
          setUser(await ExternalUserService.instance.find(userId!));
        }
      }
      else {
        // Pega o usuário logado
        if (!!session) {
          setUser(await UserService.instance.find(me?.id!));
        }
        else if (!!sessionExternal) {
          setUser(await ExternalUserService.instance.find(me?.id!));
        }
      }
    };

    if (user.status === UpApiResultStatus.loading && !!me) fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [
    me,
    user?.data,
  ]);

  return (
    <Stack
      direction={"row"}
      gap={3}
    >
      {!!me && (
        <Controller
          name="reportUserId"
          control={control}
          defaultValue={me?.id ?? ""}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled
              options={[]}
              getOptionLabel={(user: User) => user.name}
              color="warning"
              size="small"
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="warning"
                  size="small"
                  label="Relatado por"
                  placeholder={
                    user.status === UpApiResultStatus.loading
                      ? "Carregando..."
                      : ""
                  }
                  helperText={
                    errors.reportUserId?.message ?? ""
                  }
                  error={!!errors.reportUserId}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: user.status ===
                      UpApiResultStatus.loading && (
                        <InputAdornment
                          position="start"
                          sx={{ width: 16, pl: 0.85 }}
                        >
                          <FontAwesomeIcon icon={["fal", "loader"]} spin />
                        </InputAdornment>
                      ),
                  }}
                  fullWidth
                />
              )}
              renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                  <ListItemText
                    primary={option.name}
                    secondary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        sx={{ opacity: 0.5 }}
                      >
                        {option.active ? "" : "inativo"}
                      </Typography>
                    }
                    sx={{ lineHeight: 0 }}
                  />
                </ListItem>
              )}
              value={
                user.data
              }
              onChange={(_, value) => onChange(value?.id?.toString() ?? null)}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default memo(withMe(ReportUser));
