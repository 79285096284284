import {
  UpApiResult,
  UpApiResultStatus,
  UpConfirmButton,
  UpDividedCard,
  UpHeader,
} from "@done/react-essentials";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useForm,
  useFormState,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import useMenuItem from "../../../../menus-items/hooks/use-menu-item";
import { appTitle } from "../../../../shared/app-title";
import { StatusTypesNames } from "../../enums/status-type";
import { CreateStatus } from "../../models/create-status";
import { Status } from "../../models/status";
import StatusService from "../../services/status-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { themeColors } from "../../../../themes/app-theme";

const StatusFormPage = () => {
  const { id } = useParams();

  const [findApiResult, setFindApiResult] = useState<UpApiResult<Status>>(
    UpApiResult.start(),
  );

  const [saveApiResult, setSaveApiResult] = useState<UpApiResult<Status>>(
    UpApiResult.new(),
  );

  const [removeApiResult, setRemoveApiResult] = useState<UpApiResult<boolean>>(
    UpApiResult.new(),
  );

  const [dialogOpened, setDialogOpened] = useState<{
    id: string;
    text: string;
  } | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const menuItem = useMenuItem("/status");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    reset,
    control,
    setFocus,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateStatus>({
    defaultValues: async () => {
      if (id) {
        try {
          var result = await StatusService.instance.find(parseInt(id!));

          setFindApiResult(result);

          if (result.status === UpApiResultStatus.success && result.data)
            return result.data;
          else navigate(-1);
        } catch (error) {
          navigate(-1);
        }
      }
    },
  });

  const save: SubmitHandler<CreateStatus> = async (status) => {
    setSaveApiResult(UpApiResult.start());

    setFocus("name");

    if (id) {
      setSaveApiResult(
        await StatusService.instance.update(parseInt(id), status),
      );
    } else setSaveApiResult(await StatusService.instance.create(status));
  };

  const remove = async () => {
    setIsLoading(true);

    var result = await StatusService.instance.remove(parseInt(id!));

    if (result.errorMessage !== null)
      setRemoveApiResult(UpApiResult.error(result.errorMessage));
    else {
      setRemoveApiResult(UpApiResult.success(true));
    }
  };

  const openDialog = () => {
    setDialogOpened({
      id: id!!,
      text: findApiResult?.data.name ?? "-",
    });
  };

  const closeDialog = async (confirm: boolean | undefined) => {
    if (confirm === true) remove();

    setDialogOpened(null);
  };

  useEffect(() => {
    if (removeApiResult.status === UpApiResultStatus.success) {
      enqueueSnackbar(`Status removido com sucesso.`, {
        variant: "success",
      });

      navigate("/status");
    }
    if (removeApiResult.status === UpApiResultStatus.error) {
      setFocus("name");
      enqueueSnackbar(removeApiResult.errorMessage, { variant: "error" });
    }
  }, [removeApiResult]);

  useEffect(() => {
    if (saveApiResult.status === UpApiResultStatus.success) {
      enqueueSnackbar(`Status ${saveApiResult.data?.name} salvo com sucesso.`, {
        variant: "success",
      });

      navigate("/status");
    } else if (saveApiResult.status === UpApiResultStatus.error) {
      setFocus("name");
      enqueueSnackbar(saveApiResult.errorMessage, { variant: "error" });
    }
  }, [saveApiResult, id, enqueueSnackbar, reset, setFocus, navigate]);

  useEffect(() => {}, [findApiResult]);

  return (
    <Box>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(save)}
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <UpHeader appTitle={appTitle} menuItem={menuItem} showBack>
          <UpConfirmButton
            apiResult={saveApiResult}
            sx={{ ml: 2 }}
            color="primary"
          />
        </UpHeader>

        {/* Dados */}
        <UpDividedCard
          title="Dados Cadastrais"
          description="Informa os dados básicos de um status"
        >
          <Dialog
            open={!!dialogOpened}
            onClose={() => closeDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remover Status</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Confirmar a remoção do status{" "}
                <strong>{dialogOpened?.text}</strong>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => closeDialog(false)}
                autoFocus
                sx={{ mr: 1 }}
              >
                Cancelar
              </Button>
              <Button onClick={() => closeDialog(true)} color={"error"}>
                Remover
              </Button>
            </DialogActions>
          </Dialog>

          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("name", {
                required: "O campo nome é obrigatório.",
                minLength: {
                  value: 3,
                  message: "O campo nome deve ao menos 3 caracteres.",
                },
              })}
              color="warning"
              size="small"
              label="Nome"
              helperText={errors.name?.message ?? "*Obrigatório"}
              error={!!errors.name}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              autoFocus
              sx={{ flexGrow: 1 }}
            />
          </Box>

          <Controller
            name="type"
            control={control}
            defaultValue={0}
            rules={{ required: "O campo tipo é obrigatório." }}
            render={({ field: { onChange, value } }) => (
              <>
                <Autocomplete
                  sx={{ mt: 3 }}
                  options={StatusTypesNames}
                  getOptionLabel={(type) => type.label}
                  color="warning"
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="warning"
                      size="small"
                      label="Categoria"
                      helperText={errors.type?.message ?? "*Obrigatório"}
                      error={!!errors.type}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                  value={
                    StatusTypesNames.find((x) => x.value === value) ?? null
                  }
                  onChange={(_, value) => onChange(value?.value)}
                />
              </>
            )}
          />

          <Grid container>
            <Grid item md={2}>
              <FormGroup>
                <Controller
                  name="active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ mt: 1 }}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="secondary"
                        />
                      }
                      label="Ativo"
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>

          {id && (
            <Box
              sx={{
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                disabled={removeApiResult?.status === UpApiResultStatus.loading}
                sx={{ color: themeColors.errorColor }}
                onClick={openDialog}
              >
                {removeApiResult?.status === UpApiResultStatus.loading && (
                  <CircularProgress role="spinbutton" size={16} />
                )}
                {removeApiResult?.status !== UpApiResultStatus.loading && (
                  <FontAwesomeIcon title="check" icon={["fas", "trash"]} />
                )}
                <Typography component="span" variant="button" sx={{ ml: 1 }}>
                  Remover
                </Typography>
              </Button>
            </Box>
          )}
        </UpDividedCard>
      </form>
    </Box>
  );
};

export default StatusFormPage;
