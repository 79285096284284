import { UpApiResult } from "@done/react-essentials";
import { HttpClient, validateHttpResponse } from "../../../http/http-client";
import { environment } from "../../../shared/utils/environment";

const controller = "onstock/files";

export default class UploadService {
  static myInstance = null;
  static downloadBaseUrl: string = `${environment.VITE_API_GATEWAY_URL}/${controller}`;

  static get instance() {
    return UploadService.myInstance ?? new UploadService();
  }

  private _getFormData(file: File): FormData {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return formData;
  }

  async uploadFile(file: File): Promise<UpApiResult<string>> {
    const response = await HttpClient.instance.post(
      `/${controller}`,
      this._getFormData(file),
    );
    return validateHttpResponse(response);
  }
}
