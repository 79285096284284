export enum StatusType {
    New = 0,
    Open = 1,
    Waiting = 2,
    Close = 3,
}

export const StatusTypesNames = [
    { value: StatusType.New, label: "Novo" },
    { value: StatusType.Open, label: "Aberto" },
    { value: StatusType.Waiting, label: "Aguardando" },
    { value: StatusType.Close, label: "Fechado" },
  ];
